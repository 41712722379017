import axios from 'axios'
// import { Message, MessageBox } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import qs from 'qs'
import serverConfig from './serverConfig'
// import i18n from './i18n'
// import vuetify from './../plugins/vuetify.js'

// 创建axios实例
const service = axios.create({
  // baseURL: process.env.BASE_API, // api的base_url
  baseURL: serverConfig.api_url + '/admin/v1', // api的base_url
  timeout: 15000 // 请求超时时间
})
// request拦截器
service.interceptors.request.use(config => {
  if (store.getters.token) {
  }
  config.headers['X-Access-Token'] = store.getters.token//"IppWamtMCPMF-AZ0f_6hNpXcETMqKTNzY_HN789fmiIOgtgkTXdUsbS0F2zgRSyM" // 让每个请求携带自定义token 请根据实际情况自行修改
  // config.headers['X-Access-Language'] = i18n.locale
  // config.headers['X-Access-Language'] = vuetify.lang.current == "zhHans" ? "zh" : vuetify.lang.current
  config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
  if (config.method === 'post') {
    config.data = qs.stringify({
      ...config.data
    })
  }
  return config
}, error => {
  // Do something with request error
  console.log(error) // for debug
  Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.meta !== 0 && res.meta !== 100) {
      if (res.meta === 50008 || res.meta === 50012 || res.meta === 50014 || res.meta === 10000) {
        store.dispatch('LogOut').then(() => {
          location.reload()
        })
      } else {
        const err = new Error(res.error)
        err.data = res
        err.response = response
        throw err
      }
    } else {
      return response.data
    }
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
