// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import '@/permission'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import moment from './plugins/moment.js';
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueHtmlToPaper from 'vue-html-to-paper';
import DialogTitle from '@/components/dialogTitle';
import CustomDialog from '@/components/customDialog';
import { timers } from 'jquery'

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    '@/sass/overrides.sass'
  ]
};

Vue.config.productionTip = false;

Vue.component('DialogTitle', DialogTitle)
Vue.component('custom-dialog', CustomDialog)

Vue.use(VueHtmlToPaper, options);
Vue.use(moment);
Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
  position: POSITION.TOP_CENTER
});

Vue.mixin({
  methods: {
    formatNumber(data) {
      return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
