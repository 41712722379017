
const print = [
    {
      path: '/printOrder/:id',
      component: () => import('@/views/dashboard/pages/print/orderPrint'),
      name: 'printOrder',
      meta: { title: 'company', icon: 'usermanager' }
    },
    {
      path: '/printTemplate/:id',
      component: () => import('@/views/dashboard/pages/print/templatePrint'),
      name: 'printTemplate',
      meta: { title: 'company', icon: 'usermanager' }
    },
    {
      path: '/salesPrint/:id',
      component: () => import('@/views/dashboard/pages/print/salesPrint'),
      name: 'salesPrint',
      meta: { title: 'company', icon: 'usermanager' }
    },
    {
      path: '/poPrint/:id/:sell_company',
      component: () => import('@/views/dashboard/pages/print/poPrint'),
      name: 'poPrint',
      meta: { title: 'company', icon: 'usermanager' }
    }
  ]
  
  export default print
  