const financial = 
{
    name: 'Financial',
    path: '/financial',
    component: () => import('@/views/dashboard/Index'),
    meta: { title: 'financialMgr', icon: 'mdi-currency-usd-circle-outline', funcs: ['account', 'record', 'order_payed', 'account_period', 'stat', 'fee_log', 'finance_log'] },
    children: [
        {
            name: 'Bank Accounts',
            path: 'bankaccount',
            component: () => import('@/views/dashboard/pages/financial/bankAccount'),
            meta: { title: 'paymentAccount', icon: 'list', funcs: 'account' }
          },
          {
            name: 'Collection Record',
            path: 'collectionrecord',
            component: () => import('@/views/dashboard/pages/financial/collectionRecord'),
            meta: { title: 'paymentRecords', icon: 'list', funcs: 'record' }
          },
          {
            name: 'Payment Record',
            path: 'paymentrecord',
            component: () => import('@/views/dashboard/pages/financial/paymentRecord'),
            meta: { title: 'paying', icon: 'list', funcs: 'order_payed' }
          },
          {
            name: 'Payment Term Managment',
            path: 'payment',
            component: () => import('@/views/dashboard/pages/financial/paymentTermMgr'),
            meta: { title: 'paymentDays', icon: 'list', funcs: 'account_period' }
          },
          {
            name: 'Statistical Data',
            path: 'statisticaldata',
            component: () => import('@/views/dashboard/pages/financial/statisticalData'),
            meta: { title: 'stat', icon: 'list', funcs: 'stat' }
          },
          {
            name: 'Expense Log',
            path: 'expense',
            component: () => import('@/views/dashboard/pages/financial/expenseLog'),
            meta: { title: 'feeLog', icon: 'list', funcs: 'fee_log' }
          },
          {
            name: 'Financial Log',
            path: 'log',
            component: () => import('@/views/dashboard/pages/financial/financialLog'),
            meta: { title: 'financeLog', icon: 'list', funcs: 'finance_log' }
          },
    ]
}

export default financial