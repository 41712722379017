<template>
  <v-dialog
    v-model="dialog"
    :max-width="width"
    @click:outside="$emit('outsideClick')"
  >
    <v-card @mousedown="drag">
      <slot />
    </v-card>
  </v-dialog>
</template>

<script>
import { draggable } from "@/api/customFunction";

export default {
  name: "custom-dialog",
  props: {
    dialog: Boolean,
    width: {
      type: String,
      default: "1200px",
    },
  },
  methods: {
    drag() {
      draggable();
    },
  },
};
</script>

<style>
</style>