export default {

  goodsType: "ប្រភេទ​ផលិតផល",
  back2Product: "ត្រលប់ទៅផលិតផលវិញ",
  badge: 'ផ្លាកសញ្ញា',
  close: 'បិទ',
  dataIterator: {
    noResultsText: 'ស្វែងរកមិនឃើញកំណត់ត្រាដែលស្រដៀង',
    loadingText: 'កំពុងដំណើរការ...'
  },
  dataTable: {
    itemsPerPageText: 'ជួរក្នុងមួយទំព័រ:',
    ariaLabel: {
      sortDescending: 'តម្រៀបតាមលំដាប់ចុះ។',
      sortAscending: 'តម្រៀបតាមលំដាប់ឡើង។',
      sortNone: 'មិនបានតម្រៀប។',
      activateNone: 'ដំណើរការដើម្បីរំសាយការតម្រៀប',
      activateDescending: 'ដំណើរការដើម្បីតម្រៀបលំដាប់ចុះ។',
      activateAscending: 'ដំណើរការដើម្បីតម្រៀបលំដាប់ឡើង។'
    },
    sortBy: 'តម្រៀបលំដាប់ដោយ'
  },
  dataFooter: {
    itemsPerPageText: 'ចំនួនក្នុងមួយទំព័រ:',
    itemsPerPageAll: 'ទាំងអស់',
    nextPage: 'ទំព័របន្ទាប់',
    prevPage: 'ទំព័រមុន',
    firstPage: 'ទំព័រដំបូង',
    lastPage: 'ទំព័រចុងក្រោយ',
    pageText: '{0}-{1} នៃ {2}'
  },
  datePicker: {
    itemsSelected: '{0} បានជ្រើសរើស',
    nextMonthAriaLabel: 'ខែបន្ទាប់',
    nextYearAriaLabel: 'ឆ្នាំបន្ទាប់',
    prevMonthAriaLabel: 'ខែមុន',
    prevYearAriaLabel: 'ឆ្នាំមុន'
  },
  noDataText: 'មិនមានទិន្នន័យ',
  carousel: {
    prev: 'រូបភាពមុន',
    next: 'រូបភាពបន្ទាប់',
    ariaLabel: {
      delimiter: 'រូបភាពទី {0} នៃ {1}'
    }
  },
  calendar: {
    moreEvents: '{0} ច្រើនទៀត'
  },
  fileInput: {
    counter: '{0} ឯកសារ',
    counterSize: '{0} ឯកសារ ({1} សរុបទាំងអស់)'
  },
  timePicker: {
    am: 'AM',
    pm: 'PM'
  },
  pagination: {
    ariaLabel: {
      wrapper: 'រុករកទំព័រ',
      next: 'ទំព័របន្ទាប់',
      previous: 'ទំព័របន្ទាប់',
      page: 'ទៅទំព័រទី {0}',
      currentPage: 'ទំព័របច្ចុប្បន្ន, ទំព័រទី {0}'
    }
  },
  rating: {
    ariaLabel: {
      icon: 'អោយតម្លៃ {0} នៃ {1}'
    }
  },

  done: "ធ្វើរួច",
  abolish: 'លុបចោល',
  Abroad: 'នៅបរទេស',
  absenteeism: 'អវត្តមាន',
  categoryName: "ឈ្មោះប្រភេទ",
  newCategory: "ប្រភេទថ្មី",
  account: 'ឈ្មោះគណនី',
  arrAccount: 'គណនីដល់',
  accountName: 'ឈ្មោះគណនី',
  AccountPeriod: 'រយៈពេលបង់ប្រាក់',
  accountUse: 'លេខគណនីប្រើប្រាស់',
  achieve: 'បានសម្រេច',
  achieveOrderList: 'រងចាំQC',
  actionState: 'ប្រតិបត្តិការ',
  actionStateTip: 'សូមជ្រើសរើសស្ថានភាពនៃបញ្ជីជ្រើសរើស',
  activateNow: 'ធ្វើឱ្យសកម្មឥឡូវនេះ',
  active: 'កំពុងសកម្ម',
  actualArrival: 'ថ្ងៃមកដល់ពិតប្រាកដ',
  ActualQTY: 'ចំនួនពិតប្រាកដ',
  actualQuantity: 'ចំនួនពិតប្រាកដ',
  ActualTotalPriceArrived: 'តម្លៃទំនិញបានមកដល់ជាផ្លូវការ',
  add: 'បន្ថែម',
  Add: 'បន្ថែម',
  add5: 'បន្ថេម',
  addProductImage: "បន្ថែមរូបភាពផលិតផល",
  AddANewReceipt: 'បន្ថែមបង្កាន់ដៃថ្មី',
  AddEmails: 'បន្ថែមអ៊ីមែលថ្មី',
  addGoods: 'ថែមទំនិញ',
  additems: 'បន្ថែមទំនិញ',
  additems1: 'បន្ថែម',
  addNode: 'បន្ថែមចំណុចប្រសព្វ',
  addOrder: 'បន្ថែមការបញ្ជាទិញ',
  addPeople: 'បន្ថែមបុគ្គលិក',
  addr: 'អាស័យដ្ធានលម្អិត',
  address: 'អាស័យដ្ធាន',
  address1: 'អាស័យដ្ធានក្រុមហ៊ុន',
  address3: 'សាខា',
  addSuccess: 'បន្ថែមជោគជ័យ',
  addSuccessInfo: 'ការបន្ថែមជោគជ័យ ចង់បន្តទៀតរឺទេ?',
  addTime: 'បន្ថែមពេលវេលា',
  administrator: 'អ្នកគ្រប់គ្រង',
  advertising: 'ចាក់ផ្សាយពាណិជ្ជកម្ម',
  all: 'ទាំងអស់',
  allDay: 'រាល់ថ្ងៃ',
  AlreadyApplied: 'បានដាក់ពាក្យសុំរួច',
  Amount: 'ចំនួនទឹកប្រាក់',
  amountApplied: 'តម្លៃដែលបានសំណូមពរហើយ',
  amountReceived: 'ចំនួនទឹកប្រាក់ដែលត្រូវទទួល',
  annex: 'ឯកសារភ្ជាប់',
  antistop: 'ពាក្យគន្លឹះ',
  aog: 'ទំនិញមកដល់',
  appendixUpload: 'បញ្ជូនឯកសារភ្ជាប់',
  ApplDate: 'ថ្ងៃដាក់ពាក្យសុំ',
  applicant: 'អ្នកដាក់ពាក្យសុំ',
  application: 'ពាក្យសុំ',
  applicationReturn: 'ពាក្យសុំបង្វិលប្ដូរ',
  applicationTime: 'ពេលវេលាដាក់ពាក្យសុំ',
  applyReject: 'ពាក្យសុំបដិសេធ',
  applyUser: 'អ្នកសុំដាក់ពាក្យ',
  approve: 'អនុញ្ញាត',
  Approved: 'ឆ្លងកាត់ការត្រួតពិនិត្យ',
  approver: 'អ្នកអនុញ្ញាត',
  ArrivalTime: 'ពេលវេលាមកដល់',
  ArrivalWarehouse: 'មកដល់ឃ្លាំងទំនិញ',
  arrived: 'បានមកដល់',
  article: 'អត្ថបទ',
  artifactType: 'ប្រភេទទំនិញរួចរាល់',
  artwork: 'រូបគំនូរ',
  artworkNo: 'លេខរូបភាព',
  associate: 'អ្នកមានទាក់ទង',
  associatedContract: 'កិច្ចសន្យាដែលជាប់ទាក់ទង',
  associatedContractNum: 'លេខកិច្ចសន្យាដែលជាប់ទាក់ទង',
  attach: 'ឯកសារភ្ចាប់',
  attachment: 'ឯកសារភ្ជាប់',
  attendanceRecord: 'កំណត់ត្រាវត្តមាន',
  audit: 'ត្រួតពិនិត្យ',
  auditOrder: 'ត្រួតពិនិត្យបញ្ជាទិញ',
  authorization: 'អនុញ្ញាតសិទ្ធិ',
  avatar: 'រូបភាព',
  AWeekAgo: 'មួយអាទិត្យមុន',
  back: 'ត្រលប់',
  BackGround: 'រូបផ្ទាំងខាងក្រោយ',
  bank: 'គណនីធនាគារ',
  bankAccNo: 'លេខគណនីធនាគារ',
  bankAccount: 'គណនីធនាគារ',
  BankAccountEditing: 'កែសម្រួលលេខគណនីធនាគារ',
  bankAcctNo: 'លេខគណនីធនាគារ',
  bankCountry: 'ប្រទេសនៃធនាគារ',
  bankInfo: 'ពត៌មានពីធនាគារ',
  bankAcc: 'គណនីធនាគារ',
  bankName: 'ឈ្មោះធនាគារ',
  bankName1: 'ឈ្មោះធនាគារ',
  bankName2: 'ឈ្មោះធនាគារ',
  bankNo: 'លេខគណនី',
  bankStatements: 'របាយការណ៍ធនាគារ',
  bankSwiftCode: 'Bank Swift Code',
  basicData: 'ទិន្នន័យមូលដ្ឋាន',
  BasicDataMgr: 'ឃ្លាំងផ្ទុកID',
  basicInfo: 'ព័ត៌មានទូទៅ',
  basicInfo1: 'ព័ត៌មានមូលដ្ឋាន',
  basicInformation: 'ឯកសារទូទៅ',
  batchExport: 'នាំចេញចំនួនច្រើន',
  batchUpload: 'ផ្ទុកបញ្ចូលឡើងច្រើន',
  batchUpload1: 'ផ្ទុកបញ្ចូលឡើងច្រើន',
  becomeReqularEmplovee: 'ប្រាក់ខែបុគ្គលិកពេញសិទ្ធ',
  begin: 'ចាប់ផ្ដើម',
  beginProduct: 'ចាប់ផ្តើមផលិតកម្ម',
  BeneficiaryBank: 'Beneficiary Bank',
  birthday: 'ថ្ងៃខែឆ្នាំកំណើត',
  bom: 'bom',
  Bottles: 'Bottles',
  bulkImport: 'នាំចូលចំនួនច្រើន',
  bulkUpload: 'បញ្ជូនឡើងច្រើន',
  businessId: 'BusinessID',
  businessType: 'ប្រភេទអាជីវកម្ម',
  calendarView: 'ពិនិត្រមើលប្រតិទិន',
  cancel: 'លុបចោល',
  canceled: 'ត្រូវបានលុបចោល',
  canceledDelete: 'រំសាយការលុបចោល',
  canceler: 'អ្នកលុបចោល',
  cancellationTime: 'ពេលវេលាលុបចោល',
  cancelOrder: 'លុបចោលការកុម្ម៉ង់',
  cancelOrderList: 'ត្រូវបានលុប',
  cancelReason: 'មូលហេតុនៃការលុបចោល',
  cancelReturnRequest: 'លុបចោលការសុំបង្វិលប្ដូរ',
  canping: 'ទំនិញ',
  CargoCategory: 'បែងចែកប្រភេទទំនិញ',
  carton_dimension: 'ទំហំការវេចខ្ចប់កេះ',
  carton_num: 'ចំនួនវេចខ្ចប់',
  cash: 'លុយសុទ្ធ',
  categories: 'ប្រភេទបញ្ជីជ្រើសរើស',
  categories1: 'ប្រភេទអ្នកផ្គត់ផ្គង់',
  Categories2: 'បែងចែកប្រភេទសំណួរ',
  categoriesCommodities: 'ប្រភេទទំនិញ',
  category: ' ប្រភេទ',
  Category6: 'ប្រភេទ',
  categoryClass: 'ប្រភេទ',
  categoryListAll: 'ទាំងអស់',
  // centerPtlogo: require('../assets/images/sy/icon_ptlogo_e.png'),
  cetegory: 'ប្រភេទអាជីវកម្ម',
  ChangeTime: 'កែសម្រួលពេលវេលា',
  changeLang: 'ប្តូរភាសា',
  check: 'ពិនិត្យមើល',
  orderModReview: 'ពិនិត្យការកែប្រែការបញ្ជាទិញ',
  checkFile: 'ពិនិត្យមើលឯកសារ',
  checkMonth: 'ខែផ្សះផ្សា',
  checkUuid: 'រកឃើញ',
  chinese: 'ចិន',
  chineseName: 'ឈ្មោះជាភាសាចិន',
  chipLib: 'ឃ្លាំងផ្ទុកChip',
  havechip: 'មានបន្ទះChip',
  chip: 'បន្ទះឈីប',
  chooseCategories: 'សូមជ្រើសរើសប្រភេទ',
  chooseDaorukucfl: 'សូមជ្រើសរើសការបែងចែកប្រភេទដែលបានបញ្ចូលចូលទៅក្នុងឃ្លាំង',
  chooseDate: 'សូមជ្រើសរើសពេលវេលា',
  chooseFile: 'ជ្រើសរើសឯកសារ',
  chooseOneSalesEntity: 'សូមជ្រើសរើសអង្គភាពលក់យ៉ាងហោចណាស់មួយ',
  chooseShipingWarehouse: 'សូមជ្រើសរើសឃ្លាំងដឹកជញ្ជូនទំនិញ',
  chooseType: 'ជ្រើសរើសប្រភេទ',
  churukuProductQty: 'ចំនួនទំនិញដែលចេញចូលឃ្លាំង',
  city: 'ក្រុង',
  classification: 'ប្រភេទ',
  classify: 'បែងចែកប្រភេទ',
  clickNodeList: 'សូមចុចរើសយកចំណុចតភ្ជាប់ពីបញ្ជីចំណុចតភ្ជាប់សិន!!!',
  clickSelectGoods: 'សូមចុចដើម្បីជ្រើសរើសទំនិញ',
  clickUpload: 'ចុចដើម្បីបញ្ជូនឡើង',
  client: 'អតិថិជន',
  editClient: 'កែសម្រួលអតិថិជន',
  clientConfirm: 'ការបញ្ជាក់ពីអតិថិជន',
  clientman: 'ការគ្រប់គ្រងអតិថិជន',
  closed: 'បានបិទ',
  cm: 'cm',
  orderInfo: "ព័ត៌មានបញ្ជាទិញ",
  code: 'លេខសម្គាល់',
  codeClassification: 'លេខកូដទំនិញ',
  codeTip1: 'លេខកូដទុកផ្ទៀងផ្ទាត់បានផ្ញើរទៅរួចហើយ សូមត្រួតពិនិត្យមើលដោយប្រុងប្រយ័ត្ន',
  codeTip2: 'សូមវាយបញ្ចូលលេខទូរស័ព្ទដែលត្រឹមត្រូវ',
  codeTip3: 'ការធ្វើឱ្យគណនីទៅជាសកម្មបានជោគជ័យ',
  codeTip4: 'លេខកូដសម្ងាត់របស់គណនីផ្លាស់ប្ដូរបានសម្រេច សូមចូលម្តងទៀត',
  codeTip5: 'ដូរលេខកូដសម្ងាត់',
  codeTip6: 'គណនីនេះតម្រូវអោយធ្វើអោយសកម្ម តើធ្វើអោយវាសកម្មវិញទេ',
  codeTip7: 'រក្សាសិទ្ធគ្រប់យ៉ាង',
  coding: 'ការសរសេរកូដ',
  Colum: 'ជួរដេក',
  comAddress: 'អាស័យដ្ធានក្រុមហ៊ុន',
  comment: 'បញ្ចេញមតិ',
  commentDetail: 'មតិបញ្ជាទិញលម្អិត',
  commentInfo: 'កំណត់ត្រាទំនាក់ទំនង',
  company: 'ក្រុមហ៊ុន',
  companyMgm: 'គ្រប់គ្រងក្រុមហ៊ុន',
  companyAddress: 'អាស័យដ្ធានក្រុមហ៊ុន',
  companyChineseName: 'ឈ្មោះក្រុមហ៊ុនជាភាសាចិន',
  companycontact: 'ទំនាក់ទំនងក្រុមហ៊ុន',
  companyContactNumber: 'លេខទំនាក់ទំនងក្រុមហ៊ុន',
  companyEmail: 'អ៊ីមែលក្រុមហ៊ុន',
  companyFax: 'ទូរសាក្រុមហ៊ុន',
  companyInfo: 'ព័ត៌មានក្រុមហ៊ុន',
  companyName: 'ឈ្មោះក្រុមហ៊ុន',
  companyNameShort: 'ឈ្មោះ​ខ្លី',
  backgroundcolor: 'ពណ៌ផ្ទៃខាងក្រោយ',
  fontcolor: 'ពណ័ពុម្ពអក្សរ',
  logo: 'រូបសញ្ញា',
  background: 'ផ្ទៃខាងក្រោយ',
  companyName1: 'ឈ្មោះក្រុមហ៊ុនជាភាសាអង់គ្លេស',
  companyName2: 'ឈ្មោះក្រុមហ៊ុន',
  companyName3: 'ឈ្មោះក្រុមហ៊ុន',
  complain: 'តវ៉ា',
  FrieghtView: 'គ្មានការកំណត់ពុម្ព',
  customerAccount: 'អាខោនរបស់អតិថិជន',
  Gathering: 'ការប្រមូលផ្តុំ',
  complaintDate: 'ថ្ងៃតវ៉ា',
  complaintGoods: 'តវ៉ាពីទំនិញ',
  ComplaintList: 'បញ្ជីតវ៉ា',
  complaintNumber: 'ចំនួនតវ៉ា',
  complaintRecord: 'កំណត់ត្រាការតវ៉ា',
  Complaints: 'តវ៉ា',
  complete: 'បានសម្រច',
  Completed1: 'ជោគជ័យ(ការបញ្ជាទិញទាំងមូលបានដឹកជញ្ជុនរួចរាល់)',
  completeQC: 'QCបានសម្រេច',
  confirm: 'ប្រាកដ',
  Confirm: 'រក្សាទុក',
  confirm3: 'ប្រាកដ',
  Confirm5: 'ប្រាកដ',
  confirmAdd: 'បញ្ជាក់ការបន្ថែម',
  confirmAudit: 'បញ្ជាក់ត្រួតពិនិត្យ',
  confirmCancelPickList: 'ប្រាកដថាលុបបញ្ជីជ្រើសរើសបច្ចុប្បន្ម?',
  confirmChange: 'បញ្ជាក់ការកែសម្រួល',
  confirmCom: 'បញ្ជាក់បានសម្រេច',
  confirmDelCaiGou: 'បញ្ជាក់ថានឹងលុបអ្នកទិញបច្ចុប្បន្ន?',
  confirmDelCurrentRole: 'ប្រាកដលុបតួនាទីបច្ចុប្បន្នចោល',
  confirmDelCurrentSale: 'បញ្ជាក់ថានឹងលុបអង្គភាពលក់បច្ចុប្បន្ន?',
  confirmDelCurrentSuppler: 'ប្រាកដថាលុបឈ្មោះអ្នកផ្គត់ផ្គង់បច្ចុប្បន្ន',
  ConfirmDelete: 'ប្រាកដថាលុបចោលព័ត៌មានធនាគារបច្ចុប្បន្ន?',
  confirmDelivery: 'បញ្ជាក់ការដឹកជញ្ជូន',
  confirmedReturn: 'បញ្ជាក់ការបង្វិលប្ដូរ',
  confirmModify: 'កែសម្រួលបានសម្រេច',
  confirmOperation: 'តើព្រមទទួលស្គាល់ប្រតិបត្តិការនេះទេ?',
  confirmOrder: 'បញ្ជាក់បញ្ជាទិញ',
  confirmPass: 'លេខសម្ងាត់ប្រាកដ',
  confirmReceiptRefund: 'បញ្ជាក់ទទួលបានទំនិញប្ដូរនិងសងប្រាក់វិញ',
  confirmReview: 'បានបញ្ជាក់/រងចាំការត្រួតពិនិត្យ',
  ConfirmToDeleteThisAccountPeriod: 'ប្រាកដថាលុបគណនីនេះមួយរយៈពេល',
  confirmWithdrawal: 'បញ្ជាក់ការដក',
  confrimDelGoods: 'ប្រាកដថាលុបផលិតផលចោល',
  conifr: 'មិនអនុញ្ញាតអោយកែប្រែក្រោយបញ្ជាក់រួច',
  consignor: 'អ្នកដឹកជញ្ជូន',
  contactDetails: 'របៀបទាក់ទង',
  contactEmail: 'អ៊ីមែលទំនាក់ទំនង',
  contactInfo: 'ព័ត៌មានទំនាក់ទំនង',
  contactIphone: 'លេខទូរស័ព្ទទំនាក់ទំនង',
  contactPerson: 'អ្នកទំនាក់ទំនង',
  contactPersonPhone: 'លេខអ្នកទំនាក់ទំនង',
  contactPhone: ' ទូរស័ព្ទទំនាក់ទំនង',
  content: 'ការពិពណ៌នា',
  contractInformation: 'ព័ត៌មានកិច្ចសន្យា',
  Controller: 'អ្នកទទួលខុសត្រូវ',
  Controllerlist: 'ការជ្រើសរើសបុគ្គលិក',
  copy: 'ចំលង',
  copyOrder: 'ចំលងការបញ្ជាទិញ',
  copyFee: 'ថ្លៃចំលង',
  copyTemp: 'ចម្លងគំរូ',
  CorrespondingPurchaseOrder: 'ការបញ្ជាទិញដែលត្រូវគ្នា',
  cost: 'គណនេយ្យចំណាយ',
  costCategories: 'ចែកប្រភេទចំណាយ',
  costList: 'បញ្ជីចំណាយ',
  Country: 'COuntry',
  country: 'ទ្វីប',
  craft: 'ការគ្រប់គ្រងដំណើរការ',
  CreateCategory: 'បង្កើតការបែងចែកប្រភេទ',
  createCategory: 'បង្កើតប្រភេទ',
  createCategory5: 'បង្កើតការបែងចែកប្រភេទ',
  CreateDepartment: 'បង្កើតនាយកដ្ធាន',
  CreatePurchaseOrder: 'បង្កើតបញ្ជីបញ្ជាទិញ',
  createStock: 'បង្កើតបញ្ជីជ្រើសរើស',
  createTime: 'ម៉ោងផលិត',
  CreateType: 'បង្កើតការបែងចែកប្រភេទ',
  creationTime: 'ពេលវេលាបង្កើត',
  creditCard: 'កាតឥណទាន',
  Cup: 'Cup',
  currency: 'ប្រភេទទឹកប្រាក់',
  currency1: 'រូបិយប័ណ្ណទូទាត់',
  CurrencySymbol: 'លេខកូដតំណាង',
  currentLocation: 'ទីតាំងបច្ចុប្បន្ន',
  customerAcc: 'គណនីរបស់អតិថិជន',
  currentQty: 'ចំនួន/ចំនួនទទួលទំនិញ/ចំនួនបង្វិលប្ដូរ',
  customer: 'អតិថិជន',
  customer_account: 'លេខគណនីអតិថិជន',
  customerAccessories: 'ឯកសារភ្ជាប់របស់អតិថិជន',
  CustomerCreatPendConfirm: 'អតិថិជនបង្កើត/រងចាំការបញ្ជាក់',
  customerEdit: 'កែសម្រួលអតិថិជន',
  customerFinance: 'កំណត់ត្រាបង់ប្រាក់',
  customerID: 'លេខកូដសម្គាល់',
  CustomerID2: 'IDអតិថិជន',
  CustomerList: 'បញ្ជីអតិថិជន',
  customerList: 'បញ្ជីអតិថិជន',
  customerMgr: 'ការគ្រប់គ្រងអតិថិជន',
  customerName: 'ឈ្មោះអតិថិជន',
  customerordernumber: 'លេខបញ្ជាទិញអតិថិជន',
  customerPurchaseOrder: 'លេខប័ណ្ណបញ្ជាទិញ',
  customerQTY: 'ចំនួនអតិថិជន',
  customerType: 'ប្រភេទអតិថិជន',
  cutomerChoose: 'សូមជ្រើសរើសអតិថិជន',
  cutomerInfo: 'ព័ត៌មានអតិថិជន',
  cutomerName: 'ឈ្មោះអតិថិជន',
  daichengtuan: 'រងចាំដាក់ជាក្រុម',
  dashboard: 'ផ្ទាំងព័ត៌មាន',
  data: 'ទិន្នន័យ',
  date: 'កាលបរិច្ឆេទ',
  dateBirth: 'ថ្ងៃខែឆ្នាំកំណើត',
  dateOfDelivery: 'ថ្ងៃដឹកជញ្ជូន',
  dateOfReceipt: 'ថ្ងៃទទួលបានសំណង',
  dateReceipt: 'ថ្ងៃប្រមូលប្រាក់',
  DateType1: 'ប្រភេទទិន្នន័យ',
  DateTypes: 'ប្រភេទទិន្នន័យ',
  day: 'ថ្ងៃ',
  defective: 'ទំនិញគុណភាពអន់',
  degrees: 'degrees',
  delCurrentFeeLogTip: 'ប្រាកដលុបចោលកំណត់ត្រាចំណាយបច្ចុប្បន្ន ?',
  delCurrentGoodsTip: 'ប្រាកដលុបទំនិញបច្ចុប្បន្នចោល?',
  delCurrentGoodsTip2: 'ប្រាកដថាលុបចោលការបែងចែកបច្ចុប្បន្ន?',
  delCurrentOrderPayTip: 'ប្រាកដថាលុបការបញ្ជាទិញបច្ចុប្បន្ន',
  delCurrentPersonInfo: 'ប្រាកដថាលុបចោលព័ត៌មានផ្ទាល់ខ្លួនបចុប្បន្ន?',
  delDepartmentTip: 'ប្រាកដលុបនាយកដ្ធានបច្ចុប្បន្ននេះចោល?',
  delete: 'លុបចោល',
  Delete: 'លុបចោល',
  deleteAD: 'ប្រាកដលុបចោលការផ្សាយពាណិជ្ជកម្មបច្ចុប្បន្នរឺទេ?',
  deleteAdminInfo: 'ប្រាកដលុបចោលព័ត៌មានអ្នកគ្រប់គ្រងបច្ចុប្បន្ន?',
  deleteArticle: 'ប្រាកដលុបចោលអត្ថបទបច្ចុប្បន្ននេះទេ?',
  deleteCurrenNodeInfo: 'ប្រាកដលុបចោលព័ត៌មានចំណុចតភ្ជាប់បច្ចុប្បន្ន?',
  deleteCurrentForm: 'ប្រាកដលុបចោលបញ្ជីបច្ចុប្បន្ន?',
  deleteCurrentTemplate: 'ប្រាកដលុបចោលពុម្ពគំរូបច្ចុប្បន្ននេះ?',
  deleteGoods: 'ប្រាកដលុបចោលព័ត៌មានទំនិញបច្ចុប្បន្ន?',
  deleteNode: 'ប្រាកដលុបចោលចំណុចតភ្ជាប់បច្ចុប្បន្ននេះរឺទេ?',
  deleteNode1: 'លុបចោលចំណុចតភ្ជាប់',
  deleteNodeData: 'ប្រាកដលុបចោលទិន្នន័យចំណុចតភ្ជាប់បច្ចុប្បន្ននេះរឺទេ?',
  deleteSuccess: 'លុបចោលបានជោគជ័យ',
  deleteWarehouse: 'ប្រាកដលុបចោលព័ត៌មានឃ្លាំងទំនិញបច្ចុប្បន្ន?',
  delFinanceLogTip: 'ប្រាកដថាចង់លុបកំណត់ហេតុហិរញ្ញវត្ថុបច្ចុប្បន្ន?',
  deliery_addr: 'អាស័យដ្ធានពិតប្រាកដ',
  Delivered: 'ដឹកជញ្ជូនរួច',
  deliveryCompleted: 'ដឹកជញ្ជូន(ជោគជ័យ)',
  deliveryCompOrderList: 'បានសម្រេច',
  deliveryDate: 'ថ្ងៃដឹកជញ្ជូន',
  arrivalDate: 'ថ្ងៃទំនិញទៅដល់',
  deliveryDiscipline: 'កំណត់ត្រាដឹកជញ្ជូន',
  deliveryMethod: 'វិធីដឹកជញ្ជូន',
  deliveryOfGoods: 'ដឹកជញ្ជូនទំនិញ',
  deliveryOrderList: 'រងចាំដឹកជញ្ជូន',
  deliveryRecord: 'កំណត់ត្រាដឹកជញ្ជូន',
  delOrderComplaintTip: 'ប្រាកដថាអ្នកចង់លុបចោលកំនត់ត្រាការតវ៉ាបច្ចុប្បន្ននេះ?',
  delOrderTip: 'ប្រតិបត្តិការនេះនឺងលុបចោលការបញ្ជាទិញបច្ចុប្បន្ន បន្តរឺទេ?',
  department: 'នាយកដ្ធាន',
  departmenInfo: 'ព័ត៌មាននាយកដ្ឋាន',
  departmentList: 'ទាំងអស់',
  departmentsList: 'បញ្ជីនាយកដ្ធាន',
  departName: 'ឈ្មោះនាយកដ្ធាន',
  departmentHead: 'ប្រធាននាយកដ្ឋាន',
  deposit: 'ការដាក់ប្រាក់',
  des: 'ការពិពណ៌នាបញ្ហា',
  deselect: 'បដិសេធការជ្រើសរើស',
  detail: 'ពត៌មានលំអិត',
  determineDeleteRecord: 'តើអ្នកពិតជាចង់លុបកំណត់ត្រាដឹកជញ្ជូននេះចោលឬ?',
  determinedPerson: 'អ្នកប្រាកដ',
  determineTime: 'ពេលវេលាប្រាកដ',
  district: 'តំបន់',
  Dollar: 'លុយដុល្លារសហរដ្ធអាមេរិច (USD)',
  domesticFreight: 'ថ្លៃដឹកជញ្ជូនក្នុងស្រុក',
  DomestiSales: 'លក់ក្នុងស្រុក',
  dosage: 'កំរិតប្រើ',
  download: 'ទាញយក',
  dragFileHere: 'សូមទាញឯកសារមកដាក់ទីនេះ រឺ',
  dryclfl: 'សូមជ្រើសរើសចំណាត់ថ្នាក់នៃវត្ថុធាតុដើមដែលនាំចូល',
  duties: 'ទំនួលខុសត្រូវការងារ',
  edit: 'កែសម្រួល',
  editNode: 'កែសម្រួលចំណុចប្រសព្វ',
  editNodeData: 'កែសម្រួលទិន្នន័យចំណុចតភ្ជាប់នៅក្នុងពុម្ពគំរូ',
  editorialStaff: 'កែសម្រួលបុគ្គលិក',
  EditPanel: 'កែសម្រួល',
  editStock: 'កែសម្រួលបញ្ជីជ្រើសរើស ',
  eidtSuccess: 'កែសម្រួលបានសម្រេច',
  eidtUserInfo: 'កែសម្រួលព័ត៌មានផ្ទាល់ខ្លួន',
  personalEmail: 'អ៊ីមែលផ្ទាល់ខ្លួន',
  email: 'អ៊ីមែល',
  emergencyPerson: 'អ្នកទាក់ទងពេលមានអាសន្ន',
  emergencyPhone: 'លេខទូរស័ព្ទប្រើពេលមានអាសន្ន',
  employeeId: 'លេខសម្គាល់បុគ្គលិក',
  enclosure: 'ឯកសារភ្ជាប់',
  endDate: 'ថ្ងៃបញ្ចប់',
  endMonth: 'ខែបញ្ចប់',
  english: 'អង់គ្លេស',
  enterAccount: 'សូមវាយបញ្ចូលលេខទូរស័ព្ទ',
  enterAddress: 'សូមវាយបញ្ចូលអាស័យដ្ធាន',
  enterBankAcc: 'សូមវាយបញ្ចូលលេខគណនីធនាគារ',
  enterbankCountry: 'សូមវាយបញ្ចូលប្រទេសនៃធនាគារ',
  enterBankName: 'សូមវាយបញ្ចូលធនាគារដែលបានបើកគណនី',
  enterbankSwiftCode: 'សូមវាយបញ្ចូល Bank Swift Code',
  enterCategoryCode: 'សូមវាយបញ្ចូលការបែកចែកលេខកូដ',
  enterComplete: 'សូមបំពេញអោយពេញលេញ',
  enterContact: 'សូមវាយបញ្ចូលអ្នកទាក់ទង',
  enterCorrectEmailAddress: 'សូមវាយបញ្ចូលអ៊ីមែលដែលត្រឹមត្រូវ',
  enterCorrectPhoneNumber: 'សូមវាយបញ្ចូលលេខទូរស័ព្ទដែលត្រឹមត្រូវ',
  enterEmail: 'សូមវាយអ៊ីមែលចូល',
  entereProduct: 'សូមវាយបញ្ចុលទំនិញ',
  enterExcelExport: 'សូមជ្រើសរើសយកឯកសារប្រភេទទម្រង់Excelមកបញ្ចូលចូល',
  enterFactoryName: 'សូមវាយបញ្ចូលឈ្មោះរោងចក្រ',
  enterFax: 'សូមវាយបញ្ចូលទូរសារ',
  enterGoodsChineseName: 'សូមវាយឈ្មោះទំនិញជាភាសាចិន',
  enterGoodsClassName: 'សូមវាយបញ្ចុលឈ្មោះបែងចែកទំនិញ',
  enterGoodsCode: 'សូមវាយលេខសម្គាល់ទំនិញ',
  enterGoodsEnglishName: 'សូមវាយឈ្មោះទំនិញជាភាសាអង់គ្លេស',
  enterGoodsPrice: 'សូមវាយបញ្ចូលតម្លៃទំនិញ',
  enterInfo: 'ព័ត៌មានចូល',
  enterName: 'សូមវាយឈ្មោះចូល',
  enterNameSearch: 'សូមវាយបញ្ចូលការស្វែងរកឈ្មោះ',
  enterNewPass: 'សូមវាយលេខសម្ងាត់ថ្មីចូល',
  enterOldPass: 'សូមវាយលេខសម្ងាត់ចាស់ចូល',
  enterPassWord: 'សូមវាយបញ្ចូលលេខកូដសម្ងាត់',
  enterPayPerson: 'សូមវាយបញ្ចូលដៃគូរទទួលការបង់ប្រាក់',
  enterPhone: 'សូមវាយលេខទូរស័ព្ទចូល',
  enterPhone1: 'សូមវាយបញ្ចូលលេខទូរស័ព្ទ',
  enterPoGoodsDrafetTip: 'សូមបញ្ចូលPO/ទំនិញ/លេខសម្គាល់រូបគំនូរលជាដើម',
  enterPrincipal: 'សូមវាយបញ្ចូលអ្នកទទួលខុសត្រូវ',
  enterQueryCriteria: 'សូមវាយបញ្ចូលលក្ខខណ្ឌសំណួរ',
  enterSearchContent: 'សូមវាយបញ្ចូលខ្លឹមសារសំណួរ',
  enterSearchOrder: 'សូមវាយបញ្ចូលលេខស្វែងរកបញ្ជាទិញ',
  enterStockId: 'សូមវាយបញ្ចូលលេខឃ្លាំងទំនិញ',
  enterStockName: 'សូមវាយបញ្ចូលឈ្មោះឃ្លាំងទំនិញ',
  enterSupplierClassName: 'សូមវាយបញ្ចូលការបែងចែកប្រភេទឈ្មោះនៃអ្នកផ្គត់ផ្គង',
  entersupplierRating: 'សូមវាយបញ្ចូលការវាយតម្លៃ',
  enterTime: 'ម៉ោងចូល',
  enterValidPhoneNumber: 'សូមបញ្ចូលលេខទូរស័ព្ទដែលអាចប្រើប្រាស់បាន',
  enterYouMessage: 'សូមវាយសាររបស់អ្នកទុកនៅទីនេះ',
  error: 'មានបញ្ហា',
  errorCode: 'លេខកូដមានបញ្ហា:',
  examinationPassed: 'បានឆ្លងកាត់ការត្រួតពិនិត្យ',
  exchangeRate: 'អត្រាប្ដូរប្រាក់',
  existsAgain: 'មានរួចហើយ សូមធ្វើការជ្រើសរើសម្ដងទៀត',
  expenditure: 'ការចំណាយ',
  expenseLog: 'កំណត់ហេតុចំណាយ',
  expireDate: 'ថ្ងៃផុតកំណត់',
  export: 'នាំចេញ',
  Export2: 'នាំចេញ',
  exportKingdeeTemplete: 'ដឹកជញ្ជូនKingdeeចេញ',
  exportPdf: 'បញ្ចេញជាPDF',
  extendedInfo: 'ព័ត៌មានបន្ថែម',
  facebook: "Facebook",
  FactoryAccessories: 'ឯកសារភ្ជាប់របស់រោងចក្រ',
  factoryAppendixUpload: 'បញ្ជូនឯកសារភ្ជាប់រោងចក្រ',
  factoryCopyFee: 'តម្លៃចម្លងរោងចក្រ',
  factoryEdit: 'កែសម្រួលរោងចក្រ',
  factoryList: 'បញ្ជីឈ្មោះរោងចក្រ',
  factoryMgr: 'ការគ្រប់គ្រងរោងចក្រ',
  factoryPrice: 'តម្លៃរោងចក្រ',
  factoryUnitPrice: 'តម្លៃឯកតារោងចក្រ',
  fax: 'ទូរសារ',
  feedbackDetail: 'មតិប្រតិកម្មលម្អិត',
  feeLog: 'កំណត់ហេតុចំណាយ',
  fenLei: 'ប្រភេទ',
  fieldService: 'ការងារក្រៅ',
  fileHasBeenUpload: 'ឯកសារត្រូវបានបញ្ជូន',
  fileList: 'បញ្ជីឯកសារ',
  filename: 'ឈ្មោះឯកសារ',
  filesUpload: 'បញ្ជូនឯកសារ',
  FinanceController: 'បុគ្គលិកហិរញ្ញវុត្ថុ',
  financeLog: 'ទិនានុប្បវត្តិហិរញ្ញវត្ថុ',
  financeSend: 'បញ្ជាក់ពីខាងហិរញ្ញវត្ថុ',
  financialMgr: 'ការគ្រប់គ្រងហិរញ្ញវត្ថុ',
  findPassword: 'យកលេខកូដសម្ងាត់មកវិញ',
  finishedDate: 'ថ្ងៃបញ្ចប់',
  finishedProductType: 'ប្រភេទទំនិញសម្រេច',
  fkAmount: 'លេខគណនីខាងបង់ប្រាក់',
  fkComAddress: 'អាស័យដ្ធានក្រុមហ៊ុនបង់ប្រាក់',
  fkComtact: 'អ្នកទំនាក់ទំនងខាងបង់ប្រាក់',
  fkPhone: 'លេខទូរស័ព្ទទំនាក់ទំនងខាងបង់ប្រាក់',
  floatcargo: 'ទំនិញមិនទាន់មកដល់',
  forgetPassword: 'ភ្លេចលេខកូដសម្ងាត់',
  freeSample: 'គំរូឥតគិតថ្លៃ',
  freight: 'ថ្លៃដឹកជញ្ជូន',
  Function: 'ជ្រើសរើសមុខងារ',
  FunctionCode: 'លេខសម្គាល់មុខងារ',
  FunctionList: 'បញ្ជីមុខងារ',
  Fundamentaldata: 'បញ្ជីទិន្នន័យមូលដ្ឋាន',
  gathering: 'បង្កាន់ដៃ',
  generalPurchase: 'ការទិញធម្មតា',
  GiveAway: 'ថែមជូណ',
  Glass: 'Glass',
  goods: 'ទំនិញ',
  goodsAdd: 'បន្ថែមទំនិញ',
  goodsCategory: 'ប្រភេទទំនិញ',
  goodsCode: 'លេខកូដទំនិញ',
  goodsDesc: 'ការពិពណ៌នាទំនិញ',
  goodsEdit: 'កែសម្រួលទំនិញ',
  goodsInfo: 'ព័ត៌មានទំនិញ',
  goodsList: 'បញ្ជីផលិតផល',
  goodsListCategory: 'ទំនិញ',
  goodsMgr: 'ការគ្រប់គ្រងទំនិញ',
  goodsName: 'ឈ្មោះទំនិញ',
  goodsParamTemplate: 'ពុម្ពគំរូ',
  goodsShelves: 'ផលិតផលនៅលើធ្នើ',
  goodsTotal: 'ចំនួនសរុបនៃទំនិញ',
  goodsTotalPrice: 'តម្លៃសរុបនៃទំនិញ',
  goodUnit: 'ឯកតាវាស់វែងទំនិញ',
  gross_weight: 'ទំងន់សរុប',
  guanlixitong: 'ប្រព័ន្ធគ្រប់គ្រង',
  HalfDomestic: 'ពាក់កណ្តាលនៃការលក់ក្នុងស្រុក',
  halfDomesticSales: 'លក់ក្នុងស្រុកពាក់កណ្ដាល',
  handlingMethod: 'វិធីដោះស្រាយ',
  hardware: 'Hardware',
  hardwareSoftwareSystem: 'ប្រព័ន្ធ Hardware and software',
  hasMakeOutAnIncoice: 'បានចេញវិក័យប័ត្រ',
  havePayed: 'បង់ប្រាក់រួច',
  hierarchy: 'កំរិត',
  history: 'ប្រវត្តិ',
  holiday: 'ឈប់សំរាក',
  home: 'ទំព័រដើម',
  huodaofukuan: 'ទូទាត់ពេលទទួលបានទំនិញ',
  ID: 'លេខសម្គាល់',
  idLibrary: 'បញ្ជីID',
  IDNo: 'លេខអត្តសញ្ញាណប័ណ្ណ',
  idPass: 'អត្តសញ្ញាណប័ណ្ណ/ប៉ាស្ព័រ',
  image: 'រូបភាព',
  imageSizeTip: 'ហួសទំហំធំតូចដែលបានកំណត់',
  imgUploadTip1: 'ទាញយកឯកសារមកដាក់ទីនេះ រឺ',
  imgUploadTip2: 'ចុចដើម្បីបញ្ជូនឡើង',
  imgUploadTip3: 'បញ្ជូនឡើងបានតែឯកសារទម្រង់កំណត់ឡើងវិញ ហើយមិនច្រើនជាង',
  important: 'សំខាន់',
  importExportDury: 'សេចក្តីប្រកាសនាំចេញជាផ្លូវការ',
  importSuccess: 'បញ្ចូលចូលបានសម្រច',
  importUUID: 'បញ្ជូនUIDឡើង',
  ImproveLogisticsInformation: 'បំពេញព័ត៌មានដឹកជញ្ជូនអោយបានច្បាស់ពេញលេញ',
  inch: 'inch',
  incoiceTitle: 'ផ្តើមក្បាលវិក័យប័ត្រ',
  income: 'ចំណូល',
  incomeExpenditureType: 'ចែកប្រភេទចំណូល',
  inOut: 'ព័ត៌មានឃ្លាំងទំនិញ',
  inportSuccess: 'បញ្ចូលបានសម្រេច',
  internalSupplier: 'អ្នកផ្គត់ផ្គង់ផ្ទៃក្នុង',
  internalSupplier1: 'អតិថិជនផ្ទៃក្នុង',
  intragroupCode: 'Intragroup Code',
  inventory: 'ក្នុងស្តុក',
  invertedNumber: 'លេខវេន',
  invoice: 'បើកវិក័យប័ត្រ',
  invoiceInfo: 'ព័ត៌មានវិក័យប័ត្រ',
  invoiceMoban: 'ពុម្ពវិក័យប័ត្រ',
  invoiceNo: 'លេខវិក័យប័ត្រ',
  invoiceNumber: 'លេខវិក័យប័ត្រ',
  invoiceTitle: 'ក្បាលវិក័យប័ត្រ',
  isChip: 'បន្ទះឈីប',
  isHasOpen: 'បើកអោយប្រើប្រាស់',
  itemNo: 'លេខសម្គាល់',
  ItemsTTLQty: 'ចំនួនទំនិញសរុប',
  jihuo: 'ការធ្វើឱ្យសកម្ម',
  jihuoAmount: 'ធ្វើឱ្យគណនីសកម្ម',
  jizhuPassword: 'ចងចាំលេខកូដសម្ងាត់',
  keyword: 'ពាក្យសំខាន់',
  KG: 'KG',
  kh: 'រៀល',
  kuaidi: 'ដឹកជញ្ជូនឆាប់រហ័ស',
  kuaidifangshi: 'អ្នកដឹកជញ្ជូនទំនិញ',
  lastQuestion: 'សំណួរចុងក្រោយ',
  lastReply: 'តបចុងក្រោយ',
  lastStep: 'ជំហានមុន',
  lastUpateTime: 'កែសម្រួលលើកចុងក្រោយបង្អស់',
  late: 'យឺត',
  layer: 'layer',
  leaveEarly: 'ចេញមុនម៉ោង',
  level: 'អាទិភាព',
  linkSet: 'កំណត់ការតភ្ជាប់',
  linkType: 'សូមជ្រើសរើសប្រភេទតភ្ជាប់',
  list: 'បញ្ជីមុខងារ',
  loading: 'កំពុងដំណើរការ',
  login: 'ចូលទិន្នន័យ',
  loginFailed: 'ការចូលទិន្នន័យបរាជ័យ!ឈ្មោះគណនីរឺលេខកូដសម្ងាត់មិនត្រឹមត្រូវ',
  incorrectPassowrd: 'លេខកូដសម្ងាត់មិនត្រឹមត្រូវ',
  loginIp: 'ចូលទិន្នន័យ IP',
  loginTime: 'ម៉ោងបញ្ចូលទិន្នន័យ',
  logout: 'ចាកចេញ',
  lookMore: 'មើលបន្ថែមទៀត',
  maijiabeizhu: 'សម្គាល់សំរាប់អ្នកទិញ',
  mail: 'បញ្ជីសារអេឡិចត្រូនិច',
  mailAdderss: 'អាស័យដ្ឋានសំបុត្រ',
  mailInfo: 'ព័ត៌មានសារអេឡិចត្រូនិច',
  makeSureCurentRole: 'បញ្ជាក់លើការលុបមុខងារ',
  makeSureRemoveFile: 'តើអ្នកពិតជាចង់លុបឯកសារនេះចោល?',
  man: 'បុរស',
  manufacturingTechnique: 'ដំណើរការផលិតកម្ម',
  materialName: 'ឈ្មោះគ្រឿងផ្សំ',
  materials: 'វត្ថុធាតុដើម',
  merchantBackstage: 'មជ្ឈមណ្ឌលគ្រប់គ្រងផ្នែកខាងក្រោយនៃអ្នកជំនួញ',
  merchantSystem: 'ប្រព័ន្ធគ្រប់គ្រងអ្នកជំនួញ',
  mingxi: 'ពត៌មានលំអិត',
  missingCard: 'បាត់កាត',
  mobile: 'លេខទូរស័ព្ទ',
  modify: 'កែសម្រួល',
  modifyOrder: 'កែសម្រួលការកុម្ម៉ង់',
  ModifyPanel: 'កែសម្រួលនាយកដ្ធាន',
  modifySave: 'សូមធ្វើការកែសម្រួលរួចរក្សាទុក',
  modifyStatus: 'កែសម្រួលស្ថានភាព',
  modifySuccess: 'កែសម្រួលបានសម្រច',
  modifySuccessInfo: 'កែសម្រួលបានសម្រច ចង់ត្រលប់ទៅទំព័របញ្ជីវិញទេ?',
  month: 'ខែ',
  months: 'ខែ',
  name: 'ឈ្មោះ',
  namePhoneContact: 'សូមវាយបញ្ចូលឈ្មោះ/លេខទូរស័ព្ទ/អ្នកទំនាក់ទំនង',
  nationality: 'សញ្ជាតិ',
  needInvoice: 'រងចាំវិក័យប័ត្រ',
  needPay: 'រងចាំការបង់ប្រាក់',
  net_weight: 'ទម្ងន់សុទ្ធ',
  new: 'បន្ថែមថ្មី',
  NewCurrency: 'បន្ថែមរូបិយប័ណ្ណថ្មី',
  Newdata: 'បន្ថែម',
  newDetails: 'ព័ត៌មានលម្អិតនៃបញ្ជាទិញ',
  NewFunction: 'បន្ថែម',
  newOrder: 'បន្ថែមបញ្ជាទិញថ្មី',
  newOrderAdd: 'បន្ថែម',
  newPassword: 'លេខសម្ងាត់ថ្មី',
  NewRole: 'បន្ថែមតួនាទី',
  news: 'ព័ត៌មាន',
  newSupplierInfo: 'កែសម្រួលព័ត៌មាន',
  nextStep: 'ជំហានបន្ទាប់',
  no: 'ទេ',
  num: 'លេខកូដ',
  no2: 'គ្មាន',
  noChip: 'មិនមានបន្ទះឈីប',
  nodeNoDate: 'ចំណុចប្រសព្វបច្ចុប្បន្នមិនមានទិន្នន័យទេ',
  non_standardProducts: 'ផលិតផលមិនស្តង់ដារ',
  noPai: 'Item No.',
  noPreviewTemplate: 'មិនមានពុម្ពទុកមើលជាមុនទេ សូមបំពេញពុម្ពទុកមើលមុន',
  general: 'ទូទៅ',
  normal: 'ធម្មតា',
  noScantling: 'ទំនិញមិនស្តង់ដារ',
  note: 'កំណត់សម្គាល់',
  Nowadays: 'ថ្ងៃនេះ',
  number: 'ចំនួន',
  NumberOfFactories: 'ចំនួនរោងចក្រ',
  NumberOfGifts: 'ចំនួនកាដូថែមជូន',
  NumberOfSuppliers: 'ចំនួនអ្នកផ្គត់ផ្គង់',
  nweCustomer: 'បន្ថែមថ្មី',
  offStocks: 'បានសម្រច',
  oldPassword: 'លេខសម្ងាត់ចាស់',
  onlinePay: 'ទូទាត់ប្រាក់អនឡាញ',
  onlyExcelFile: 'បញ្ចលបានតែឯកសារប្រភេទexcelតែប៉ុណ្ណោះ',
  OperatTime: 'ពេលវេលាដំណើរការ',
  opt: 'ប្រតិបត្តិការ',
  optTime: 'ម៉ោងប្រតិបត្តិការ',
  optUser: 'អ្នកប្រតិបត្តិការ',
  order: 'បញ្ជាទិញ',
  customerSetting: 'ជ្រើសរើសការគ្រប់គ្រងអតិថិជន',
  orderAmount: 'តម្លៃបញ្ជាទិញ',
  orderCheck: 'រងចាំការត្រួតពិនិត្យ',
  orderChoose: 'ជ្រើសរើសបញ្ជាទិញ',
  orderComment: 'ការតវ៉ា',
  orderCommentDetail: 'ព័ត៌មានលម្អិតពីការតវ៉ាអំពីការកម្ម៉ង់',
  orderConfirm: 'បញ្ជាក់ការបញ្ជាទិញ',
  orderGoods: 'ទំនិញក្នុងបញ្ជាទិញ：',
  orderDetails: 'ព័ត៌មានលម្អិតការបញ្ជាទិញ',
  orderInfo: 'ទិដ្ឋភាពទូទៅនៃការបញ្ជាទិញ',
  orderList: 'បញ្ជីបញ្ជាទិញ',
  orderMgr: 'ការគ្រប់គ្រងការបញ្ជាទិញ',
  orderNo: 'លេខបញ្ជាទិញ',
  orderNoSearchTip: ' សូមបញ្ចូលលេខបញ្ជាលក់ដែលជាប់ទាក់ទង',
  orderNumber: 'ចំនួនបញ្ជាទិញ',
  orderPlace: 'រងចាំការបញ្ជាទិញ',
  orderPo: 'បញ្ជាទិញPO',
  orderPoSearch: 'សូមវាយបញ្ចូលលក្ខខណ្ឌសាកសួរ',
  orderPrice: 'តម្លៃបញ្ជាទិញ',
  orderQty: 'ចំនួនបញ្ជាទិញ',
  orderReview: 'ត្រួតពិនិត្យការបញ្ជាទិញ',
  Orders: 'បញ្ជាទិញ',
  orderSale: 'តំណាងលក់：',
  orderStatistics: 'ស្ថិតិនៃបញ្ជាទិញ',
  orderDate: 'ថ្ងៃដាក់បញ្ជាទិញ',
  orderTime: 'ម៉ោងបញ្ជាទិញ',
  orderTotalPrice: 'តម្លៃបញ្ជាទិញសរុប',
  orderyield: 'លំដាប់ផលិតកម្ម',
  other: 'ផ្សេងៗ',
  otherContact: 'អ្នកទំនាក់ទំនងផ្សេងៗ',
  otherInformation: 'ព័ត៌មានផ្សេងៗ',
  outerChain: 'តំណភ្ជាប់ខាងក្រៅ',
  OutOfStock: 'អស់ពីស្តុក',
  Outsourcing: 'កម្លាំងពលកម្មខាងក្រៅ',
  Outsourcingss: 'កម្លាំងពលកម្មខាងក្រៅ',
  outsourcingType: 'ប្រភេទប្រភពខាងក្រៅ',
  outStock: 'ចេញពីឃ្លាំង',
  overseasFreight: 'ថ្លៃដឹកជញ្ជូនក្រៅស្រុក',
  overtime: 'ថែមម៉ោង',
  packing: 'វេចខ្ចប់',
  paid: 'បង់ប្រាក់រួច',
  paymentReceived: 'ទទួលប្រាក់រួច',
  paidAmount: 'បង់ប្រាក់រួច/ចំនួនទឹកប្រាក់ពីការបញ្ជាទិញ',
  parameter: 'ប៉ារ៉ាម៉ែត្រ',
  ParameterTemplateClassification: 'ចំណាត់បែងចែកថ្នាក់ពុម្ពគំរូ',
  parameterTemplateList: 'បញ្ជីពុម្ពគំរូ',
  parentCategory: 'ប្រភេទមេ',
  particulars: 'ព័ត៌មានលម្អិត',
  passport: 'ប៉ាស្ព័រ/លិខិតឆ្លងដែន',
  passportName: 'ឈ្មោះនៅលើប៉ាស្ព័រ',
  passResetSuccess: 'ទាញយកលេខកូដបានជោគជ័យ',
  password: 'លេខសម្ងាត់',
  passwordCannotLess: 'លេខកូដសម្ងាត់មិនអាចតិចជាង៦ខ្ទង់',
  passwordNotmatch: 'លេខសម្ងាត់ថ្មីនិងលេខសម្ងាត់ប្រាកដមិនដូចគ្នា',
  pay: 'បង់ប្រាក់',
  payed: 'ទទួលបាន',
  applyDate: "ថ្ងៃដាក់ពាក្យ",
  expense: "ចំណាយ",
  selecttype: "ជ្រើសរើសប្រភេទ",
  payable: 'ប្រាក់មិនទាន់ទូទាត់',
  paying: 'កំណត់ត្រាបង់ប្រាក់',
  payingEdit: 'កែសម្រួលការបង់ប្រាក់',
  PaymenSerialNumber: 'លេខសម្គាល់ការបង់ប្រាក់',
  payment: 'អ្នកបង់ប្រាក់',
  payment2: 'បង់ប្រាក់',
  paymentAccount: 'គណនីទទួល',
  paymentAmount: 'ចំនួនទឹកប្រាក់ដែលត្រូវបង់',
  paymentCompany: 'ក្រុមហ៊ុនបង់ប្រាក់',
  paymentContactPerson: 'ទំនាក់ទំនងដៃគូរទទួលការបង់ប្រាក់',
  paymentDate: 'ថ្ងៃបង់ប្រាក់',
  paymentDays: 'រយៈពេលបង់ប្រាក់',
  paymentHasBeenReceived: 'ទទួលបានប្រាក់',
  payMentInfo: '',
  PaymentInformation: 'ឯកសារបង់ប្រាក់',
  paymentLimit: 'ការកំណត់នៃការបង់ប្រាក់',
  paymentOrder: 'បង្កាន់ដៃ',
  PaymentOrder: 'បញ្ជាបង់ប្រាក់',
  paymentRecords: 'កំណត់ត្រាប្រមូល',
  PaymentSlip: 'ប័ណ្ណបង់ប្រាក់',
  paymentSuccessful: 'បង់ប្រាក់ទូទាត់បានសម្រេច',
  paymentTerm: 'រយៈពេលនៃការបង់ប្រាក់',
  paymentterm: 'រយៈពេលបង់ប្រាក់',
  PaymentTerm1: 'បង់ប្រាក់ប្រចាំខេ',
  PaymentTermMgr: 'បន្ថែម',
  PaymentTime: 'ម៉ោងបង់ប្រាក់',
  Pcs: 'Pcs',
  PendingOpenInvoice: 'រងចាំបើកវិក័យប័ត្រ',
  pendingOrder: 'រង់ចាំការបញ្ជាទិញ',
  pendingPicklist: 'រងចាំបញ្ជីជ្រើសរើស',
  pendingPurchase: 'រងចាំការបញ្ជាទិញ',
  permitNo: 'លេខលិខិតអនុញ្ញាត',
  permitSearchTip: 'សូមបញ្ចូលលេខនៃការជ្រើសរើសសំណួរ',
  personalInformation: 'ព័ត៌មានផ្ទាល់ខ្លួន',
  personalizedProcess: 'ដំណើរការផ្ទាល់ខ្លួន',
  personList: 'បញ្ជីបុគ្គលិក',
  phone: 'លេខទូរស័ព្ទ',
  phone2: 'លេខទូរស័ព្ទទី២',
  phoneCannotEmpty: 'កន្លែងបំពេញទូរស័ព្ទមិនត្រូវទទេ',
  phoneNotNull: 'លេខទូរស័ព្ទមិនអាចគ្មានអ្វីទាំងអស់',
  pick: 'បញ្ជីជ្រើសរើស',
  pickEdit: 'កែសម្រួលបញ្ជីជ្រើសរើស',
  picking: 'តារាងបញ្ជីជ្រើសរើស',
  pickingListReview: 'ត្រួតពិនិត្យបញ្ជីជ្រើសរើស',
  pickNew: 'បញ្ជីជ្រើសរើសថ្មី',
  placeOrder: 'ដាក់បញ្ជាទិញ',
  plaeasEnterTaxRate: 'សូមវាយបញ្ចូលអត្រាពន្ធ',
  plannedArrival: 'គ្រោងមកដល់',
  plannedArrivalR1: ' គ្រោងមកដល់ 1',
  plannedArrivalR2: ' គ្រោងមកដល់ 2',
  pleaseAddGoods: 'សូមបន្ថែមទំនិញ',
  pleaseAddGoodsNumber: 'សូមបន្ថែមចំនួនទំនិញ',
  pleaseAddNumber: 'សូមបន្ថែមចំនួន',
  pleaseAddProductFirst: 'សូមបន្ថែមទំនិញជាមុនសិន',
  PleaseAddProductQuantity: 'សូមបន្ថែមចំនួនទំនិញ',
  PleaseAddPurchaser: 'សូមបន្ថែមអ្នកទិញទំនិញ',
  PleaseAddTaxRate: 'សូមវាយបញ្ចូលអត្រាពន្ធ',
  pleaseAddTypeFeeLog: 'សូមបន្លែមការបែងចែកប្រភេទចំណាយជាមុនសិន',
  PleaseBankAccounts: 'សូមវាយបញ្ចូលលេខគណនីធនាគារ',
  pleaseCangKu: 'សូមជ្រើសរើសឃ្លាំងទំនិញ',
  pleaseChoose: 'សូមជ្រើសរើស',
  pleaseChooseGoods: 'សូមជ្រើសរើសទំនិញ',
  pleaseChoosePoNo: 'សូមជ្រើសរើសលេខបញ្ជាទិញ',
  pleaseChooseRoles: 'សូមជ្រើសរើសមុខងារ',
  PleaseCompleteTheInformation: 'សូមបំពេញព័ត៌មានអោយគ្រប់',
  PleaseCompleteTheTrackingNumber: 'សូមបំពេញលេខតាមដានការដឹកជញ្ជូន',
  pleaseEditPassSame: 'សូមវាយលេខសម្ងាត់ដែលកែប្រែថ្មីចូលម្តងទៀត',
  PleaseEnterBankAccountNumber: 'សូមវាយបញ្ចូលលេខគណនីធនាគារ',
  pleaseEnterCancelReason: 'សូមបំពេញមូលហេតុនៃការលុបចោល',
  pleaseEnterFenleiName: 'សូមវាយបញ្ចូលឈ្មោះពេញនៃការបែងចែកប្រភេទទំនិញ',
  pleaseEnterFormName: 'សូមវាយបញ្ជូលឈ្មោះក្នុងទម្រង់!',
  pleaseEnterGoodsNumber: 'សូមបញ្ចូលចំនួនទំនិញ',
  pleaseEnterName: 'សូមវាយបញ្ចូលឈ្មោះ',
  pleaseEnterNewPass: 'សូមបញ្ចូលលេខសម្ងាត់ថ្មី',
  pleaseEnterProblemDesc: 'សូមវាយបញ្ចូលការពិពណ៌នាទំនិញ',
  pleaseEnterProcessMethod: 'សូមវាយបញ្ចូលវិធីដោះស្រាយ',
  pleaseEnterPurchaseNo: 'លេខបញ្ជាទិញ',
  pleaseEntersaleList: 'សូមបញ្ចូលប័ណ្ណលក់ដែលជាប់ទាក់ទង',
  pleaseEnterStockName: 'សូមវាយបញ្ចូលឈ្មោះឃ្លាំងទំនិញ',
  pleaseEnterThanZero: 'សូមវាយបញ្ចូលលេខដែលធំជាងសូន្យ',
  PleaseEnterTheAccountName: 'សូមវាយបញ្ចូលឈ្មោះគណនី',
  PleaseEnterTheBankName: 'សូមវាយបញ្ចូលឈ្មោះធនាគារ',
  pleaseFillActualQuantity: 'សូមវាយបញ្ចូលបំពេញចំនួនពិតប្រាកដ',
  pleaseFillData: 'សូមបំពេញទិន្នន័យចូល',
  PleaseSelectASupplier: 'សូមជ្រើសរើសអ្នកផ្គត់ផ្គង់',
  PleaseSelectCurrency: 'សូមជ្រើសរើសរូបិយប័ណ្ណ',
  pleaseSelectGoods: 'សូមជ្រើសរើសទំនិញ',
  PleaseSelectTheArrivalWarehouse: 'សូមជ្រើសរើសឃ្លាំងដឹកជញ្ជូនទំនិញ',
  PleaseSelectTheBillingPeriod: 'សូមជ្រើសរើសរយៈពេលទូទាត់បង់ប្រាក់',
  pleaseSelectTheOrder: 'សូមជ្រើសរើសបញ្ជាទិញ',
  pleaseSetNewPass: 'សូមរៀបចំលេខកូដសម្ងាត់ថ្មី',
  poMoban: 'ពុម្ពPO ',
  poNo: 'លេខបញ្ជាទិញ',
  position: 'តួនាទី',
  contactPersonPosition: 'តួនាទីអ្នកទំនាក់ទំនង',
  postalCode: 'លេខកូដប្រៃសណីយ៍',
  preview: 'មើលជាមុន',
  price: 'តម្លៃ',
  totalPrice: 'តម្លៃសរុប',
  PriceChange: 'ទំនិញដែលដាក់មិនត្រូវមានតម្លៃលើសពី',
  priceControl: 'កំណត់ការគ្រប់គ្រងតម្លៃ',
  priceTip: 'សូមវាយបញ្ចូលតម្លៃដែលត្រឹមត្រូវ',
  principal: 'អ្នកទទួលខុសត្រូវ',
  print: 'ព្រីន',
  Printing: 'ព្រីន',
  printTip: 'សូមប្រើប្រាស់google រឺ 360browserដើម្បីព្រីន',
  probationSalary: 'ប្រាក់ខែដំបូង',
  problemDesc: 'ការពិពណ៌នាបញ្ហា',
  process: 'ដំណើរការ',
  processingTime: 'ពេលវេលាអនុញ្ញាត',
  procuring: 'ប្រធានបទការទិញ',
  produce: 'ផលិត',
  product: 'ទំនិញ',
  commodity: 'ទំនិញ',
  producing: 'កំពុងទំនិញ',
  productCategory: 'ប្រភេទ',
  productCategoryTip: 'សូមជ្រើសរើសប្រភេទបញ្ជីជ្រើសរើស',
  productChoose: 'ជ្រើសរើសទំនិញ',
  ProductClass: 'ការបែងចែកប្រភេទទំនិញ',
  productDecription: 'ឈ្មោះទំនិញ',
  productDescription: 'ទំនិញ',
  productDescription1: 'ឈ្មោះទំនិញ',
  productEdit: 'កែសម្រួលទំនិញ',
  productId: 'លេខសម្គាល់ទំនិញ',
  productionFactory: 'រោងចក្រផលិត',
  productionOrderNo: 'លេខលំដាប់ផលិតកម្ម',
  productionProcess: 'ដំណើរការផលិតកម្ម',
  productList: 'ទំនិញនៅក្នុងឃ្លាំង',
  productList1: 'បញ្ជីទំនិញ',
  productName: 'ឈ្មោះទំនិញ',
  productPicture: 'រូបភាពទំនិញ',
  productPrice: 'តម្លៃទំនិញ',
  Products: 'ទំនិញ',
  products: 'ទំនិញ',
  productsName: 'ឈ្មោះទំនិញ',
  productUnit: 'ឯកតាទំនិញ',
  profit: 'ចំណេញ',
  ProhibitEditingInformation: 'ហាមមិនអោយកែសម្រួលព័ត៌មាន',
  prompt: 'ជំនួយ',
  provideInfo: 'ផ្ដល់ពត៍មាន',
  province: 'ខេត្ត',
  // ptlogo: require('../assets/images/dl/icon_ptlogo_e.png'),
  publicInventory: 'ជ្រើសរើសឃ្លាំងសាធារណៈ',
  purchase: 'ទិញ',
  PurchaseAmount: 'ចំនួនទិញ',
  purchasedQuantity: 'ចំនួនបញ្ជាទិញទិញ',
  purchaseEdit: 'កែសម្រួលបញ្ជីបញ្ជាទិញ',
  purchaseList: 'បញ្ជីបញ្ជាទិញ',
  purchaseMgr: 'ការគ្រប់គ្រងការទិញ',
  purchaseNo: 'លេខការបញ្ជាទិញ',
  purchaseorder: 'ការបញ្ជាទិញ',
  PurchaseOrderList: 'បញ្ជីបញ្ជាទិញ',
  purchaseOrderStatistics: 'ស្ថិតិនៃការបញ្ជាទិញ',
  purchaseQty: 'លេខកូដទិញ',
  Purchaser: 'អ្នកទិញ',
  purchaseReturns: 'បង្វិលប្ដូរការទិញ',
  QCOrder: 'QCហើយរួចរាល់',
  qcOrderList: 'រង់ចាំការបញ្ជាក់ពីហិរញ្ញវត្ថុ',
  qty: 'ចំនួន/ចំនួនទទួលបាន/ចំនួនត្រឡប់មកវិញ',
  qty1: 'ចំនួន',
  qty5: 'ចំនួន',
  qtyOrder: 'ចំនួន',
  qtyReturn: 'ចំនួនបង្វិលប្ដូរ',
  quanBuFeeLog: 'ទាំងអស់',
  totalQty: 'ចំនួនសរុប',
  Quantity: 'ចំនួន/ចំនួនទទួលបានទំនិញ/ចំនួនបង្វិលប្ដូរទំនិញ',
  quantity2: 'ចំនួនការទិញទំនិញច្រើនជាងចំនួនទំនិញដែលបានកុម្ម៉ង់ ចង់បន្តទៀតរឺទេ?',
  quantityCompleted: 'ចំនួនរូចរាល់',
  quantityRequested: 'សំណូមពរចំនួនបរិមាណ',
  quertAnswer: 'ឆ្លើយសំនួរមតិកែសម្រួល',
  quertClass: 'ប្រភេទមតិកែសម្រួល ',
  quertEdit: 'កែប្រែមតិកែសម្រួល',
  quertList: 'ជំនួយការកុម្ម៉ង់',
  R_DProducts: 'ផលិតផល R&D',
  RawMaterials: 'សមាសធាតុដើម',
  RDProducts: 'ទំនិញ R&D',
  readed: 'បានអានរូច',
  reason: 'មូលហេតុ',
  receipt: 'បង្កាន់ដៃ',
  Receipt2: 'ទទួលទំនិញ',
  ReceivableReceivedPayment: 'Receivable Payment',
  receivablesCompany: 'ក្រុមហ៊ុនទទួលប្រាក់',
  ReceivedPayment: 'ទទួលបានការបង់ប្រាក់',
  receiveOrder: 'ការផលិតបានសម្រេច',
  ReceivePayment: 'ទទួលការបង់ប្រាក់',
  ReceivingAccount: 'គណនីទទួលយក',
  receivingAddress: 'អាស័យដ្ធានទទួលទំនិញ',
  receiver: 'អ្នកទទួលទំនិញ',
  refund: 'លុបចោលការកុម្ម៉ង់',
  registrationNo: 'លេខសម្គាល់ចុះឈ្មោះអាជីកម្ម',
  reject: 'បដិសេធ',
  rejected: 'បដិសេធ',
  remarks: 'កំណត់សម្កាល់',
  remarks1: 'ព័ត៌មានលម្អិតនៃការបង្វិលត្រលប់',
  remarksEdit: 'កែសម្រួលកំណត់សម្គាល់',
  reply: 'តប',
  requestedReturnQty: 'ចំនួនសំណមពរបង្វិលប្ដូរ',
  required: 'ត្រូវបំពេញ',
  fieldRequired: "ចន្លោះ​នេះ​ត្រូវតែ​បំពេញ",
  reset: 'កំណត់ឡើងវិញ',
  resetPassword: 'ទាញយកលេខកូដសម្ងាត់មកវិញ',
  rest: 'សំរាក',
  returnRequest: 'សំណូមពរបង្វិលប្ដូរ',
  returnWarehouse: 'ឃ្លាំងបង្វិលប្ដូរទំនិញ',
  review: 'ត្រួតពិនិត្យ',
  preparing: 'កំពុងរៀបចំ',
  reviewPending: 'ត្រួតពិនិត្យរួច/រងចាំការបានសម្រច',
  rfid: 'RFID ប្រឆាំងការក្លែងបន្លំនិងតាមរកប្រភព',
  rider: 'អ្នកបើកបរ',
  RMB: 'លុយចិនដីគោក（RMB）',
  RoleControlPanel: 'កែសម្រួលតួនាទី',
  roles: 'មុខងារ',
  Roll: 'ជួរឈរ',
  row: 'ជួរ',
  saleMarket: 'លក់ក្នុងស្រុក',
  saleReturn: 'បង្វិលប្តូរការលក់',
  sales: 'លក់',
  salesDepramentCode: 'លេខសម្គាល់ផ្នែកលក់',
  Salesdiscount: 'ការលក់បញ្ចុះតម្លៃលុយចិន',
  salesEntity: 'អង្គភាពលក់',
  salesGroupCode: 'លេខសម្គាល់ក្រុមលក់',
  salesMain: 'អង្គភាពលក់',
  salesman: 'អ្នកលក់',
  salesName: 'ឈ្មោះអ្នកលក់',
  salesNo: 'លេខកូដសម្គាល់លក់',
  salesOrder: 'បញ្ជាលក់',
  salesperson: 'បុគ្គលិកផ្នែកលក់',
  salespersonCode: 'លេខសម្គាល់អ្នកលក់',
  SalesPhone: 'លេខទូរស័ព្ទអ្នកលក់',
  SalesQty: 'ចំនួនលក់',
  salesQty: 'លេខកូដលក់',
  salesReturn: 'បង្វិលទំនិញមកវិញ',
  salesSubject: 'អង្គភាពលក់',
  salesTel: 'លេខទូរស័ព្ទអ្នកលក់',
  SalesTotal: 'ចំនួនលក់សរុប',
  SalesTTL: 'ចំនួនលក់សរុប',
  sample: 'គំរូ',
  sampleList: 'បញ្ជីទំនិញគំរូ',
  SampleSheet: 'សន្លឹកគំរូ',
  save: 'រក្សាទុក',
  saveSuccess: 'រក្សាទុកបានសម្រច',
  scantling: 'ទំនិញស្តង់ដារ',
  scrap: 'កំទិចកំទី',
  search: 'សាកសួរ',
  searchOrder: 'សាកសួរ Order ID',
  searchPO: 'សាកសួរ PO ID',
  searchCrit: 'សូមវាយបញ្ចូលលក្ខខណ្ឌស្វែងរក',
  search1: 'ស្វែងរក',
  totalItem: 'ផលិតផលសរុប',
  searchContent: 'សូមវាយបញ្ចូលខ្លឹមសារសំណួរ',
  searchOption: 'លក្ខខណ្ឌស្វែងរក',
  searchPersonName: 'សូមវាយបញ្ចូលឈ្មោះបុគ្គលិកដែលចង់ស្វែង��ក',
  searchRequirement: 'សូមបញ្ចូលលក្ខខណ្ឌស្វែងរក',
  searchTip: 'សូមវាយបញ្ចូលខ្លឺមសារសំណួរ',
  select: 'ជ្រើសរើស',
  selectAccontManagment: 'ជ្រើសរើសការគ្រប់គ្រងអតិថិជន',
  selectDate: 'ជ្រើសរើសថ្ងៃខែ',
  selectExcelFormatFile: 'សូមជ្រើសរើសឯកសារដែលមានទម្រង់ជាExcelដើម្បីធ្វើការបញ្ជូនចូល',
  selectFile: 'សូមជ្រើសរើសឯកសារ',
  selectFileUpload: 'ជ្រើសរើសឯកសារដើម្បីបញ្ជូនឡើង',
  selectInsertLink: 'សូមជ្រើសរើសដៃគូដែលអ្នកចង់រួមតភ្ជាប់ជាមួយ',
  SelectOrder: 'ជ្រើសរើសបញ្ជាទិញ',
  selectSalesperson: 'សូមជ្រើសរើសអ្នកលក់',
  selectSupplier: 'ជ្រើសរើសអ្នកផ្គត់ផ្គង់',
  selectType: 'សូមជ្រើសរើសប្រភេទឯកសារ',
  sellingPrice: 'តម្លៃលក់',
  send: 'ដឹកជញ្ជូនទំនិញ',
  sendCode: 'ផ្ញើលេខកូដផ្ទៀងផ្ទាត់',
  sendEmail: 'ផ្ញើរសារអេឡិចត្រូនិច',
  sendWay: 'វិធីដឹកជញ្ជូន',
  serialNumber: 'លេខសម្គាល់',
  serverAddress: 'Server Address',
  set: 'ការកំណត់',
  setSelection: 'សូមកំណត់ការជ្រើសរើស',
  SetupFee: 'កម្រៃជើងសារ',
  sex: 'ភេទ',
  shagnjiabeizhu: 'សម្គាល់សំរាប់អ្នកជំនួញ',
  shenzhenlili: 'ក្រុមហ៊ុនលីលីតិចណូឡូជីសិនចិនខូអិលធីឌី',
  shipped: 'បានដឹកជញ្ជូនចេញ',
  Shipping: 'ថ្លៃដឹកជញ្ជូន',
  ShippingCompany: 'ក្រុមហ៊ុនដឹកជញ្ជូន',
  ShippingFee: 'ថ្លៃដឹកជញ្ជូន',
  ShippingRecordList: 'បញ្ជីកត់ត្រាការដឹកជញ្ជូន',
  shippingWarehouse: 'ឃ្លាំងដឹកជញ្ជូនទំនិញ',
  ShortName: 'ឈ្មោះហៅកាត់',
  shouhuoAddress: 'អាស័យដ្ធានទទួលទំនិញ',
  showFiels: 'ពិនិត្យមើលឯកសារ',
  shut: 'បិទ',
  // signIn: require('../assets/images/dl/icon_dl_e.png'),
  siteInfo: 'ព័ត៌មានអំពីគេហទំព័រ',
  size: 'ទំហំ',
  skAmount: 'លេខគណនីទទួលប្រាក់',
  skCompanyAddress: 'អាស័យដ្ធានក្រុមហ៊ុនទទួលប្រាក់',
  skContact: 'អ្នកទំនាក់ទំនងខាងទទួលប្រាក់',
  skip: 'រំលង',
  skPhone: 'លេខទូរស័ព្ទទំនាក់ទំនងខាងទទួលប្រាក់',
  software: 'Software',
  sort: 'តម្រៀបលំដាប់',
  Sorting: 'តម្រៀបលំដាប់',
  ssoftwareAndHardwareSystem: 'Software And Hardware System',
  sstc: 'ព័ត៌មានក្រុមហ៊ុន',
  standardProduct: 'ផលិតផលស្តង់ដារ',
  startDate: 'ថ្ងៃចាប់ផ្ដើម',
  startMonth: 'ខែចាប់ផ្ដើម',
  startStopTime: 'ម៉ោងចាប់ផ្ដើមនិងបញ្ចប់',
  stat: 'ស្ថិតិ',
  stateProvince: 'ប្រទេស',
  statistics: 'ស្ថិតិ',
  status: 'ស្ថានភាព',
  statusChart: 'ឆែកមើលតារាង',
  statView: 'ពិនិត្រមើលស្ថិតិ',
  stockAddress: 'អាស័យដ្ធានឃ្លាំង',
  stockEdit: 'កែសម្រួលឃ្លាំងទំនិញ',
  stockInOutRec: 'កំណត់ត្រាទំនិញចេញចូលឃ្លាំង',
  stockQty: 'នៅក្នុងស្តុក',
  storageTime: 'ពេលវេលាចេញចូលឃ្លាំង',
  sub: 'ដាក់ស្នើ',
  subDepartment: 'កូននៃនាយកដ្ឋាន',
  subitemMaterialCode: 'Subitem material code',
  Submit: 'ដាក់ស្នើ',
  subTime: 'ពេលវេលាដាក់ស្នើ',
  successful: 'បានសម្រេច',
  successfulImport: 'បញ្ជុនឡើងបានសម្រេច:',
  successfullyDeleted: 'ការលុបចោលបានសម្រេច',
  SuccessfulOperation: 'ប្រតិបត្តិការបានសម្រេច ត្រឡប់ក្រោយវិញទេ?',
  suoyou: 'ទាំងអស់',
  Supplier: 'អ្នកផ្គត់ផ្គង',
  supplier: 'អ្នកផ្គត់ផ្គង់',
  supplier_account: 'លេខគណនី',
  supplierAccessories: 'ឯកសារភ្ជាប់អ្នកផ្ទត់ផ្គង់',
  supplierAdd: 'បន្ថែម',
  supplierList: 'បញ្ជីអ្នកផ្គត់ផ្គង់',
  supplierRating: 'វាយតម្លៃអ្នកផ្គត់ផ្គង់',
  SureToDeleteThisPaymentSlip: 'ប្រាកដថាលុបប័ណ្ណបង់ប្រាក់បច្ចុប្បន្នចោល?',
  SwiftCode: 'Swift Code',
  sysCategory: 'ចំណាត់ថ្នាក់មុខងារ',
  sysFuncs: 'ការគ្រប់គ្រងមុខងារ',
  sysFuncsEdit: 'ការកែសម្រួលមុខងារ',
  sysList: 'បញ្ជីមុខងារ',
  sysMgr: 'ការគ្រប់គ្រងប្រព័ន្ធ',
  sysRoles: 'ការគ្រប់គ្រងតួនាទី',
  System5: 'System (including software and hardware)',
  systemSoftware: 'ប្រព័ន្ធ (រួមមាន software និង hardware)',
  systemSync: 'ប្រព័ន្ធដើរព្រមគ្នា',
  tax: 'អត្រាពន្ធ',
  taxIncludedQuotation: 'តម្លៃបានរួមបញ្ចូលពន្ធ',
  taxIncludedQuotations: 'តម្លៃឯកតាមានរួមបញ្ចូលពន្ធ',
  taxInfo: 'ទាក់ទងនឹងពន្ធ',
  taxNo: 'លេខសម្គាល់ពន្ធ',
  taxRate: 'អត្រាពន្ធ',
  tel: 'លេខទូរស័ព្ទ',
  templateClassification: 'ចំណាត់បែងចែកថ្នាក់ពុម្ពគំរូថ្នាក់ក្រុមគំរូ',
  templateCreateSuccess: 'ការបង្កើតពុម្ពគំរូបានជោគជ័យ លោតទៅបញ្ជីពុម្ពគំរូវិញរឺទេ?',
  templateParameter: 'Template Parameter',
  Territory: 'ក្នុងស្រុក',
  theExport: 'ការនាំចេញ',
  thickness: 'កម្រាស់',
  ThisArrival: 'ទំនិញមកដល់នេះ',
  threeEncod: 'លេខកូដសម្គាល់ទំនិញ',
  tianxieQtyReturn: 'សូមវាយបញ្ចូលតិចជាងចំនួនទំនិញដែលសុំបង្វិលប្ដូរ',
  time: 'ពេលវេលា',
  timeBecomeReqular: 'ថ្ងៃក្លាយជាបុគ្គលិកពេញសិទ្ធ',
  tip1: 'គណនីអតិថិជនទើបមានសិទិ្ធនេះ',
  priceTip2: 'ការកំណត់តម្លៃផ្សេងៗនៅលើទំព័រនេះនៅពេលចំនួននៃការបញ្ជាទិញច្រើនជាងចំនួនកំណត់់ វានឹងផ្ដល់ដំណឹងដល់ការត្រួយពិនិត្យ ដូចនេះត្រូវការការត្រួតពិនិត្យបញ្ជាទិញពីខាងគ្រប់គ្រង។',
  title: 'ចំណងជើង',
  to: 'ដល់',
  toBeAudited: 'រងចាំការត្រួតពិនិត្យ',
  toBeComplete: 'រងចាំការបញ្ចប់',
  toBeConfirm: 'រងចាំការបញ្ជាក់',
  toBeProduced: 'រងចាំការផលិត',
  tobeReceived: 'រងចាំទទួល',
  toOrder: 'រងចាំការដាក់បញ្ជាទិញ',
  total: 'សរុប',
  TotalAmount: 'ចំនួនសរុប',
  totalAmountOrder: 'សរុបតម្លៃបញ្ជាទិញ',
  totalAmout: 'តម្លៃសរុប',
  TotalCost: 'តម្លៃសរុប',
  totalFreight: 'ថ្ងៃដឹកជញ្ជូនសរុប',
  totalMoney: 'តម្លៃសរុប',
  totalNumber: 'ចំនួនសរុប',
  totalOrderAmount: 'ចំនួនបញ្ជាទិញសរុប',
  totalPrice1: 'តម្លៃសរុប',
  totalPrice2: 'ចំនួនទឹកប្រាក់បញ្ជាទិញ',
  toView: 'ឆែកមើល',
  toWork: 'ធ្វើការ',
  toWork1: 'ទៅធ្វើការ',
  traceability: 'តាមដាន',
  TradeCondition: 'លក្ខខណ្ឌពាណិជ្ជកម្ម',
  transactionTerms: 'លក្ខខណ្ឌពាណិជ្ជកម្ម',
  tripartiteCoding: 'លេខកូដ៣ជ្រុង',
  TTLOrders: 'ចំនួនបញ្ជាទិញសរុប',
  ttlPrice: 'តម្លៃសរុប',
  twitter: 'Twitter',
  type: 'ប្រភេទ',
  Types: 'ប្រភេទ',
  unfloatcargo: 'ទំនិញមកដល់ហើយ',
  uniqueCode: 'លេខកូដពិសេស',
  unit: 'មុខ',
  singleUnit: 'ឯកតារាយ',
  messureUnit: 'ឯកតារង្វាស់',
  unitMeasurement: 'ឯកតាវាស់វែង',
  unitPrice: 'តម្លៃរាយ',
  UnitPriceIncludesTax: 'តម្លៃឯកតារួមបញ្ចូលពន្ធ',
  Unpaid: 'រងចាំការបង់ប្រាក់',
  Unpaid1: 'មិនទាន់ទូទាត់ប្រាក់',
  unread: 'មិនទាន់អាន',
  unreadMeasage: 'សារមិនទាន់បានអាន',
  Unshipped: 'មិនទាន់ដឹកជញ្ជូន',
  untreated: 'មិនទាន់ដោះស្រាយ',
  up1: 'បាននាំចូលដោយជោគជ័យ',
  upload: 'កំពុងបញ្ចូល uuid',
  uploader: 'អ្នកបញ្ជូន',
  uploadFiles: 'បញ្ជូនឯកសារ',
  uploadImgSize: 'រូបដែលបញ្ជូនឡើងបានតែនៅក្នុងទម្រង់JPG、pngតែប៉ុណ្ណោះ!ទំហំរូបដែលបញ្ជូនឡើងមិនអាចធំហួស500KBទេ!',
  uploadLogoInfo: 'រូបដែលបញ្ជូនឡើងបានតែនៅក្នុងទម្រង់JPG、pngតែប៉ុណ្ណោះ!ទំហំរូបដែលបញ្ជូនឡើងមិនអាចធំហួស100KBទេ!',
  uploadLogoSize: 'ទំហំរូបដែលបញ្ជូនឡើងមិនអាចធំហួស100KBទេ!',
  uploadPicturesErrInfo: 'រូបដែលបញ្ជូនឡើងបានតែនៅក្នុងទម្រង់JPG、pngតែប៉ុណ្ណោះ!',
  uploadPicturesSize: 'រូបដែលបានបញ្ជូនឡើងមិនអាចមានទំហំធំជាងរឺតូចជាងចំនួនកំណត់ទេ។',
  uploadSuccess: 'បញ្ជូនបានសម្រេច',
  uploadUuidsErrInfo: 'រូបដែលបញ្ជូនឡើងបានតែនៅក្នុងទម្រង់txt, xlsxតែប៉ុណ្ណោះ!',
  usd: 'ដុល្លារ',
  user: 'ផ្នែកបម្រើអតិថិជន',
  userList: 'ព័ត៌មានអ្នកប្រើប្រាស់',
  userName: 'ឈ្មោះ',
  usersEdit: 'កែប្រែព័ត៌មានអ្នកប្រើប្រាស់',
  uuidsInfo: 'ខាងក្រោមមានដូចគ្នា២ដង សូមបញ្ជាក់',
  vat: 'Vat No.',
  view: 'ពិនិត្យមើល',
  waitForDelivery: 'រងចាំការដឹកជញ្ជូន',
  waitingForCustomer: 'រងចាំការបញ្ជាក់ពីអតិថិជន',
  warehouseContact: 'លេខអ្នកទំនាក់ទំនងឃ្លាំងទំនិញ',
  warehouseGoods: 'ទំនិញក្នុងឃ្លាំង',
  warehouseId: 'លេខឃ្លាំងទំនិញ',
  warehouseInfo: 'ព័ត៌មានឃ្លាំងទំនិញ',
  warehouseList: 'បញ្ជីឃ្លាំង',
  warehouseLog: 'កំណត់ត្រាចេញចូលឃ្លាំង',
  warehouseMgr: 'ការគ្រប់គ្រងឃ្លាំង',
  warehouseName: 'ឈ្មោះឃ្លាំងទំនិញ',
  warehousePhone: 'លេខទូរស័ព្ទឃ្លាំងទំនិញ',
  warehousing: 'ចូលឃ្លាំង',
  WaybillNumber: 'លេខសម្គាល់Waybill',
  website: 'ទំព័រអ៊ីនធើណេត',
  wechat: 'WeChat',
  welcome: 'សូមស្វាគមន៍',
  whetherCloseCase: 'គួររឺមិនគួរបញ្ចប់សំណុំរឿង',
  whetherRestoreCustomer: 'គួររឺមិនគួរតបទៅអតិថិជនវិញ',
  willDeleteRecord: 'ប្រតិបត្តិការនេះនឹងលុបចោលកំណត់ត្រាបច្ចុប្បន្ន បន្តរឺទេ?',
  withdraw: 'ការដកប្រាក់',
  withdrawal: 'ដក',
  woman: 'ស្ត្រី',
  wuliaohao: 'លេខទំនិញ',
  xiangqing: 'ព័ត៌មានលម្អិត',
  xianjingshouzhi: 'ការបង់និងទទួលសាច់ប្រាក់',
  internalCustomer: 'អតិថិជនផ្ទៃក្នុង',
  yes: 'បាទ',
  yesterday: 'ម្សិលមិញ',
  yieldOrderList: 'រងចាំផលិត /បញ្ជាទិញ',
  yinghangcunqu: 'ការដាក់ប្រាក់និងដកប្រាក់',
  youfei: 'តម្លៃផ្ញើរ',
  youNotChooseMain: 'អ្នកមិនទាន់ជ្រើសរើសអង្គភាពលក់',
  youNotTemplate: 'អ្នកមិនទាន់បានបញ្ជូនពុម្ពគំរូ',
  yuan: 'យ័ន',
  yujjlxrgx: 'ទំនាក់ទំនងរវាងអ្នកនិងអ្នកទាក់ទងពេលមានអាសន្ន',
  zhifufangshi: 'ពត៌មានទូទាត់ប្រាក់',
  zipCode: 'លេខកូដប្រៃសណីយ៍',
  ziti: 'មកយកខ្លួនឯង',
  err404: "404",
  err404title: "អូហូ! អ្នកវង្វេងផ្លូវហើយ",
  err404msg: "ទំព័រដែលអ្នកកំពុងស្វែងរកមិនមាននោះទេ។ មូលហេតុដែលនាំអ្នកមកដល់ទីនេះគឺជាអាថ៌កំបាំង។ តែអ្នកអាចចុចលើប៊ូតុងខាងក្រោមដើម្បីត្រលប់ទៅទំព័រដើមវិញ",
  uploadImgOnly: "សូមបញ្ចូលតែរូបភាពប៉ុណ្ណោះ",
  
}
