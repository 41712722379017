import Vue from 'vue'
import router from './router'
import store from './store'
import qs from 'qs'

router.beforeEach((to, from, next) => {
    if (qs.stringify(store.getters.siteInfo) == '') {
      store.dispatch('SetInfo').then(res => {
        document.title = res.title
        localStorage.setItem("color", res.color);
      })
    }
    if(store.getters.token){
      if (qs.stringify(store.getters.userInfo) == '') {
        store.dispatch('GetUserInfo').then(info => {
          const pi = info.permission_info
          store.dispatch('GenerateRoutes',  pi ).then(routes => {
            routes.forEach(r => router.addRoute(r))
            if(to.path == '/login'){
              next({ path: '/', replace: true })
            }else{
              next({ path: to.fullPath })
            }
          })
        })
      }else if(to.path == '/login'){
        next({ path: '/', replace: true })
      }else{
        next()
      }
    }else if(to.path != '/login'){
      next({ path: '/login', replace: true })
    }else{
      next()
    }
 
  })

Vue.mixin({
  data() {
    return {
      functionName: ''
    }
  },
  mounted() {
    if (this.$route !== undefined && this.$route.meta !== undefined) {
      this.functionName = this.$route.meta.funcs
    }
  },
  methods: {
    permissionCheck(...param) {
      const action = param[0]
      const fName = param.length === 2 ? param[1] : this.functionName
      const permissionInfo = store.getters.permissionInfo
      if (permissionInfo.is_root || permissionInfo.is_admin) {
        return true
      }
      if (permissionInfo.functions === undefined) {
        return false
      }
      const fd = permissionInfo.functions[fName]
      if (fd === undefined) {
        return false
      }
      if (fd[action] === true) {
        return true
      } else {
        return false
      }
    },
    colPermissCheck(...param) {
      const col = param[0]
      const fName = param.length === 2 ? param[1] : this.functionName
      const permissionInfo = store.getters.permissionInfo
      if (permissionInfo.is_root || permissionInfo.is_admin) {
        return true
      }
      if (permissionInfo.functions === undefined) {
        return false
      }
      const fd = permissionInfo.functions[fName]
      if (fd === undefined) {
        return false
      }
      if (fd.column_fields && Array.isArray(fd.column_fields) && fd.column_fields.includes(col)) {
        return false
      }
      return true
    }
  }
})
  
