const order = 
{
    name: 'Order',
    path: '/order',
    component: () => import('@/views/dashboard/Index'),
    meta: { title: 'orderMgr', icon: 'mdi-cart-arrow-up', funcs: ['order', 'orderCheck', 'order_complaint', 'orderReceive', 'orderQC', 'orderSendCheck', 'orderSend', 'order_place', 'order_modify', 'order_send', 'customer_finance'] },
    children: [
        {
            name: 'Order List',
            path: 'lists/-100',
            component: () => import('@/views/dashboard/pages/orders/orderList'),
            meta: { title: 'orderList', icon: 'list', funcs: 'order' }
          },
          {
            name: 'Shipping Record',
            path: 'shipping',
            component: () => import('@/views/dashboard/pages/orders/shippingRecord'),
            meta: { title: 'deliveryDiscipline', icon: 'list', funcs: 'order_send' }
          },
          {
            name: 'Complaint Record',
            path: 'complaint',
            component: () => import('@/views/dashboard/pages/orders/complaintRecord'),
            meta: { title: 'complaintRecord', icon: 'list', funcs: 'order_complaint' }
          },
          {
            name: 'check',
            path: 'check',
            hidden: true,
            component: () => import('@/views/dashboard/pages/orders/check'),
            meta: { title: 'check', icon: 'list', funcs: 'order_modify' }
          },
          {
            name: 'cancelOrderList',
            path: 'list/-1',
            hidden: true,
            component: () => import('@/views/dashboard/pages/orders/orderList'),
            meta: { title: 'cancelOrderList', icon: 'list', funcs: 'cancelOrderList' }
          },
          {
            name: 'orderPlace',
            path: 'list/0',
            hidden: true,
            component: () => import('@/views/dashboard/pages/orders/orderList'),
            meta: { title: 'orderPlace', icon: 'list', funcs: 'order_place' }
          },
          {
            name: 'orderCheck',
            path: 'list/1',
            hidden: true,
            component: () => import('@/views/dashboard/pages/orders/orderList'),
            meta: { title: 'orderCheck', icon: 'list', funcs: 'orderCheck' }
          },
          // {
          //   name: 'addOrder',
          //   path: 'add',
          //   component: () => import('@/views/dashboard/pages/orders/addOrder'),
          //   meta: { title: 'addOrder', icon: 'list' },
          //   hidden: true
          // },
          {
            name: 'customerOrder',
            path: 'customerOrder',
            component: () => import('@/views/dashboard/pages/orders/customerOrder'),
            meta: { title: 'addOrder', icon: 'list' },
            hidden: true
          },
          {
            name: 'customerFinance',
            path: 'finance',
            component: () => import('@/views/dashboard/pages/orders/customerFinance'),
            meta: { title: 'customerFinance', icon: 'list', funcs: 'customer_finance' },
            hidden: true
          }
    ]
}

export default order