import Vue from 'vue'
import Router from 'vue-router'
import account from './account'
import basic from './basicdata'
import company from './company'
import customers from './customers'
import financial from './financial'
import mail from './mail'
import order from './order'
import products from './products'
import purchase from './purchase'
import system from './system'
import print from './print'

Vue.use(Router)

export const routeLists = [company, products, customers, order, purchase, financial, system]

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      name: 'Login',
      path: '/login',
      component: () => import('@/views/login/index'),
    },
    ...print,
    // ...routeLists,
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/Dashboard'),
        },
      ],
    },
    {
      path: '*',
      component: () => import('@/views/404')
    }
  ],
})
