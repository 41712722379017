const purchase =
{
  name: 'Purchase',
  path: '/purchase',
  component: () => import('@/views/dashboard/Index'),
  meta: { title: 'purchaseMgr', icon: 'mdi-shopping-outline', funcs: ['purchase_order', 'warehouse', 'picking', 'factory', 'supplier', 'supplier_account'] },
  children: [
    {
      name: 'Product List',
      path: 'list',
      component: () => import('@/views/dashboard/pages/product/productList'),
      meta: { title: 'goodsMgr', icon: 'classify', funcs: 'listClassify' }
    },
    {
      name: 'Supplier Management',
      path: 'supplier',
      component: () => import('@/views/dashboard/pages/purchases/supplierMg'),
      meta: { title: 'supplier', icon: 'warehouse', funcs: 'supplier' }
    },
    {
      name: 'Purchase Order',
      path: 'order',
      component: () => import('@/views/dashboard/pages/purchases/purchaseOrder'),
      meta: { title: 'purchaseList', icon: 'warehouse', funcs: 'purchase_order' }
    },
    {
      name: 'Stock-In',
      path: 'warehouse',
      component: () => import('@/views/dashboard/pages/purchases/stockIn'),
      meta: { title: 'warehouseMgr', icon: 'warehouse', funcs: 'warehouse' }
    },
    {
      name: 'Stock-Out',
      path: 'picking/:id',
      component: () => import('@/views/dashboard/pages/purchases/stockOut'),
      meta: { title: 'picking', icon: 'warehouse', funcs: 'picking' }
    },
    {
      name: 'warehouseGoods',
      path: 'goods/:id',
      component: () => import('@/views/dashboard/pages/purchases/goods'),
      meta: { title: 'warehouseGoods', icon: 'warehouse', funcs: 'warehouse' },
      hidden: true
    },
    {
      name: 'warehouseLog',
      path: 'log/:id',
      component: () => import('@/views/dashboard/pages/purchases/log'),
      meta: { title: 'warehouseLog', icon: 'warehouse', funcs: 'warehouse' },
      hidden: true
    },
    // {
    //   name: 'purchaseEdit',
    //   path: 'purchaseEdit',
    //   hidden: true,
    //   component: () => import('@/views/dashboard/pages/purchases/purchaseEditNew'),
    //   meta: { title: 'purchaseEdit', icon: 'warehouse', funcs: 'purchase_order' }
    // },
    {
      name: 'pickEdit',
      path: 'pickEdit',
      hidden: true,
      component: () => import('@/views/dashboard/pages/purchases/pickEditNew'),
      meta: { title: 'pickEdit', icon: 'warehouse', funcs: 'warehouse' }
    },
    {
      name: 'factoryList',
      path: 'factory',
      component: () => import('@/views/dashboard/pages/purchases/factory'),
      meta: { title: 'factoryMgr', icon: 'warehouse', funcs: 'factory' },
      hidden: true
    },
    {
      name: 'supplierAccount',
      path: 'supplierAccount',
      hidden: true,
      component: () => import('@/views/dashboard/pages/purchases/supplierUser'),
      meta: { title: 'supplier_account', icon: 'warehouse', funcs: 'supplier_account' }
    },
    {
      name: 'supplierPay',
      path: 'supplierPay',
      hidden: true,
      component: () => import('@/views/dashboard/pages/purchases/supplierPay'),
      meta: { title: 'paymentOrder', icon: 'warehouse', funcs: 'supplier_pay' }
    }
  ]
}

export default purchase