const products =
{
    name: 'Products',
    path: '/products',
    component: () => import('@/views/dashboard/Index'),
    meta: { title: 'goodsMgr', icon: 'mdi-briefcase-variant', funcs: ['listClassify', 'bom', 'chip', 'craft', 'paramTemplate', 'code_types', 'product', 'materials', 'packing', 'artwork'] },
    children: [
        // {
        //     name: 'Product List',
        //     path: 'list',
        //     component: () => import('@/views/dashboard/pages/product/productList'),
        //     meta: { title: 'goodsListCategory', icon: 'classify', funcs: 'listClassify' },
        //     hidden: true
        // },
        //move to purchase management
        {
            name: 'bom',
            path: 'bom',
            component: () => import('@/views/dashboard/pages/product/bom'),
            meta: { title: 'bom', icon: 'classify', funcs: 'bom' },
            hidden: true
        },
        {
            name: 'Product Type',
            path: 'type',
            component: () => import('@/views/dashboard/pages/product/productType'),
            // meta: { title: 'warehouseGoods', icon: 'warehouse', funcs: 'warehouse' },
            hidden: true
        },
        // {
        //   name: 'product',
        //   path: 'product',
        //   component: () => import('@/views/basicdata/product'),
        //   meta: { title: 'product', icon: 'list', funcs: 'product' }
        // },
        {
            name: 'chip',
            path: 'chip',
            component: () => import('@/views/dashboard/pages/basicdata/chip'),
            meta: { title: 'chip', icon: 'list', funcs: 'chip' },
            hidden: true
        },
        {
            name: 'materials',
            path: 'materials',
            component: () => import('@/views/dashboard/pages/basicdata/materials'),
            meta: { title: 'materials', icon: 'list', funcs: 'materials' },
            hidden: true
        },
        {
            name: 'packing',
            path: 'packing',
            component: () => import('@/views/dashboard/pages/basicdata/packing'),
            meta: { title: 'packing', icon: 'list', funcs: 'packing' },
            hidden: true
        },
        {
            name: 'artwork',
            path: 'artwork',
            component: () => import('@/views/dashboard/pages/basicdata/artwork'),
            meta: { title: 'artwork', icon: 'list', funcs: 'artwork' },
            hidden: true
        },
        {
            name: 'craft',
            path: 'craft',
            component: () => import('@/views/dashboard/pages/basicdata/craft'),
            meta: { title: 'craft', icon: 'classify', funcs: 'craft' },
            hidden: true
        },
        {
            name: 'goodsParamTemplate',
            path: 'paramTemplate',
            component: () => import('@/views/dashboard/pages/product/paramTemplate'),
            meta: { title: 'goodsParamTemplate', icon: 'list', funcs: 'paramTemplate' },
            hidden: true
        }
    ]
}

export default products