import { en } from 'vuetify/es5/locale'

export default {
  ...en,

  back2Product: "Back to Product",
  abolish: 'Cancel',
  Abroad: 'Abroad',
  absenteeism: 'Absenteeism',
  account: 'Account',
  arrAccount: 'Arrived account',
  accountName: 'Account Name',
  AccountPeriod: 'Payment Term',
  accountUse: 'Account Use',
  achieve: 'Complete',
  achieveOrderList: 'Pending QC',
  actionState: 'Operating',
  actionStateTip: 'Please select the status of the pick list',
  activateNow: 'Activate Now',
  active: 'active',
  actualArrival: 'Actual Arrival Time',
  ActualQTY: 'Actual Quantity',
  actualQuantity: 'Actual Quantity',
  ActualTotalPriceArrived: 'Actual Total Price Arrived',
  add: 'Add',
  Add: 'Add',
  add5: 'Add Products',
  AddANewReceipt: 'Add A New Receipt',
  AddEmails: 'Add new Emails',
  addGoods: 'Add products',
  additems: 'Add Items',
  additems1: 'Add',
  addNode: 'Add Node',
  addOrder: 'order add',
  addPeople: 'Add User',
  addr: 'Address',
  address: 'Address',
  address1: 'Company\'s address',
  address3: 'Branch',
  addSuccess: 'Successfully added',
  addSuccessInfo: 'Successfully added, continue adding?',
  addTime: 'Add Time',
  administrator: 'Administrator',
  advertising: 'Advertising',
  all: 'All',
  allDay: 'All Day',
  AlreadyApplied: 'Already Applied',
  Amount: 'Amount',
  amountApplied: 'Amount applied',
  amountReceived: 'Amount received',
  annex: 'Attachment',
  antistop: 'key word',
  aog: 'Goods arrival',
  appendixUpload: 'Upload attachment',
  ApplDate: 'Application time',
  applicant: 'Applicant',
  application: 'Application',
  applicationReturn: 'Application for return',
  applicationTime: 'Application Time',
  applyReject: 'Application rejected',
  applyUser: 'Applicant',
  applyDate: "Apply Date",
  approve: 'Approve',
  Approved: 'Approved',
  approver: 'Approver',
  ArrivalTime: 'Arrival Time',
  ArrivalWarehouse: 'Arrived Warehouse',
  arrived: 'arrived',
  article: 'Article',
  artifactType: 'product classification',
  artwork: 'Artwork',
  artworkNo: 'Artwork No.',
  associate: 'Associates',
  associatedContract: 'Associated Contract',
  associatedContractNum: 'Associated Contract No.',
  attach: 'Attachment',
  attachment: 'Attachment',
  attendanceRecord: 'Attendance Record',
  audit: 'Review',
  auditOrder: 'Review the order',
  authorization: 'Authorization',
  avatar: 'Avatar',
  AWeekAgo: 'A Week Ago',
  back: 'Back',
  BackGround: 'Back Ground picture',
  background: 'Back Ground',
  bank: 'Bank account',
  bankAccNo: 'Bank Account Number',
  bankAccount: 'Bank Account',
  BankAccountEditing: 'Bank Account Editing',
  bankAcctNo: 'Bank Account number',
  bankCountry: 'Bank Country',
  bankInfo: 'Bank Information',
  bankAcc: 'Bank Account',
  bankName: 'Bank Name',
  bankName1: 'Bank Name',
  bankName2: 'Bank Name',
  bankNo: 'Account number',
  bankStatements: 'Bank Statements',
  bankSwiftCode: 'Bank Swift Code',
  basicData: 'Basic Data',
  BasicDataMgr: 'ID library',
  basicInfo: 'Basic Information',
  basicInfo1: 'Basic Info',
  basicInformation: 'Basic Information',
  batchExport: 'Batch Export',
  batchUpload: 'Batch Upload',
  batchUpload1: 'Batch Upload',
  backgroundcolor: 'Background Color',
  becomeReqularEmplovee: 'Salary on becoming regular employee',
  begin: 'Initiate',
  beginProduct: 'Begin production',
  BeneficiaryBank: 'Beneficiary Bank',
  birthday: 'Birthday',
  bom: 'bom',
  fontcolor: 'Font Color',
  Bottles: 'Bottles',
  bulkImport: 'Bulk Import',
  bulkUpload: 'Bulk Upload',
  businessId: 'Business ID',
  businessType: 'Business Type',
  calendarView: 'Calendar view',
  cancel: 'Cancel',
  canceled: 'Canceled',
  canceledDelete: 'Undeleted',
  canceler: 'Canceler',
  cancellationTime: 'Cancellation time',
  cancelOrder: 'cancel Order',
  cancelOrderList: 'canceled',
  cancelReason: 'Cancellation Reason',
  cancelReturnRequest: 'Cancel return request',
  canping: 'Product',
  CargoCategory: 'Cargo Category',
  carton_dimension: 'Carton dimension',
  carton_num: 'Packing quantity',
  cash: 'Cash',
  categories: 'Picking list type',
  categories1: 'Supplier Classification',
  Categories2: 'Question Category',
  categoriesCommodities: 'Commodities Categories',
  category: ' Category',
  Category6: 'Category',
  categoryClass: 'Category',
  categoryListAll: 'All',
  orderInfo: "Order Info",
  // centerPtlogo: require('../assets/images/sy/icon_ptlogo_e.png'),
  cetegory: 'business type',
  ChangeTime: 'Change Time',
  changeLang: 'Change Language',
  check: 'Check',
  orderModReview: 'Order modification review',
  checkFile: 'Check File',
  checkMonth: 'Reconciliation month',
  checkUuid: 'Detect',
  chinese: 'Chinese',
  chineseName: 'Chinese Name',
  chipLib: 'chip library',
  havechip: 'Have Chip',
  chip: 'Chip',
  chooseCategories: 'Please select a type',
  chooseDaorukucfl: 'Please select the category of the imported inventory',
  chooseDate: 'Choose Date',
  chooseFile: 'Choose File',
  chooseOneSalesEntity: 'Please select at least one sales entity',
  chooseShipingWarehouse: 'Please select shipping warehouse',
  chooseType: 'Choose Type',
  churukuProductQty: 'Quantity of goods in and out of the warehouse',
  city: 'City',
  classification: 'Category',
  classify: 'classify',
  clickNodeList: 'Click on the node in the node list first!!!',
  clickSelectGoods: 'Please click to select the product',
  clickUpload: 'Click to Upload',
  client: 'Customer',
  editClient: 'Edit customer',
  customerAcc: 'Customer Account',
  clientConfirm: 'Client Confirmation',
  clientman: 'Customer Management',
  closed: 'Closed',
  cm: 'Cm',
  code: 'Code',
  codeClassification: 'coding classification',
  codeTip1: 'The verification code has been sent, please check carefully',
  codeTip2: 'Please enter the correct phone number',
  codeTip3: 'Account activated successfully',
  codeTip4: 'Account password changed successfully. Please log in again.',
  codeTip5: 'Change Password',
  codeTip6: 'This account needs to be activated, is it activated?',
  codeTip7: 'All Rights Reserved',
  coding: 'coding',
  Colum: 'Colum',
  comAddress: 'Company Address',
  comment: 'comment',
  commentDetail: 'Order comment details',
  commentInfo: 'Communication record',
  company: 'Company',
  companyMgm: 'Company management',
  companyAddress: 'Company Address',
  companyChineseName: 'Company name in Chinese',
  companycontact: 'Company Contact Person',
  companyContactNumber: 'Company Contact Number',
  companyEmail: 'Company Email',
  companyFax: 'Company Fax',
  companyInfo: 'Company Information',
  companyName: 'Company Name',
  companyNameShort: 'Short Name',
  companyName1: 'Company Name in English',
  companyName2: 'Company Name',
  companyName3: 'Company Name',
  complain: 'Complain',
  complaintDate: 'Date of complaint',
  complaintGoods: 'Complaint goods',
  ComplaintList: 'Complaint List',
  complaintNumber: 'Number of complaints',
  complaintRecord: 'Complaint Record',
  Complaints: 'Complaints',
  complete: 'Complete',
  Completed1: 'Completed (the entire order is completed and delivered)',
  completeQC: 'Complete QC',
  confirm: 'Confirm',
  Confirm: 'save',
  confirm3: 'Confirm',
  Confirm5: 'Confirm',
  confirmAdd: 'Confirm Add',
  confirmAudit: 'Confirm review',
  confirmCancelPickList: 'Confirm to cancel the picking list?',
  confirmChange: 'Confirm Change',
  confirmCom: 'Confirm Completion',
  confirmDelCaiGou: 'Confirm to delete the current purchasing entity?',
  confirmDelCurrentRole: 'Confirm to delete the current role?',
  confirmDelCurrentSale: 'Confirm to delete the current sales entity?',
  confirmDelCurrentSuppler: 'Confirm to delete the current supplier',
  ConfirmDelete: 'Confirm to delete the current bank account information?',
  confirmDelivery: 'Confirm Delivery',
  confirmedReturn: 'Confirmed Return',
  confirmModify: 'Confirm modification',
  confirmOperation: 'Is this operation confirmed or not?',
  confirmOrder: 'Confirm the order',
  confirmPass: 'Confirm password',
  confirmReceiptRefund: 'Confirm Receipt And Refund',
  confirmReview: 'Confirmed/pending reviewed',
  ConfirmToDeleteThisAccountPeriod: 'Confirm To Delete This Account Period',
  confirmWithdrawal: 'Confirm Withdrawal',
  confrimDelGoods: 'Confirm to delete the product?',
  conifr: 'Modify is not allowed after confirmation',
  consignor: 'Sender',
  contactDetails: 'Contact Details',
  contactEmail: 'Contact Email',
  contactInfo: 'Contact person information',
  contactIphone: 'Phone Number',
  contactPerson: 'Contact Person',
  contactPersonPhone: 'Contact Number',
  contactPhone: ' Contact phone number',
  content: 'Description',
  contractInformation: 'Contract Information',
  Controller: 'Principal',
  Controllerlist: 'Personnel selection',
  copy: 'Copy',
  copyOrder: 'Copy order',
  copyFee: 'Copy Fee',
  copyTemp: 'Copy template',
  CorrespondingPurchaseOrder: 'Corresponding Purchase Order',
  cost: 'Cost accounting',
  costCategories: 'Expense classification',
  costList: 'Expense List',
  country: 'Continenet',
  Country: 'Country',
  craft: 'Craft Management',
  createCategory: 'Create Category',
  CreateCategory: 'Create Category',
  createCategory5: 'Create Template Categories',
  CreateDepartment: 'Create Department',
  CreatePurchaseOrder: 'Confirm order',
  createStock: 'create Stock',
  createTime: 'Creation time',
  CreateType: 'Create Type',
  creationTime: 'Creation Time',
  creditCard: 'Credit Card',
  Cup: 'Cup',
  currency: 'Currency',
  currency1: 'Settlement currency',
  CurrencySymbol: 'Currency Symbol',
  currentLocation: 'Current Location',
  currentQty: 'Current Qty/Received/Return',
  customer: 'Customer',
  customer_account: 'Customer account',
  customerAccessories: 'Customer Attachment',
  CustomerCreatPendConfirm: 'Customer creation/pending confirmation',
  customerEdit: 'Customer Edit',
  customerFinance: 'Payment Record',
  customerID: 'ID',
  CustomerID2: 'Customer ID',
  customerList: 'Customer List',
  CustomerList: 'Customer List',
  customerMgr: 'Customer List',
  customerName: 'Customer Name',
  customerordernumber: 'Customer PO No.',
  customerPurchaseOrder: 'Purchase order contract number',
  customerQTY: 'Customer quantity',
  customerType: 'Customer Type',
  cutomerChoose: 'Please select customer',
  cutomerInfo: 'Customer Info',
  cutomerName: 'Cutomer Name',
  daichengtuan: 'To be grouped',
  dashboard: 'Dashboard',
  data: 'Data',
  date: 'Date',
  dateBirth: 'Date of Birth',
  dateOfDelivery: 'Delivery date',
  dateOfReceipt: 'Date of refund received',
  dateReceipt: 'Date of collection',
  DateType1: 'Date Type',
  DateTypes: 'Date Types',
  day: 'Day',
  defective: 'Defective',
  degrees: 'Degrees',
  delCurrentFeeLogTip: 'Confirm to delete the current expense record ?',
  delCurrentGoodsTip: 'Confirm to delete the current product?',
  delCurrentGoodsTip2: 'Confirm to delete the current category?',
  delCurrentOrderPayTip: 'Confirm to delete the current purchase order',
  delCurrentPersonInfo: 'Are you sure you want to delete this record?',
  delDepartmentTip: 'Confirm to delete the current department?',
  delete: 'Delete',
  Delete: 'Delete',
  deleteAD: 'Delete the current AD or not?',
  deleteAdminInfo: 'Delete current administrator information or not?',
  deleteArticle: 'Whether to delete the current article?',
  deleteCurrenNodeInfo: 'Delete current node information?',
  deleteCurrentForm: 'Delete the current form?',
  deleteCurrentTemplate: 'Delete the current template?',
  deleteGoods: 'Do you want to delete the current product information?',
  deleteNode: 'Determine to delete the current node?',
  deleteNode1: 'Delete Node',
  deleteNodeData: 'Determine to delete current node data?',
  done: "Done",
  deleteSuccess: 'Delete Successfully',
  deleteWarehouse: 'Delete current warehouse information or not?',
  delFinanceLogTip: 'Confirm to delete the current financial log',
  deliery_addr: 'Deliery Address',
  Delivered: 'Delivered',
  deliveryCompleted: 'Delivery (completed)',
  deliveryCompOrderList: 'completed',
  deliveryDate: 'Delivery Date',
  arrivalDate: 'Arrival Date',
  deliveryDiscipline: 'Shipping Record',
  deliveryMethod: 'Delivery Method',
  deliveryOfGoods: 'Deliver goods',
  deliveryOrderList: 'to be delivered',
  deliveryRecord: 'Delivery Record',
  delOrderComplaintTip: 'Are you sure to delete the current complaint record?',
  delOrderTip: 'This operation will delete the current order, whether to continue?',
  department: 'Department',
  departmenInfo: 'Department information',
  departmentList: 'All',
  departmentsList: 'Departments List',
  departmentHead: 'Head of Department',
  departName: 'Department Name',
  deposit: 'Deposit',
  des: 'Problem description',
  deselect: 'Cancel selection',
  detail: 'Detail',
  determineDeleteRecord: 'Are you sure you want to remove current delivery record?',
  determinedPerson: 'Determined Person',
  determineTime: 'Determine the time',
  district: 'District',
  Dollar: 'United States Dollar (USD)',
  domesticFreight: 'Domestic shipping fees',
  DomestiSales: 'Domestic Sales',
  dosage: 'dosage',
  download: 'download',
  dragFileHere: 'Drag the file here, or',
  dryclfl: 'Please select the classification of imported raw materials',
  duties: 'Duties',
  edit: 'Edit',
  editNode: 'Edit Node',
  editNodeData: 'Edit node data within the template',
  editorialStaff: 'Editorial staff',
  EditPanel: 'Edit Panel',
  editStock: 'Edit picklist',
  eidtSuccess: 'Edit successfully',
  eidtUserInfo: 'Edit personal information',
  personalEmail: 'Personal Email',
  email: 'Email',
  emergencyPerson: 'Emergency Contact Person',
  emergencyPhone: 'Emergency Contact Phone number',
  employeeId: 'Employee ID',
  enclosure: 'Attachments',
  endDate: 'End Date',
  endMonth: 'End Month',
  english: 'English',
  enterAccount: 'Please enter the phone number',
  enterAddress: 'Please enter the address',
  enterBankAcc: 'Please enter a bank account',
  enterbankCountry: 'Please Enter Bank Country',
  enterBankName: 'Please enter the account opening bank',
  enterbankSwiftCode: 'Please Enter Bank Swift Code',
  enterCategoryCode: 'Please select category number',
  enterComplete: 'Please Enter Complete',
  enterContact: 'Please enter a contact person',
  enterCorrectEmailAddress: 'Please input the correct email address',
  enterCorrectPhoneNumber: 'Please enter the correct phone number',
  enterEmail: 'please enter your email',
  entereProduct: 'Please enter the product',
  enterExcelExport: 'Please select a file in Excel format to import',
  enterFactoryName: 'Please enter the factory name',
  enterFax: 'Please enter a fax',
  enterGoodsChineseName: 'Please enter the Chinese name of the product',
  enterGoodsClassName: 'Please enter the name of the product category',
  enterGoodsCode: 'Please enter the product number',
  enterGoodsEnglishName: 'Please enter the English name of the product',
  enterGoodsPrice: 'Please enter the commodity price',
  enterInfo: 'Entry Information',
  personalDocx: 'Personal Document',
  enterName: 'Please enter the name',
  enterNameSearch: 'Please enter name search',
  enterNewPass: 'Please enter a new password',
  enterOldPass: 'Please enter the original password',
  enterPassWord: 'Please enter password',
  enterPayPerson: 'Please enter the recipient',
  enterPhone: 'Please enter your phone number',
  enterPhone1: 'Please enter the phone',
  enterPoGoodsDrafetTip: 'Please enter the inquiry po/commodity/artwork number, etc',
  enterPrincipal: 'Please enter the person in charge',
  enterQueryCriteria: 'Please enter the query criteria',
  enterSearchContent: 'Please enter query content',
  enterSearchOrder: 'Please enter the search order number',
  enterStockId: 'Please enter the warehouse number',
  enterStockName: 'Please enter the warehouse name',
  enterSupplierClassName: 'Please enter the name of the supplier category',
  entersupplierRating: 'Please Enter Supplier Rating',
  enterTime: 'Enter Time',
  enterValidPhoneNumber: 'please enter a valid phone number',
  enterYouMessage: 'Enter your message here',
  error: 'Error',
  errorCode: 'Error code:',
  examinationPassed: 'Review passed',
  exchangeRate: 'Exchange Rate',
  existsAgain: 'Already exists, please select again',
  expenditure: 'Expenditure',
  expenseLog: 'Expense log',
  expense: "Expense",
  expireDate: 'Expire Date',
  export: 'Export',
  Export2: 'Export',
  exportKingdeeTemplete: 'Export the kingdee template',
  exportPdf: 'Export PDF',
  extendedInfo: 'Extended Info',
  facebook: "Facebook",
  FactoryAccessories: 'Factory attachment',
  factoryAppendixUpload: 'Factory Attachment Upload',
  factoryCopyFee: 'Factory Copy Fee',
  factoryEdit: 'Factory Edit',
  factoryList: 'Factory List',
  factoryMgr: 'Factory Management',
  factoryPrice: 'Factory Price',
  factoryUnitPrice: 'Factory Unit Price',
  fax: 'FAX',
  feedbackDetail: 'Feedback detail',
  feeLog: 'Expense Log',
  fenLei: 'Categories',
  fieldService: 'Field Service',
  fileHasBeenUpload: 'The file has been uploaded',
  fileList: 'File list',
  filename: 'filename',
  filesUpload: 'Files Upload',
  FinanceController: 'Finance Controller',
  financeLog: 'Financial Log',
  financeSend: 'Financial confirms',
  financialMgr: 'Financial Management',
  findPassword: 'Retrieve Password',
  finishedDate: 'Finished Date',
  finishedProductType: 'Finished Product Type',
  fkAmount: 'Payer Bank Account',
  fkComAddress: 'Paying Company Address',
  fkComtact: 'Payer Contact',
  fkPhone: 'Contact phone number for payment',
  floatcargo: 'Not arrived ',
  forgetPassword: 'Forget the password',
  freeSample: 'Free Sample',
  freight: 'Delivery fees',
  Function: 'Function selection',
  FunctionCode: 'Function Number',
  FunctionList: 'Function List',
  Fundamentaldata: 'Basic data list',
  gathering: 'Receipt',
  Gathering: 'Gathering',
  generalPurchase: 'General Purchase',
  GiveAway: 'Give Away',
  Glass: 'Glass',
  goods: 'Goods',
  goodsAdd: 'Add product',
  goodsCategory: 'Product Category',
  goodsCode: 'Product No.',
  goodsDesc: 'description of goods',
  goodsEdit: 'Edit Product',
  goodsInfo: 'Product Information',
  goodsList: 'Product List',
  goodsListCategory: 'Product',
  goodsMgr: 'Product Management',
  goodsName: 'Product Name',
  goodsParamTemplate: 'Param Template',
  goodsShelves: 'Goods Shelves',
  goodsTotal: 'Total Products Qty',
  goodsTotalPrice: 'Total products Amount',
  goodUnit: 'Commodity measurement unit',
  gross_weight: 'Gross Weight',
  guanlixitong: 'Management System',
  HalfDomestic: 'Half Domestic',
  halfDomesticSales: 'Half Domestic Sales',
  handlingMethod: 'Handling method',
  hardware: 'Hardware',
  hardwareSoftwareSystem: 'Hardware and software system',
  hasMakeOutAnIncoice: 'invoiced',
  havePayed: '',
  hierarchy: 'Level',
  history: 'History',
  holiday: 'Holiday',
  home: 'Home page',
  huodaofukuan: 'Cash on Delivery',
  ID: 'ID',
  idLibrary: 'ID library',
  IDNo: 'National ID',
  idPass: 'ID/Passport',
  image: 'Image',
  imageSizeTip: 'exceeding the file size limit',
  imgUploadTip1: 'Drag the files here, or',
  imgUploadTip2: 'Click to upload',
  imgUploadTip3: 'Only JPG / PNG files can be uploaded, and no more than',
  important: 'Important',
  importExportDury: 'Formal export declaration',
  importSuccess: 'Imported successfully',
  importUUID: 'import UID',
  ImproveLogisticsInformation: 'Improve Logistics Information',
  inch: 'Inch',
  incoiceTitle: 'Incoice Title',
  income: 'Income',
  incomeExpenditureType: 'Type of income and expenditure',
  inOut: 'Warehouse information',
  inportSuccess: 'Import success',
  internalSupplier: 'Internal Supplier',
  internalSupplier1: 'Internal customer',
  intragroupCode: 'Intragroup Code',
  inventory: 'Inventory',
  invertedNumber: 'Inverted Number',
  invoice: 'Create invoice',
  invoiceInfo: 'Billing Information',
  invoiceMoban: 'Invoice Template',
  invoiceNo: 'Invoice No.',
  invoiceNumber: 'invoice Number',
  invoiceTitle: 'Invoice opening',
  isChip: 'Isss Chip',
  isHasOpen: 'Authorize',
  itemNo: 'Item No',
  ItemsTTLQty: 'Items Total Quantity',
  jihuo: 'Activation',
  jihuoAmount: 'Activate the account',
  jizhuPassword: 'Remember the password',
  keyword: 'Key words',
  KG: 'Kg',
  kh: 'Riel',
  kuaidi: 'Local Delivery',
  kuaidifangshi: 'Courier',
  lastQuestion: 'Last Question',
  lastReply: 'Last Reply',
  lastStep: 'Last Step',
  lastUpateTime: 'Last update',
  late: 'Late',
  layer: 'Layer',
  leaveEarly: 'Leave Early',
  level: 'Priority',
  linkSet: 'Link Set',
  linkType: 'Please Select Link Type',
  list: 'Function list',
  loading: 'Loading',
  login: 'Login',
  loginFailed: 'Login failed! Incorrect username or password',
  incorrectPassowrd: 'Incorrect password',
  loginIp: 'Login IP',
  loginTime: 'Login Time',
  logout: 'Log out',
  logo: 'Logo',
  lookMore: 'See More',
  maijiabeizhu: 'Buyer notes',
  mail: 'Email List',
  mailAdderss: 'EMail Adderss',
  mailInfo: 'Mail details',
  makeSureCurentRole: 'Confirm deleting current role',
  makeSureRemoveFile: 'Are you sure you want to remove the file?',
  man: 'Male',
  manufacturingTechnique: 'Production Process',
  materialName: 'name of material',
  materials: 'materials',
  merchantBackstage: 'Merchant Backstage Management Center',
  merchantSystem: 'Merchant Management System',
  mingxi: 'Detail',
  missingCard: 'Missing Card',
  mobile: 'Phone number',
  modify: 'Modify',
  modifyOrder: 'Confirm order',
  ModifyPanel: 'Department Editor',
  modifySave: 'Please modify and then save it',
  modifyStatus: 'Modify status',
  modifySuccess: 'Modify successfully',
  modifySuccessInfo: 'Change is successful, return back to the list page?',
  month: 'Month',
  months: 'Months',
  name: 'Name',
  newCategory: "New Category",
  categoryName: "Category Name",
  namePhoneContact: 'Please enter name/phone/contact',
  nationality: 'Nationality',
  needInvoice: 'Pending invoiced',
  needPay: 'Paid',
  net_weight: 'Net Weight',
  new: 'New',
  NewCurrency: 'Add New Currency',
  Newdata: 'Add',
  newDetails: 'Order Details',
  NewFunction: 'Add',
  newOrder: 'New Order',
  newOrderAdd: 'Add order',
  newPassword: 'New password',
  NewRole: 'Add New Role',
  news: 'news',
  newSupplierInfo: 'Edit information',
  nextStep: 'Next Step',
  no: 'No',
  num: 'No.',
  no2: 'None',
  noChip: 'No Chip',
  nodeNoDate: 'The current node has no data',
  non_standardProducts: 'Non-standard Products',
  noPai: 'Item Number',
  noPreviewTemplate: 'Preview template is currently unavailable, please fill in the preview template',
  general: 'General',
  normal: 'Normal',
  noScantling: 'Non-standard products',
  note: 'Remark',
  Nowadays: 'Nowadays',
  number: 'Quantity',
  NumberOfFactories: 'Number Of Factories',
  NumberOfGifts: 'Number Of Gifts',
  NumberOfSuppliers: 'Number Of Suppliers',
  nweCustomer: 'Add',
  offStocks: 'off the stocks',
  oldPassword: 'Old password',
  onlinePay: 'Online Payment',
  onlyExcelFile: 'Only upload excel files',
  OperatTime: 'Operate Time',
  opt: 'Operating',
  optTime: 'Operation time',
  optUser: 'Operator',
  order: 'Order',
  customerSetting: 'Choose customer settings',
  orderAmount: 'order amount',
  orderCheck: 'Pending review',
  orderChoose: 'Order selection',
  orderComment: 'Complaint',
  orderCommentDetail: 'Order Complaint Detail',
  orderConfirm: 'Order confirmation',
  orderGoods: 'Order goods：',
  orderDetails: 'order details',
  orderList: 'Order List',
  orderMgr: 'Order Management',
  orderNo: 'Order No.',
  orderNoSearchTip: 'Please enter the associated sales order number',
  orderNumber: 'Order qualtity',
  orderPlace: 'pending order',
  orderPo: 'Order No.',
  orderPoSearch: 'Please enter search criteria',
  orderPrice: 'Order Price',
  orderQty: 'Order Quantity',
  orderReview: 'Order Review',
  Orders: 'Orders',
  orderSale: 'Sales Representative:',
  orderStatistics: 'Order Statistics',
  orderDate: 'Order date',
  orderTime: 'Order Time',
  orderTotalPrice: 'Order total',
  orderyield: 'order yield',
  other: 'Other',
  otherContact: 'Other Contacts',
  otherInformation: 'Other Information',
  outerChain: 'Outer Chain',
  OutOfStock: 'Out of stock',
  Outsourcing: 'Outsourcing purchase',
  Outsourcingss: 'Outsourcing',
  outsourcingType: 'Outsourcing Type',
  goodsType: "Product Type",
  outStock: 'Out',
  overseasFreight: 'Overseas shipping fees',
  overtime: 'Over Time',
  packing: 'Packaging',
  paid: 'Paid',
  paymentReceived: 'Payment received',
  paidAmount: 'Paid/order amount',
  parameter: 'Parameter',
  ParameterTemplateClassification: 'Parameter Template Classification',
  parameterTemplateList: 'Parameter Template List',
  parentCategory: 'Parent Category',
  particulars: 'Particulars',
  passport: 'Passport',
  passportName: 'Passport Name',
  passResetSuccess: 'Password reset successfully',
  password: 'Password',
  passwordCannotLess: 'The password cannot be less than 6 digits',
  passwordNotmatch: 'The new password and confirm password do not match',
  pay: 'Pay',
  payed: 'Received payment',
  payable: 'Receivable Payment',
  paying: 'Payment Record',
  payingEdit: 'paying edit',
  PaymenSerialNumber: 'Payment Serial Number',
  payment: 'payer',
  payment2: 'Payment',
  paymentAccount: 'Receiving Accounts',
  paymentAmount: 'Payment Amount',
  paymentCompany: 'Payment Company',
  paymentContactPerson: 'Payment Contact Person',
  paymentDate: 'Payment Date',
  paymentDays: 'Payment Term Management',
  paymentHasBeenReceived: 'payment received',
  payMentInfo: 'Payment Info',
  PaymentInformation: 'Payment Information',
  paymentLimit: 'Payment Limit',
  PaymentOrder: 'Payment Order',
  paymentOrder: 'Receipt',
  paymentRecords: 'Collection Record',
  PaymentSlip: 'Payment Slip',
  paymentSuccessful: 'Payment Successful',
  paymentTerm: 'Payment Term(Payment advance/Pay on delivery / 7 / 15 / 30 / 45 / 60)',
  paymentterm: 'Payment Term',
  PaymentTerm1: 'Payment Term',
  PaymentTermMgr: 'Add',
  PaymentTime: 'Payment Time',
  Pcs: 'Pcs',
  PendingOpenInvoice: 'Pending Invoice',
  pendingOrder: 'Pending order',
  pendingPicklist: 'Pending picking list',
  pendingPurchase: 'Pending purchase order',
  permitNo: 'Permit Number ',
  permitSearchTip: 'Please enter the query picking order number',
  personalInformation: 'Personal Information',
  personalizedProcess: 'Personalized process',
  personList: 'Personnel list',
  phone: 'Phone number',
  phone2: 'Phone number2',
  phoneCannotEmpty: 'Phone number cannot be empty',
  phoneNotNull: 'Phone number cannot be empty',
  pick: 'Picklist',
  pickEdit: 'Picking list edit',
  picking: 'Stock Out',
  pickingListReview: 'Picking list review',
  pickNew: 'New pick list',
  placeOrder: 'Place the order',
  plaeasEnterTaxRate: 'Please enter the tax rate.',
  plannedArrival: 'Est Arv Time',
  plannedArrivalR1: ' Est Arv Time 1',
  plannedArrivalR2: ' Est Arv Time 2',
  pleaseAddGoods: 'Please add products',
  pleaseAddGoodsNumber: 'Please add product quantity',
  pleaseAddNumber: 'Please add quantity',
  pleaseAddProductFirst: 'Please add products first',
  PleaseAddProductQuantity: 'Please Add Product Quantity',
  PleaseAddPurchaser: 'Please Add Purchaser',
  PleaseAddTaxRate: 'Please Add Tax Rate',
  pleaseAddTypeFeeLog: 'Please add expense classification first',
  PleaseBankAccounts: 'Please Enter Bank Accounts',
  pleaseCangKu: 'Please select a warehouse',
  pleaseChoose: 'Please Choose',
  pleaseChooseGoods: 'Please select product',
  pleaseChoosePoNo: 'Please select the tracking number',
  pleaseChooseRoles: 'Please Choose a Roles',
  PleaseCompleteTheInformation: 'Please Complete The Information',
  PleaseCompleteTheTrackingNumber: 'Please Complete The Tracking Number',
  pleaseEditPassSame: 'Please enter the same as the modified password',
  PleaseEnterBankAccountNumber: 'Please Enter Bank Account Number',
  pleaseEnterCancelReason: 'Please enter the reason for cancellation',
  pleaseEnterFenleiName: 'Please enter the name of the product category',
  pleaseEnterFormName: 'Please enter the form name!',
  pleaseEnterGoodsNumber: 'Please enter goods qualtity',
  pleaseEnterName: 'Please enter a name',
  pleaseEnterNewPass: 'Please enter a new password',
  pleaseEnterProblemDesc: 'Please enter a problem description',
  pleaseEnterProcessMethod: 'Please enter the processing method',
  pleaseEnterPurchaseNo: 'Please enter purchase order number',
  pleaseEntersaleList: 'Please enter the associated sales slip',
  pleaseEnterStockName: 'Please enter Stock Name',
  pleaseEnterThanZero: 'Please enter a number greater than or equal to zero',
  PleaseEnterTheAccountName: 'Please Enter The Account Name',
  PleaseEnterTheBankName: 'Please Enter The Bank Name',
  pleaseFillActualQuantity: 'Please fill in the actual quantity',
  pleaseFillData: 'Please fill in the data',
  PleaseSelectASupplier: 'Please Select A Supplier',
  PleaseSelectCurrency: 'Please Select Currency',
  pleaseSelectGoods: 'Please select a product',
  PleaseSelectTheArrivalWarehouse: 'Please Select The Arrival Warehouse',
  PleaseSelectTheBillingPeriod: 'Please Select The Billing Period',
  pleaseSelectTheOrder: 'Please select the order',
  pleaseSetNewPass: 'Please set a new password',
  poMoban: 'PO Template',
  poNo: 'Purchase order number.',
  contactPersonPosition: 'Contact person position ',
  position: 'Position',
  postalCode: 'Postal Code',
  preview: 'Preview',
  price: 'Price',
  totalPrice: 'Total cost',
  PriceChange: 'Commodities Price change must not be larger than',
  priceControl: 'Price Control',
  priceTip: 'Please enter the price correctly, keep 2 decimal places',
  principal: 'Principal',
  print: 'Print',
  Printing: 'Printing',
  printTip: 'Please use google or 360 browser to print',
  probationSalary: 'Probation Salary',
  problemDesc: 'Problem Description',
  process: 'Process',
  processingTime: 'Processing time',
  procuring: 'procurement subject',
  produce: 'produce',
  product: 'Product',
  commodity: 'Commodity',
  producing: 'Producing',
  productCategory: 'Product Category',
  productCategoryTip: 'Please select the type of picking list',
  productChoose: 'Product Choose',
  ProductClass: 'Product Categories',
  productDecription: 'Product Decription',
  productDescription: 'Product Description',
  productDescription1: 'Product name',
  productEdit: 'Product Edit',
  productId: 'Product ID',
  productionFactory: 'Production Factory',
  productionOrderNo: 'Production Order Number',
  productionProcess: 'Production Process',
  productList: 'Warehouse products',
  productList1: 'Product',
  productName: 'Product Name',
  productPicture: 'Product Image',
  addProductImage: "Add Product Image",
  productPrice: 'Product Price',
  Products: 'Products',
  products: 'Products',
  productsName: 'Product Name',
  productUnit: 'Product Unit',
  profit: 'Profit',
  ProhibitEditingInformation: 'Editing information is prohibited',
  prompt: 'Prompt',
  provideInfo: 'Provide Information',
  province: 'Province',
  // ptlogo: require('../assets/images/dl/icon_ptlogo_e.png'),
  publicInventory: 'Select Public Inventory',
  purchase: 'purchase',
  PurchaseAmount: 'Purchase Amount',
  purchasedQuantity: 'Purchased quantity',
  purchaseEdit: 'Purchase order edit',
  purchaseList: 'Purchase Order list',
  purchaseMgr: 'Purchase Management',
  purchaseNo: 'Purchase Order No.',
  purchaseorder: 'Purchase Order',
  PurchaseOrderList: 'Purchase Order List',
  purchaseOrderStatistics: 'Purchase Order Statistics',
  purchaseQty: 'Purchase No',
  Purchaser: 'Purchaser',
  purchaseReturns: 'Purchase returns',
  QCOrder: 'finish QC',
  qcOrderList: 'Pending financial confirmation',
  qty: 'Quantity/Receipt Quantity/Return Quantity',
  qty1: 'Quantity',
  qty5: 'Quantity',
  qtyOrder: 'quantity',
  qtyReturn: 'returned quanity',
  quanBuFeeLog: 'All',
  totalQty: 'Total Quantity',
  Quantity: 'Quantity/Receipt quantity/Return/Return Quantity',
  quantity2: 'The purchase quantity of the product is greater than the order quantity of the product, do you wish to continue',
  quantityCompleted: 'Completed Quantity ',
  quantityRequested: 'Number of claims',
  quertAnswer: 'Answer feedback',
  quertClass: 'Feedback classification',
  quertEdit: 'Feedback edit',
  quertList: 'Order support',
  R_DProducts: 'R&D products',
  RawMaterials: 'Raw Materials',
  RDProducts: 'R&D products',
  readed: 'Read',
  reason: 'Reason',
  receipt: 'Receipt',
  Receipt2: 'Receipt',
  ReceivableReceivedPayment: 'Collect on behalf of',
  receivablesCompany: 'Receivables Company',
  ReceivedPayment: 'Payment received',
  receiveOrder: 'Production complete',
  ReceivePayment: 'Receive Payment',
  ReceivingAccount: 'Receiving Account',
  receivingAddress: 'Receiving Address',
  receiver: 'Receiver',
  refund: 'cancel order',
  registrationNo: 'Business Registration Number',
  reject: 'reject',
  rejected: 'Rejected',
  remarks: 'Remark',
  remarks1: 'Return details',
  remarksEdit: 'Remarks Edit',
  reply: 'Reply',
  requestedReturnQty: 'requested Return Qty',
  required: 'Required',
  fieldRequired: "this field is required",
  reset: 'reset',
  resetPassword: 'Reset Password',
  rest: 'Rest',
  returnRequest: 'Return Request',
  returnWarehouse: 'Return warehouse',
  review: 'Review',
  preparing: 'In preparation',
  reviewPending: 'Reviewed/pending completion',
  rfid: 'RFID Anti-counterfeiting And Traceability',
  rider: 'Rider',
  RMB: 'RMB（RMB）',
  RoleControlPanel: 'Role Control Panel',
  roles: 'Roles',
  Roll: 'Roll',
  row: 'Row',
  saleMarket: 'Domestic sale',
  saleReturn: 'sales return',
  sales: 'Sales',
  salesDepramentCode: 'Sales department code',
  Salesdiscount: 'Sales Discount RMB',
  salesEntity: 'Sales Entity',
  salesGroupCode: 'Group sales code',
  salesMain: 'Sales entity',
  salesman: 'Salesman',
  salesName: 'Sales Name',
  salesNo: 'Sales No.',
  salesOrder: 'Sales Order',
  salesperson: 'Sales Person',
  salespersonCode: 'Salesperson Code',
  SalesPhone: 'Sales Phone number',
  salesQty: 'Sales NO.',
  SalesQty: 'Sales quantity',
  salesReturn: 'sales return',
  salesSubject: 'Sales Entity',
  salesTel: 'Sales Telephone number',
  SalesTotal: 'Sales Total',
  SalesTTL: 'Sale total',
  sample: 'Sample',
  sampleList: 'Sample List',
  SampleSheet: 'Sample Sheet',
  save: 'Save',
  saveSuccess: 'Saved successfully',
  scantling: 'Standard product',
  scrap: 'Scrap',
  search: 'Search',
  searchOrder: 'Search Order ID',
  searchPO: 'Search PO ID',
  searchCrit: 'Please enter search criteria',
  search1: 'search',
  searchContent: 'Please enter query content',
  searchOption: 'Query conditions',
  searchPersonName: 'Please enter the searched person name',
  searchRequirement: 'Please enter search criteria',
  searchTip: 'Please enter query content',
  select: 'Select',
  selectAccontManagment: 'Select account management',
  selectDate: 'Select a date',
  selectExcelFormatFile: 'Please select Excel format for file import',
  selectFile: 'Select File',
  selectFileUpload: 'Select File to Upload',
  selectInsertLink: 'Please select the object you want to insert in the link',
  SelectOrder: 'Select Order',
  selecttype: "Select Type",
  selectSalesperson: 'Please select a salesperson',
  selectSupplier: 'Select Suppliers',
  selectType: 'Please select the document type',
  sellingPrice: 'Selling Price',
  send: 'logistics delivery',
  sendCode: 'Send the verification code',
  sendEmail: 'Send Email',
  sendWay: 'Delivery method',
  serialNumber: 'serial Number',
  serverAddress: 'Server Address',
  set: 'Setting',
  setSelection: 'Please set option',
  SetupFee: 'Setup Fee',
  sex: 'Sex',
  shagnjiabeizhu: 'Merchant notes',
  shenzhenlili: 'Shenzhen Lili Technology Co. LTD',
  shipped: 'Shipped',
  Shipping: 'Shipping',
  ShippingCompany: 'Shipping Company',
  ShippingFee: 'Shipping Fee',
  ShippingRecordList: 'Shipping Record List',
  shippingWarehouse: 'Shipping Warehouse',
  ShortName: 'Abbreviation',
  shouhuoAddress: 'Shipping address',
  showFiels: 'Checking file',
  shut: 'close',
  // signIn: require('../assets/images/dl/icon_dl_e.png'),
  siteInfo: 'Site information',
  size: 'Size',
  skAmount: 'Beneficiary Bank Account',
  skCompanyAddress: 'Receiving Company Address',
  skContact: 'Payee Contact',
  skip: 'skip',
  skPhone: 'Collection and release phone number',
  software: 'Software',
  sort: 'Sort',
  Sorting: 'Sorting',
  ssoftwareAndHardwareSystem: 'Software And Hardware System',
  sstc: 'Company Info',
  standardProduct: 'Standard Product',
  startDate: 'Start Date',
  startMonth: 'Start Month',
  startStopTime: 'Start-stop time',
  stat: 'Statistics',
  stateProvince: 'Country',
  statistics: 'Statistics',
  status: 'Status',
  statusChart: 'View chart',
  statView: 'Statistics view',
  stockAddress: 'Warehouse Address',
  stockEdit: 'Stock Edit',
  stockInOutRec: 'Stock In/Out Record',
  stockQty: 'Stock Quantity',
  storageTime: 'Storage Time',
  sub: 'Submit',
  subDepartment: 'Sub Department',
  subitemMaterialCode: 'Subitem material code',
  Submit: 'Submit',
  subTime: 'Submit time',
  successful: 'Successful',
  successfulImport: 'Import successfully:',
  successfullyDeleted: 'successfully Deleted',
  SuccessfulOperation: 'Successful Operation, return back?',
  suoyou: 'Everything',
  supplier: 'Supplier Management',
  Supplier: 'Supplier',
  supplier_account: 'Account number',
  supplierAccessories: 'Supplier attachment',
  supplierAdd: 'Add',
  customerAccount: 'Customer Account',
  supplierList: 'Supplier List',
  supplierRating: 'Supplier Rating',
  SureToDeleteThisPaymentSlip: 'Sure To Delete This Payment Slip',
  SwiftCode: 'Swift Code',
  sysCategory: 'Functional Classification',
  sysFuncs: 'Permission Management',
  sysFuncsEdit: 'Functional Edit',
  sysList: 'Functional List',
  sysMgr: 'System management',
  sysRoles: 'Role Management',
  System5: 'System (including software and hardware)',
  systemSoftware: 'System (including software and hardware)',
  systemSync: 'System synchronization',
  tax: 'Tax rate',
  taxIncludedQuotation: 'Tax Included In Quotation',
  taxIncludedQuotations: 'Unit price includes tax',
  taxInfo: 'TAX Information',
  taxNo: 'Tax Number',
  taxRate: 'Tax Rate',
  tel: 'Telephone',
  templateClassification: 'Template Classification',
  templateCreateSuccess: 'Template creation success whether to jump back to the template list?',
  templateParameter: 'Template Parameter',
  Territory: 'Territory',
  theExport: 'Export',
  thickness: 'Thickness',
  ThisArrival: 'This Arrival',
  threeEncod: 'order encoding',
  tianxieQtyReturn: 'Please fill in less than the number of products that have been returned',
  time: 'Time',
  timeBecomeReqular: 'Become Regular employee',
  timeBecomeRegular: 'Time Become Regular Employee',
  tip1: 'Only customer accounts have this permission',
  priceTip2: 'All price setting on the setting page, when the order quantity ratio exceeds these setting, the review will be triggered and the order will needs to be review by the administrator.',
  title: 'Title',
  to: 'To',
  FrieghtView: 'No setting template',
  toBeAudited: 'Pending Review',
  toBeComplete: 'Pending Completion',
  toBeConfirm: 'Pending Confirmation',
  toBeProduced: 'Pending Production',
  tobeReceived: 'To be received',
  toOrder: 'Pending Order',
  total: 'Total',
  totalItem: 'Total Products',
  TotalAmount: 'Total Amount',
  totalAmountOrder: 'total amount of the order',
  totalAmout: 'Total Amount',
  TotalCost: 'Total Cost',
  totalFreight: 'Total shipping fees',
  totalMoney: 'Total amount',
  totalNumber: 'Quantity',
  totalOrderAmount: 'Total Order Amount',
  totalPrice1: 'Total price',
  totalPrice2: 'order amount',
  toView: 'View',
  toWork: 'Work',
  toWork1: 'To Work',
  traceability: 'Trace',
  TradeCondition: 'Trade Condition',
  transactionTerms: 'Transaction Terms',
  tripartiteCoding: 'Tripartite Coding',
  TTLOrders: 'Order quantity',
  ttlPrice: 'Total Price',
  twitter: 'Twitter',
  type: 'Types',
  Types: 'Types',
  unfloatcargo: 'Have arrived',
  uniqueCode: 'Unique Code',
  messureUnit: 'unit of measurement',
  unit: 'unit',
  singleUnit: 'Single unit',
  unitMeasurement: 'unit of measurement',
  unitPrice: 'Unit price',
  UnitPriceIncludesTax: 'Unit Price Includes Tax',
  Unpaid: 'Waiting for payment',
  Unpaid1: 'Unpaid',
  unread: 'Unread',
  unreadMeasage: 'Unread Measage',
  Unshipped: 'Unshipped',
  untreated: 'Untreated',
  up1: 'Uploading successful',
  upload: 'Uploading uuid',
  uploader: 'Uploader',
  uploadFiles: 'Upload Files',
  uploadImgSize: 'Picture upload can only be in JPG, PNG format! Upload image size cannot exceed 500KB!',
  uploadLogoInfo: 'Picture upload can only be JPG, PNG format! Upload image size cannot exceed 100KB!',
  uploadLogoSize: 'Upload Logo size can not exceed 100KB!',
  uploadPicturesErrInfo: 'Upload pictures can only be in JPG, PNG format!',
  uploadPicturesSize: 'Upload image size cannot be exceed',
  uploadSuccess: 'Uploaded successfully',
  uploadUuidsErrInfo: 'Upload pictures can only be in txt, xlsx format!',
  usd: 'Dollar',
  user: 'Customer service',
  userList: 'Staffs information',
  userName: 'Name',
  usersEdit: 'Users Edit',
  uuidsInfo: 'There are duplicates below, please confirm',
  vat: 'Vat Number',
  view: 'View',
  waitForDelivery: 'waiting for delivery',
  waitingForCustomer: 'Waiting for customers confirmation',
  warehouseContact: 'Warehouse contact person',
  warehouseGoods: 'Warehouse products',
  warehouseId: 'Warehouse ID',
  warehouseInfo: 'Warehouse information',
  warehouseList: 'Warehouse list',
  warehouseLog: 'Warehouse access log',
  warehouseMgr: 'Warehouse management',
  warehouseName: 'Warehouse Name',
  warehousePhone: 'Warehouse phone number',
  warehousing: 'In',
  WaybillNumber: 'Waybill Number',
  website: 'Website',
  wechat: 'WeChat',
  welcome: 'Welcome',
  whetherCloseCase: 'Whether to close the case',
  whetherRestoreCustomer: 'Whether to reply to the customer',
  willDeleteRecord: 'This operation will delete the current record, continue?',
  withdraw: 'Withdraw',
  withdrawal: 'Withdrawal',
  woman: 'Female',
  wuliaohao: 'Item number',
  xiangqing: 'Details',
  xianjingshouzhi: 'Cash In/ Cash Out',
  internalCustomer: 'internal customer',
  yes: 'Yes',
  yesterday: 'Yesterday',
  yieldOrderList: 'To be produced/purchased',
  yinghangcunqu: 'Deposit/Withdraw',
  youfei: 'Postage',
  youNotChooseMain: 'You did not choose sale entity',
  youNotTemplate: 'You did not upload the template',
  yuan: 'yuan',
  yujjlxrgx: 'Relationship with emergency contacts',
  zhifufangshi: 'Payment Method',
  zipCode: 'Zip Code',
  ziti: 'Self Pick Up',
  err404: "404",
  err404title: "UH OH! You're lost",
  err404msg: "The page you are looking for does not exist. How you got here is a mystery. But you can click the button below to go back to the homepage.",
  uploadImgOnly: "Please Upload Image Only",
}
