import request from '@/utils/request'

// 功能分类管理
export function functionTypesList() {
  return request({
    url: '/function_types/list',
    method: 'get'
  })
}
// 分类添加
export function functionTypesUpsert(data) {
  return request({
    url: '/function_types/upsert',
    method: 'post',
    data: data
  })
}

// 删除分类
export function functionTypesDel(uid) {
  return request({
    url: '/function_types/delete/' + uid,
    method: 'delete'
  })
}

// 功能
export function functionList(data) {
  return request({
    url: '/function/list',
    method: 'post',
    data: data
  })
}
// 添加功能
export function functionUpsert(data) {
  return request({
    url: '/function/upsert',
    method: 'post',
    data: data
  })
}

// 删除功能
export function functionDel(id) {
  return request({
    url: '/function/delete/' + id,
    method: 'delete'
  })
}

// 功能详情
export function functionInfo(id) {
  return request({
    url: '/function/info/' + id,
    method: 'get'
  })
}

// 添加角色
export function roleUpsert(data) {
  return request({
    url: '/role/upsert',
    method: 'post',
    data: data
  })
}

// 角色列表
export function roleList(data) {
  return request({
    url: '/role/list',
    method: 'post',
    data: data
  })
}

// 删除角色
export function roleDel(id) {
  return request({
    url: '/role/delete/' + id,
    method: 'delete'
  })
}

// 角色详情
export function roleInfo(id) {
  return request({
    url: '/role/info/' + id,
    method: 'get'
  })
}

export function upsertCurrency(data) {
  return request({
    url: '/currency/upsert',
    method: 'post',
    data: data
  })
}

export function listCurrency(data) {
  return request({
    url: '/currency/list',
    method: 'post',
    data: data
  })
}
export function delCurrency(id) {
  return request({
    url: '/currency/delete/' + id,
    method: 'delete'
  })
}

export function infoCurrency(id) {
  return request({
    url: '/currency/info/' + id,
    method: 'get'
  })
}

export function upsertPriceControl(data) {
  return request({
    url: '/price_control/upsert',
    method: 'post',
    data: data
  })
}

export function infoPriceControl(data) {
  return request({
    url: '/price_control/info',
    method: 'get'
  })
}

export function getTableDefine(table) {
  return request({
    url: '/users/table-define/' + table,
    method: 'get'
  })
}

export function upsertTableDefine(data) {
  return request({
    url: '/users/table-define',
    method: 'post',
    data: data
  })
}

export function upsertSiteSetting(data) {
  return request({
    url: '/site_setting/upsert',
    method: 'post',
    data: data
  })
}

export function getSiteSetting(data) {
  return request({
    url: '/site_setting/info',
    method: 'get',
    data: data
  })
}

// 基础数据详情
export function baseDataInfo(id) {
  return request({
    url: '/base_data/info/' + id,
    method: 'get'
  })
}

// 添加基础数据
export function baseDataUpsert(data) {
  return request({
    url: '/base_data/upsert',
    method: 'post',
    data: data
  })
}

// 基础数据列表
export function baseDataList(data) {
  return request({
    url: '/base_data/list',
    method: 'post',
    data: data
  })
}

// 删除基础数据
export function baseDataDel(id) {
  return request({
    url: '/base_data/delete/' + id,
    method: 'delete'
  })
}

// 数据类型添加
export function dataTypesUpsert(data) {
  return request({
    url: '/data_types/upsert',
    method: 'post',
    data: data
  })
}

// 数据类型列表
export function dataTypesList() {
  return request({
    url: '/data_types/list',
    method: 'get'
  })
}

// 数据类型删除
export function dataTypesDel(id) {
  return request({
    url: '/data_types/delete/' + id,
    method: 'delete'
  })
}

