const customers = {
    name: 'customers',
    path: '/customers',
    component: () => import('@/views/dashboard/Index'),
    meta: { title: 'clientman', icon: 'mdi-account-box', funcs: ['customer', 'sell_company', 'buy_company', 'order_receipt', 'customer_account'] },
    children: [
        {
            name: 'Customer List',
            path: 'list',
            component: () => import('@/views/dashboard/pages/customers/customersList'),
            meta: { title: 'customerMgr', icon: 'list', funcs: 'customer' }
        },
        {
            name: 'Company Info',
            path: 'company',
            component: () => import('@/views/dashboard/pages/customers/companyInfo'),
            meta: { title: 'sstc', icon: 'list', funcs: 'sell_company' }
        },
        {
            name: 'procuring',
            path: 'procuring',
            component: () => import('@/views/dashboard/pages/customers/procuring'),
            meta: { title: 'procuring', icon: 'list', funcs: 'buy_company' },
            hidden: true
        },
        {
            name: 'gathering',
            path: 'gathering/:id',
            hidden: true,
            component: () => import('@/views/dashboard/pages/customers/gathering'),
            meta: { title: 'gathering', icon: 'list', funcs: 'order_receipt' }
        },
        {
            name: 'user',
            path: 'user',
            hidden: true,
            component: () => import('@/views/dashboard/pages/customers/user'),
            meta: { title: 'customer_account', icon: 'list', funcs: 'customer_account' }
        }
    ]
}

export default customers