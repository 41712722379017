import { zhHans } from 'vuetify/es5/locale'

export default {
  ...zhHans,

  goodsType: "产品类别",
  back2Product: "返回产品",
  abolish: '完毕',
  done: "完毕",
  Abroad: '境外',
  absenteeism: '旷工',
  account: '账户名',
  newCategory: "新类别",
  arrAccount: '到账',
  categoryName: "分类名称",
  accountName: '开户名',
  AccountPeriod: '账期',
  accountUse: '账号使用',
  achieve: '完成',
  achieveOrderList: '待QC',
  actionState: '操作',
  addProductImage: "添加产品图片",
  actionStateTip: '请选择领料单状态',
  activateNow: '是否激活',
  active: '激活',
  actualArrival: '实际到货',
  ActualQTY: '实发数量',
  actualQuantity: '实发数量',
  ActualTotalPriceArrived: '已到货实际总价',
  Add: '增加',
  add: '添加',
  add5: '添加',
  AddANewReceipt: '添加新的收款单',
  AddEmails: '添加新的邮箱',
  addGoods: '添加商品',
  additems: '添加商品',
  additems1: '添加',
  addNode: '增加节点',
  addOrder: '订单添加',
  addPeople: '添加人员',
  addr: '详细地址',
  address: '地址',
  address1: '公司地址',
  address3: '支行',
  addSuccess: '添加成功',
  addSuccessInfo: '添加成功, 是否继续添加?',
  addTime: '添加时间',
  administrator: '管理员',
  advertising: '投放广告',
  all: '全部',
  allDay: '全天',
  AlreadyApplied: '已申请',
  Amount: '金额',
  amountApplied: '已申请金额',
  amountReceived: '收款金额',
  annex: '附件',
  antistop: '关键词',
  aog: '到货',
  appendixUpload: '附件上传',
  ApplDate: '申请时间',
  applicant: '申请人',
  application: '申请',
  applicationReturn: '申请退货',
  applicationTime: '申请时间',
  applyReject: '申请驳回',
  applyUser: '申请人',
  approve: '审批',
  Approved: '审批通过',
  approver: '审批人',
  ArrivalTime: '到货时间',
  ArrivalWarehouse: '到货仓库',
  arrived: '已到货',
  article: '文章',
  artifactType: '成品类型',
  artwork: '图稿',
  artworkNo: '图稿号',
  associate: '关联人员',
  associatedContract: '关联合同',
  associatedContractNum: '关联合同号',
  attach: '附件',
  attachment: '附件',
  attendanceRecord: '考勤记录',
  audit: '审核',
  auditOrder: '审核订单',
  authorization: '授权',
  avatar: '头像',
  AWeekAgo: '一周前',
  back: '返回',
  BackGround: '背景图',
  bank: '开户行',
  bankAccNo: '银行账号',
  bankAccount: '银行账号',
  BankAccountEditing: '银行账号编辑',
  bankAcctNo: '银行账号',
  bankCountry: '银行所在国',
  bankInfo: '银行信息',
  bankAcc: '开户行',
  bankName: '银行名称',
  bankName1: '开户银行',
  bankName2: '银行名',
  bankNo: '银行账号',
  bankStatements: '银行对账单',
  bankSwiftCode: 'Bank Swift Code',
  basicData: '基础数据',
  BasicDataMgr: 'ID库',
  basicInfo: '基本信息',
  basicInfo1: '基本信息',
  basicInformation: '基础信息',
  batchExport: '批量导出',
  batchUpload: '批量上传',
  batchUpload1: '批量导入',
  becomeReqularEmplovee: '转正薪水',
  begin: '发起',
  beginProduct: '开始生产',
  BeneficiaryBank: '收款银行',
  birthday: '生日',
  bom: 'BOM单',
  Bottles: 'Bottles',
  bulkImport: '批量导入',
  bulkUpload: '批量上传',
  businessId: 'business ID',
  businessType: '业务类型',
  calendarView: '日历视图',
  cancel: '取消',
  canceled: '已取消',
  canceledDelete: '已取消删除',
  canceler: '取消人',
  cancellationTime: '取消时间',
  cancelOrder: '取消订单',
  cancelOrderList: '已取消',
  orderInfo: "订单信息",
  cancelReason: '取消原因',
  cancelReturnRequest: '取消退货申请',
  canping: '产品',
  CargoCategory: '货物类别',
  carton_dimension: '包装尺寸',
  carton_num: '包装数量',
  cash: '现金',
  categories: '领料单类型',
  categories1: '供应商分类',
  Categories2: '问题分类',
  categoriesCommodities: '商品种类',
  category: '类型',
  Category6: '分类',
  categoryClass: '分类',
  categoryListAll: '全部',
  // centerPtlogo: require('../assets/images/sy/icon_ptlogo.png'),
  cetegory: '业务类型',
  ChangeTime: '修改时间',
  changeLang: '修改语言',
  check: '查看',
  orderModReview: '订单修改审核',
  checkFile: '查看文件',
  checkMonth: '对账月',
  checkUuid: '检测',
  chinese: '中文',
  chineseName: '中文名',
  chipLib: '芯片库',
  havechip: '有芯片',
  chip: '芯片',
  chooseCategories: '请选择分类',
  chooseDaorukucfl: '请选择导入库存所属分类',
  chooseDate: '选择日期',
  chooseFile: '选择文件',
  chooseOneSalesEntity: '请至少选择一种销售主体',
  chooseShipingWarehouse: '请选择发货仓库',
  chooseType: '请选择类型',
  churukuProductQty: '出入库商品数量',
  city: '市',
  classification: '分类',
  classify: '分类',
  clickNodeList: '请先点击节点列表中的节点!!!',
  clickSelectGoods: '请点击选择商品',
  clickUpload: '点击上传',
  client: '客户',
  editClient: '客户编辑',
  clientConfirm: '客户确认',
  clientman: '客户管理',
  closed: '已结案',
  cm: 'cm',
  code: '编号',
  codeClassification: '商品编码',
  codeTip1: '已经发送验证码，请注意查看',
  codeTip2: '请输入正确的电话号码',
  codeTip3: '账号激活成功',
  codeTip4: '账号密码修改成功，请再次登录',
  codeTip5: '修改密码',
  codeTip6: '此账号需要激活，是否激活？',
  codeTip7: '版权所有',
  coding: '编码',
  Colum: '行',
  comAddress: '公司地址',
  comment: '评论',
  commentDetail: '订单评论详情',
  commentInfo: '沟通记录',
  company: '公司',
  companyMgm: '公司管理',
  companyAddress: '公司地址',
  companyChineseName: '公司中文名',
  companycontact: '公司联系人',
  companyContactNumber: '公司联系号码',
  companyEmail: '公司邮箱',
  companyFax: '公司传真',
  companyInfo: '公司信息',
  companyName: '公司名称',
  companyNameShort: '简称',
  backgroundcolor: '背景颜色',
  fontcolor: '字体颜色',
  logo: '标识',
  background: '背景',
  companyName1: '公司英文名',
  companyName2: '公司名',
  companyName3: '公司名称',
  complain: '投诉',
  complaintDate: '投诉日期',
  complaintGoods: '投诉商品',
  ComplaintList: '投诉列表',
  complaintNumber: '投诉数量',
  complaintRecord: '投诉记录',
  Complaints: '投诉',
  complete: '完成',
  Completed1: '完成（整个订单完成到货）',
  completeQC: '完成QC',
  Confirm: '保存',
  confirm: '确定',
  confirm3: '确认',
  Confirm5: '确 定',
  confirmAdd: '确认添加',
  confirmAudit: '确认审核',
  confirmCancelPickList: '确定取消该领料单?',
  confirmChange: '确认修改',
  confirmCom: '确认完成',
  confirmDelCaiGou: '确定删除当前采购主体?',
  confirmDelCurrentRole: '确定删除当前角色?',
  confirmDelCurrentSale: '确定删除当前销售主体?',
  confirmDelCurrentSuppler: '确定删除当前供应商？',
  ConfirmDelete: '确定删除当前银行账号信息?',
  confirmDelivery: '确认发货',
  confirmedReturn: '确认退货',
  confirmModify: '确认修改',
  confirmOperation: '是否确认此操作',
  confirmOrder: '确认下单',
  confirmPass: '确认密码',
  confirmReceiptRefund: '确认收货并退款',
  confirmReview: '已确认/待审核',
  ConfirmToDeleteThisAccountPeriod: '确认删除此条账期',
  confirmWithdrawal: '确认提现',
  confrimDelGoods: '确定删除该商品?',
  conifr: '确认完成之后不能再修改',
  consignor: '发货人',
  contactDetails: '联系方式',
  contactEmail: '联系人邮箱',
  contactInfo: '联系人信息',
  contactIphone: '联系电话',
  contactPerson: '联系人',
  contactPersonPhone: '联系人号码',
  contactPhone: '联系电话',
  content: 'Description',
  contractInformation: '合同信息',
  Controller: '负责人',
  Controllerlist: '人员选择',
  copy: '复制',
  copyOrder: '复制订单',
  copyFee: '版费',
  copyTemp: '复制模板',
  CorrespondingPurchaseOrder: '对应采购单',
  cost: '成本核算',
  costCategories: '费用分类',
  costList: '费用列表',
  Country: 'Country',
  country: '洲',
  craft: '工艺管理',
  createCategory: '创建分类',
  CreateCategory: '创建分类',
  createCategory5: '创建分类',
  CreateDepartment: '创建部门',
  CreatePurchaseOrder: '创建采购单',
  createStock: '创建领料单',
  createTime: '创建时间',
  CreateType: '创建类别',
  creationTime: '创建时间',
  creditCard: '信用卡',
  Cup: 'Cup',
  currency: '币种',
  currency1: '结算币种',
  CurrencySymbol: '代码',
  currentLocation: '当前位置',
  currentQty: '数量/收货数量/退货数量',
  customer: '客户',
  customerAcc: '用户账号',
  customer_account: '客户账号',
  customerAccessories: '客户附件',
  CustomerCreatPendConfirm: '客户创建/待确认',
  customerEdit: '客户编辑',
  customerFinance: '付款记录',
  customerID: '编码',
  CustomerID2: '编码',
  customerList: '客户列表',
  CustomerList: '客户列表',
  customerMgr: '客户管理',
  customerName: '客户名称',
  customerordernumber: '客户订单号',
  customerPurchaseOrder: 'PO合同号',
  customerQTY: '客户数量',
  customerType: '客户类型',
  cutomerChoose: '请选择客户',
  cutomerInfo: '客户信息',
  cutomerName: '客户名称',
  daichengtuan: '待成团',
  dashboard: '仪表板',
  data: '条数据',
  date: '日期',
  dateBirth: '出生日期',
  dateOfDelivery: '交货日期',
  dateOfReceipt: '收到退卡日期',
  dateReceipt: '收款日期',
  DateType1: '数据类型',
  DateTypes: '数据类型',
  day: '天',
  defective: '次品',
  degrees: 'degrees',
  delCurrentFeeLogTip: '确定删除当前费用记录?',
  delCurrentGoodsTip: '确定删除当前商品?',
  delCurrentGoodsTip2: '确定删除当前分类?',
  delCurrentOrderPayTip: '确定删除当前订购单',
  delCurrentPersonInfo: '确定删除当前人员信息?',
  delDepartmentTip: '确定删除当前部门?',
  delete: '删除',
  Delete: '删除',
  deleteAD: '是否删除当前广告?',
  deleteAdminInfo: '是否删除当前管理员信息?',
  deleteArticle: '是否删除当前文章?',
  deleteCurrenNodeInfo: '是否删除当前节点信息?',
  deleteCurrentForm: '是否删除当前表单?',
  deleteCurrentTemplate: '是否删除当前模板?',
  deleteGoods: '是否删除当前商品信息?',
  deleteNode: '确定删除当前节点?',
  deleteNode1: '删除节点',
  deleteNodeData: '确定删除当前节点数据?',
  deleteSuccess: '删除成功',
  deleteWarehouse: '是否删除当前仓库信息?',
  delFinanceLogTip: '确定删除当前财务日志',
  deliery_addr: '详细地址',
  Delivered: '已出货',
  deliveryCompleted: '出库（完成）',
  deliveryCompOrderList: '已完成',
  arrivalDate: '到货日期',
  deliveryDate: '发货日期',
  deliveryDiscipline: '发货记录',
  deliveryMethod: '发货方式',
  deliveryOfGoods: '发货商品',
  deliveryOrderList: '待发货',
  deliveryRecord: '发货记录',
  delOrderComplaintTip: '确定删除当前投诉记录?',
  delOrderTip: '此操作将删除当前订单, 是否继续?',
  department: '部门',
  departmenInfo: '部门信息',
  departmentList: '全部',
  departmentsList: '部门分类',
  departmentHead: '部门主管',
  departName: '部门名',
  deposit: '存款',
  des: '问题描述',
  deselect: '取消选择',
  detail: '明细',
  determineDeleteRecord: '确定删除当前发货记录?',
  determinedPerson: '确定人',
  determineTime: '确定时间',
  district: '区',
  Dollar: '美金（USD）',
  domesticFreight: '境内运费',
  DomestiSales: '内销',
  dosage: '用量',
  download: '下载',
  dragFileHere: '将文件拖到此处，或',
  dryclfl: '请选择导入原材料所属分类',
  duties: '工作职责',
  edit: '编辑',
  editNode: '编辑节点',
  editNodeData: '编辑模板内节点数据',
  editorialStaff: '编辑人员',
  EditPanel: '编辑',
  editStock: '编辑领料单',
  eidtSuccess: '编辑成功',
  eidtUserInfo: '修改个人信息',
  personalEmail: '私人邮箱',
  email: '邮箱',
  emergencyPerson: '紧急联络人',
  emergencyPhone: '紧急联络电话',
  employeeId: '员工编号',
  enclosure: '附件',
  endDate: '结束日期',
  endMonth: '结束月份',
  english: '英文',
  enterAccount: '请输入手机号',
  enterAddress: '请输入地址',
  enterBankAcc: '请输入银行账户',
  enterbankCountry: '请输入银行所在国',
  enterBankName: '请输入开户行',
  enterbankSwiftCode: '请输入Bank Swift Code',
  enterCategoryCode: '请选择分类编号',
  enterComplete: '请输入完整',
  enterContact: '请输入联系人',
  enterCorrectEmailAddress: '请输入正确的邮箱地址',
  enterCorrectPhoneNumber: '请输入正确的手机号',
  enterEmail: '请输入邮箱',
  entereProduct: '请输入商品',
  enterExcelExport: '请选择Excel格式的文件导入',
  enterFactoryName: '请输入工厂名',
  enterFax: '请输入传真',
  enterGoodsChineseName: '请输入商品中文名称',
  enterGoodsClassName: '请输入商品分类的名称',
  enterGoodsCode: '请输入商品编号',
  enterGoodsEnglishName: '请输入商品英文名称',
  enterGoodsPrice: '请输入商品价格',
  enterInfo: '入职信息',
  personalDocx: '个人证件',
  enterName: '请输入名字',
  enterNameSearch: '请输入名称搜索',
  enterNewPass: '请输入新密码',
  enterOldPass: '请输入原密码',
  enterPassWord: '请输入密码',
  enterPayPerson: '请输入收款对象',
  enterPhone: '请输入手机',
  enterPhone1: '请输入电话',
  enterPoGoodsDrafetTip: '请输入查询的po/商品/图稿号等',
  enterPrincipal: '请输入负责人',
  enterQueryCriteria: '请输入查询条件',
  enterSearchContent: '请输入查询内容',
  enterSearchOrder: '请输入搜索的订单号',
  enterStockId: '请输入仓库编号',
  enterStockName: '请输入仓库名',
  enterSupplierClassName: '请输入供应商分类的名称',
  entersupplierRating: '请输入供应商评级',
  enterTime: '入职时间',
  enterValidPhoneNumber: '请输入正确的手机号',
  enterYouMessage: '在此输入留言',
  error: '错误',
  errorCode: '错误编码：',
  examinationPassed: '审核通过',
  exchangeRate: '汇率',
  existsAgain: '已存在，请重新选择',
  expenditure: '支出',
  expenseLog: '费用日志',
  expireDate: '过期时间',
  export: '导出',
  Export2: '出口外销',
  exportKingdeeTemplete: '导出金蝶模板',
  exportPdf: '导出pdf',
  extendedInfo: '扩展信息',
  facebook: "脸书",
  FactoryAccessories: '工厂附件',
  factoryAppendixUpload: '工厂附件上传',
  factoryCopyFee: '工厂版费',
  factoryEdit: '工厂编辑',
  factoryList: '工厂列表',
  factoryMgr: '工厂管理',
  factoryPrice: '工厂价格',
  factoryUnitPrice: '工厂单价',
  fax: '传真',
  feedbackDetail: '反馈详情',
  feeLog: '费用日志',
  fenLei: '分类',
  selecttype: "选择类型",
  fieldService: '外勤',
  fileHasBeenUpload: '该文件已上传',
  fileList: '文件列表',
  filename: '文件名',
  filesUpload: '文件上传',
  FinanceController: '财务人员',
  financeLog: '财务日志',
  financeSend: '财务确认',
  financialMgr: '财务管理',
  findPassword: '找回密码',
  finishedDate: '结束日期',
  finishedProductType: '成品类型',
  fkAmount: '付款方账号',
  fkComAddress: '付款公司地址',
  fkComtact: '付款方联系人',
  fkPhone: '付款放联系电话',
  floatcargo: '未到货',
  forgetPassword: '忘记密码',
  freeSample: '免费样品',
  freight: '运费',
  Function: '功能选择',
  FunctionCode: '功能分类编号',
  FunctionList: '功能清单',
  Fundamentaldata: '基础数据列表',
  gathering: '收款单',
  generalPurchase: '普通采购',
  GiveAway: '赠送',
  Glass: 'Glass',
  goods: '商品',
  goodsAdd: '商品添加',
  goodsCategory: '商品分类',
  goodsCode: '商品编号',
  goodsDesc: '货物描述',
  goodsEdit: '商品编辑',
  goodsInfo: '商品信息',
  goodsList: '商品列表',
  goodsListCategory: '商品',
  goodsMgr: '商品管理',
  goodsName: '商品名称',
  goodsParamTemplate: '参数模板',
  goodsShelves: '上架商品',
  goodsTotal: '商品总数',
  goodsTotalPrice: '商品总价格',
  goodUnit: '商品计量单位',
  gross_weight: '毛重',
  guanlixitong: '管理系统',
  HalfDomestic: '一半内销',
  halfDomesticSales: '一半内销',
  handlingMethod: '处理办法',
  hardware: '硬件',
  hardwareSoftwareSystem: '软硬件系统',
  hasMakeOutAnIncoice: '已开票',
  havePayed: '已支付',
  hierarchy: '层级',
  history: '历史',
  holiday: '放假',
  home: '首页',
  huodaofukuan: '货到付款',
  ID: '编号',
  idLibrary: 'ID列表',
  IDNo: '身份证号',
  idPass: '身份证/护照',
  image: '图片',
  imageSizeTip: '超过了文件大小限制',
  imgUploadTip1: '将文件拖到此处，或',
  imgUploadTip2: '点击上传',
  imgUploadTip3: '只能上传jpg/png文件，且不超过',
  important: '重要',
  importExportDury: '正式出口申报',
  importSuccess: '导入成功',
  importUUID: '导入UID',
  ImproveLogisticsInformation: '完善物流信息',
  inch: 'inch',
  incoiceTitle: '发票抬头',
  income: '收入',
  incomeExpenditureType: '收支类型',
  inOut: '仓库信息',
  inportSuccess: '导入成功!',
  internalSupplier: '内部供应商',
  internalSupplier1: '内部客户',
  intragroupCode: 'intragroup code',
  inventory: '库存',
  invertedNumber: '翻单号',
  invoice: '开票',
  invoiceInfo: '开票信息',
  invoiceMoban: 'invoice模板',
  invoiceNo: '发票号',
  invoiceNumber: '发票号',
  invoiceTitle: '发票抬头',
  isChip: '有无芯片',
  isHasOpen: '是否开通',
  itemNo: '序号',
  ItemsTTLQty: '商品数量',
  jihuo: '激活',
  jihuoAmount: '激活账号',
  jizhuPassword: '记住密码',
  keyword: '关键词',
  KG: 'KG',
  kh: '柬币',
  kuaidi: '快递',
  kuaidifangshi: '快递方式',
  lastQuestion: '最后提问',
  lastReply: '最后回复',
  lastStep: '上一步',
  lastUpateTime: '最后变更时间',
  late: '迟到',
  layer: 'layer',
  leaveEarly: '早退',
  level: '优先级',
  linkSet: '链接设置',
  linkType: '请选择链接类型',
  list: '功能清单',
  loading: '加载中',
  login: '登录',
  loginFailed: '登陆失败！用户名或密码错误',
  incorrectPassowrd: '密码错误',
  loginIp: '登陆IP',
  loginTime: '登陆时间',
  logout: '登出',
  lookMore: '查看更多',
  maijiabeizhu: '买家备注',
  mail: '邮件列表',
  mailAdderss: '邮寄地址',
  mailInfo: '邮件详情',
  makeSureCurentRole: '确定删除当前角色',
  makeSureRemoveFile: '确定移除该文件?',
  man: '男',
  manufacturingTechnique: '生产工艺',
  materialName: '物料名称',
  materials: '原材料',
  merchantBackstage: '商户后台管理中心',
  merchantSystem: '商户管理系统',
  mingxi: '明细',
  missingCard: '缺卡',
  mobile: '手机号',
  modify: '修改',
  modifyOrder: '修改订单',
  ModifyPanel: '部门编辑',
  modifySave: '请修改后再保存',
  modifyStatus: '修改状态',
  modifySuccess: '修改成功',
  modifySuccessInfo: '编辑成功, 是否返回列表页?',
  month: '月',
  months: '月份',
  name: '名称',
  namePhoneContact: '请输入名称/电话/联系人',
  nationality: '国籍',
  needInvoice: '待开票',
  needPay: '待支付',
  net_weight: '净重',
  new: '新增',
  NewCurrency: '添加币种',
  Newdata: '添加',
  newDetails: '订单详情',
  NewFunction: '添加',
  newOrder: '新增订单',
  newOrderAdd: '添加',
  newPassword: '新密码',
  NewRole: '添加角色',
  news: '消息',
  newSupplierInfo: '编辑信息',
  nextStep: '下一步',
  no: '否',
  num: '编号',
  no2: '无',
  noChip: '无芯片',
  nodeNoDate: '当前节点无数据',
  non_standardProducts: '非标品',
  noPai: 'Item No.',
  noPreviewTemplate: '暂无预览模板，请填写预览模板',
  general: '一般',
  normal: '正常',
  noScantling: '非标品',
  note: '备注',
  Nowadays: '今天',
  number: '数量',
  NumberOfFactories: '工厂数量',
  NumberOfGifts: '赠送数量',
  NumberOfSuppliers: '供应商数量',
  nweCustomer: '新增',
  offStocks: '已完成',
  oldPassword: '原密码',
  onlinePay: '在线支付',
  onlyExcelFile: '只能上传excel文件',
  OperatTime: '操作时间',
  opt: '操作',
  optTime: '操作时间',
  optUser: '操作人',
  order: '订单',
  customerSetting: '选择客户管理',
  orderAmount: '订单金额',
  orderCheck: '待审核',
  orderChoose: '订单选择',
  orderComment: '投诉',
  orderCommentDetail: '订单投诉详情',
  orderConfirm: '订单确认',
  orderGoods: '订单商品：',
  orderDetails: '订单详情',
  orderInfo: '订单概况',
  orderList: '订单列表',
  orderMgr: '订单管理',
  orderNo: '订单编号',
  orderNoSearchTip: '请输入关联销售单号',
  orderNumber: '订单数量',
  orderPlace: '待下单',
  orderPo: '订单po',
  orderPoSearch: '请输入查询条件',
  orderPrice: '下单价格',
  orderQty: '下单数量',
  orderReview: '订单审核',
  Orders: '订单',
  orderSale: '销售代表：',
  orderStatistics: '订单统计',
  orderDate: '下单日期：',
  orderTime: '下单时间',
  orderTotalPrice: '订单总额',
  orderyield: '生产订单',
  other: '其他',
  otherContact: '其他联系人',
  otherInformation: '其他信息',
  outerChain: '外链',
  OutOfStock: '已出库',
  Outsourcing: '委外采购',
  Outsourcingss: '外包业务',
  outsourcingType: '委外类型',
  outStock: '出库',
  overseasFreight: '境外运费',
  overtime: '加班',
  packing: '包装',
  paid: '已支付',
  paymentReceived: '已收款',
  paidAmount: '已支付/订单金额',
  parameter: '参数',
  ParameterTemplateClassification: '参数模板分类',
  parameterTemplateList: '参数模板列表',
  parentCategory: '父分类',
  particulars: '详情',
  passport: '护照号',
  passportName: '护照上姓名',
  passResetSuccess: '密码重置成功',
  password: '密码',
  passwordCannotLess: '密码不能小于6位',
  passwordNotmatch: '新密码并确认密码不匹配',
  pay: '支付',
  payed: '到账',
  payable: '应收款项',
  applyDate: "申请日期",
  paying: '付款记录',
  payingEdit: '付款编辑',
  PaymenSerialNumber: '支付流水号',
  payment: '付款方',
  payment2: '付款',
  paymentAccount: '收款账号',
  paymentAmount: '付款金额',
  paymentCompany: '付款公司',
  paymentContactPerson: '收款对象',
  paymentDate: '付款日期',
  paymentDays: '账期',
  paymentHasBeenReceived: '已到账',
  payMentInfo: '支付信息',
  PaymentInformation: '支付信息',
  paymentLimit: '账期额度',
  paymentOrder: '付款单',
  PaymentOrder: '付款订单',
  paymentRecords: '收款记录',
  PaymentSlip: '付款单支付',
  paymentSuccessful: '支付成功',
  paymentterm: '账期',
  paymentTerm: '账期天数',
  PaymentTerm1: '对账月',
  PaymentTermMgr: '添加',
  PaymentTime: '支付时间',
  Pcs: 'Pcs',
  PendingOpenInvoice: '待开票',
  pendingOrder: '待处理销售单',
  pendingPicklist: '待处理领料单',
  pendingPurchase: '待处理采购单',
  permitNo: '单号',
  permitSearchTip: '请输入查询领料单号',
  personalInformation: '个人信息',
  personalizedProcess: '个性化工艺',
  personList: '人员列表',
  phone1: '电话',
  phone2: '联系电话2',
  phoneCannotEmpty: '手机号不能为空',
  phoneNotNull: '手机号不能为空',
  pick: '领料单',
  pickEdit: '领料单编辑',
  picking: '领料单列表',
  pickingListReview: '领料单审核',
  pickNew: '新建领料单',
  placeOrder: '下单',
  plaeasEnterTaxRate: '请输入税率',
  plannedArrival: '计划到货',
  plannedArrivalR1: '计划到货R1',
  plannedArrivalR2: '计划到货R2',
  pleaseAddGoods: '请添加商品',
  pleaseAddGoodsNumber: '请添加商品数量',
  pleaseAddNumber: '请添加数量',
  pleaseAddProductFirst: '请先添加商品',
  PleaseAddProductQuantity: '请添加商品数量',
  PleaseAddPurchaser: '请添加采购人',
  PleaseAddTaxRate: '请添加税率',
  pleaseAddTypeFeeLog: '请先添加费用分类',
  PleaseBankAccounts: '请输入银行账户',
  pleaseCangKu: '请选择仓库',
  pleaseChoose: '请选择',
  pleaseChooseGoods: '请选择商品',
  pleaseChoosePoNo: '请选择单号',
  pleaseChooseRoles: '请选择角色',
  PleaseCompleteTheInformation: '请完善信息',
  PleaseCompleteTheTrackingNumber: '请完善单号',
  pleaseEditPassSame: '请与修改密码输入一致',
  PleaseEnterBankAccountNumber: '请输入银行账号',
  pleaseEnterCancelReason: '请输入取消原因',
  pleaseEnterFenleiName: '请输入商品分类的名称',
  pleaseEnterFormName: '请输入表单名称!',
  pleaseEnterGoodsNumber: '请输入商品数量',
  pleaseEnterName: '请输入名称',
  pleaseEnterNewPass: '请输入新密码',
  pleaseEnterProblemDesc: '请输入问题描述',
  pleaseEnterProcessMethod: '请输入处理方法',
  pleaseEnterPurchaseNo: '请输入采购单号',
  pleaseEntersaleList: '请输入关联销售单',
  pleaseEnterStockName: '请输入仓库名称',
  pleaseEnterThanZero: '请输入大于等于零的数',
  PleaseEnterTheAccountName: '请输入开户名',
  PleaseEnterTheBankName: '请输入银行名称',
  pleaseFillActualQuantity: '请填写完实发数量',
  pleaseFillData: '请填写完数据',
  PleaseSelectASupplier: '请选择供应商',
  PleaseSelectCurrency: '请选择币种',
  pleaseSelectGoods: '请选择商品',
  PleaseSelectTheArrivalWarehouse: '请选择到货仓库',
  PleaseSelectTheBillingPeriod: '请选择账期',
  pleaseSelectTheOrder: '请选择订单',
  pleaseSetNewPass: '请设置新密码',
  poMoban: 'po模板',
  poNo: '单号',
  position: '职位',
  contactPersonPosition: '联系人职位',
  postalCode: '邮编',
  preview: '预览',
  price: '价格',
  totalPrice: '总价格',
  PriceChange: '上浮的商品总价值不大于',
  priceControl: '价格控制设置',
  priceTip: '请正确输入价格，保留小数点后2位',
  principal: '负责人',
  print: '打印',
  Printing: '打印',
  printTip: '请使用google或者360浏览器打印',
  probationSalary: '试用薪水',
  problemDesc: '问题描述',
  process: '流程',
  processingTime: '审批时间',
  procuring: '采购主体',
  produce: '生产',
  product: '产品',
  commodity: '商品',
  producing: '生产中',
  productCategory: '类型',
  productCategoryTip: '请选择领料单类型',
  productChoose: '商品选择',
  ProductClass: '商品分类',
  productDecription: '商品名称',
  productDescription: '产品',
  productDescription1: '产品名',
  productEdit: '商品编辑',
  productId: '商品编号',
  productionFactory: '生产工厂',
  productionOrderNo: '生产单号',
  productionProcess: '生产工艺',
  productList: '仓库的商品',
  productList1: '商品列表',
  productName: '商品名称',
  productPicture: '商品图片',
  productPrice: '产品价格',
  products: '产品',
  Products: '商品',
  productsName: '产品名',
  productUnit: '商品单位',
  profit: '利润',
  ProhibitEditingInformation: '禁止编辑信息',
  prompt: '提示',
  provideInfo: '供应信息',
  province: '省',
  // ptlogo: require('../assets/images/dl/icon_ptlogo.png'),
  publicInventory: '选择公共库存',
  purchase: '采购',
  PurchaseAmount: '采购额',
  purchasedQuantity: '采购数',
  purchaseEdit: '采购单编辑',
  purchaseList: '采购单列表',
  purchaseMgr: '采购管理',
  purchaseNo: '采购单号',
  purchaseorder: '采购订单',
  PurchaseOrderList: '采购单列表',
  expense: "费用",
  purchaseOrderStatistics: '采购单统计',
  purchaseQty: '购买数量',
  Purchaser: '采购人',
  purchaseReturns: '采购退货',
  QCOrder: '完成QC',
  qcOrderList: '待财务确认',
  qty: '数量/收货数量/退货数量',
  qty1: '数量',
  qty5: '数量',
  qtyOrder: '订单数量',
  qtyReturn: '退货数量',
  quanBuFeeLog: '全部',
  totalQty: '总数量',
  Quantity: '数量/收货数量/退货数量',
  quantity2: '商品采购数量大于该商品订单数量，是否继续',
  quantityCompleted: '完成数',
  quantityRequested: '申领数量',
  quertAnswer: '反馈回复',
  quertClass: '反馈类型',
  quertEdit: '反馈编辑',
  quertList: '订单支持',
  R_DProducts: '研发品',
  RawMaterials: '原材料',
  RDProducts: '研发品',
  readed: '已读',
  reason: '原因',
  receipt: '付款单',
  Receipt2: '收货',
  ReceivableReceivedPayment: '代收款',
  receivablesCompany: '收款公司',
  ReceivedPayment: '已到账',
  receiveOrder: '生产完成',
  ReceivePayment: '收款',
  ReceivingAccount: '收款账号',
  receivingAddress: '收货地址',
  receiver: '收货人',
  refund: '取消订单',
  registrationNo: '注册号',
  reject: '驳回',
  rejected: '驳回',
  remarks: '备注',
  remarks1: '退货详情',
  remarksEdit: '备注编辑',
  reply: '回复',
  requestedReturnQty: '申请退货数量',
  required: '需要',
  fieldRequired: "此字段是必需的",
  reset: '重置',
  resetPassword: '重置密码',
  rest: '休',
  returnRequest: '退货申请',
  returnWarehouse: '退货仓库',
  review: '审核',
  preparing: '准备中',
  reviewPending: '已审核/待完成',
  rfid: 'RFID防伪溯源',
  rider: '骑手',
  RMB: '人民币（RMB）',
  RoleControlPanel: '角色编辑',
  roles: '角色',
  Roll: '列',
  row: '排',
  saleMarket: '内销',
  saleReturn: '退货',
  sales: '销售',
  salesDepramentCode: '销售部门编码',
  Salesdiscount: '销售折算RMB',
  salesEntity: '所属销售主体',
  salesGroupCode: '销售组编码',
  salesMain: '销售主体',
  salesman: '销售人员',
  salesName: '销售姓名',
  salesNo: '销售单号',
  salesOrder: '销售订单',
  salesperson: '销售人员',
  salespersonCode: '销售员编码',
  SalesPhone: '销售电话',
  salesQty: '销售编号',
  SalesQty: '销售量',
  salesReturn: '退货',
  salesSubject: '销售主体',
  salesTel: '销售电话',
  SalesTotal: '销售总额',
  SalesTTL: '销售额',
  sample: '样品',
  sampleList: '样品单',
  SampleSheet: '样品单',
  save: '保存',
  saveSuccess: '保存成功',
  scantling: '标品',
  scrap: '报废品',
  totalItem: '总产品',
  search: '查询',
  searchOrder: '查询 Order ID',
  searchPO: '查询 PO ID',
  searchCrit: '请输入查询条件',
  search1: '搜索',
  searchContent: '请输入查询内容',
  searchOption: '查询条件',
  searchPersonName: '请输入搜索的人员名称',
  searchRequirement: '请输入查询条件',
  searchTip: '请输入查询内容',
  select: '选择',
  selectAccontManagment: '选择客户管理',
  selectDate: '选择日期',
  selectExcelFormatFile: '请选择Excel格式的文件导入!',
  selectFile: '选取文件',
  selectFileUpload: '选择文件上传',
  selectInsertLink: '请选择您要插入链接的对象',
  SelectOrder: '选择订单',
  selectSalesperson: '请选择销售人员',
  selectSupplier: '选择供应商',
  selectType: '请选择单据类型',
  sellingPrice: '出售价格',
  send: '物流发货',
  sendCode: '发送验证码',
  sendEmail: '发送邮件',
  sendWay: '发货方式',
  serialNumber: '流水号',
  serverAddress: '服务器地址',
  set: '设置',
  setSelection: '请设置选择项',
  SetupFee: '板费',
  sex: '性别',
  shagnjiabeizhu: '商家备注',
  shenzhenlili: '深圳礼礼科技有限公司',
  shipped: '已发货',
  Shipping: '运费',
  ShippingCompany: '货运公司',
  ShippingFee: '运费',
  ShippingRecordList: '发货记录列表',
  shippingWarehouse: '发货仓库',
  ShortName: '简称',
  shouhuoAddress: '收货地址',
  showFiels: '查看文件',
  shut: '关闭',
  // signIn: require('../assets/images/dl/icon_dl.png'),
  siteInfo: '站点信息',
  size: '尺寸',
  skAmount: '收款方账号',
  skCompanyAddress: '收款公司地址',
  skContact: '收款方联系人',
  skip: '跳过',
  skPhone: '收款放联系电话',
  software: '软件',
  softwareAndHardwareSystem: '软硬件系统',
  sort: '排序',
  Sorting: '排序',
  sstc: '公司信息',
  standardProduct: '标品',
  startDate: '开始日期',
  startMonth: '开始月份',
  startStopTime: '起止时间',
  stat: '统计',
  stateProvince: '国家',
  statistics: '统计',
  status: '状态',
  statusChart: '查看图表',
  statView: '统计视图',
  stockAddress: '仓库地址',
  stockEdit: '仓库编辑',
  stockInOutRec: '出入库记录',
  stockQty: '库存',
  storageTime: '出入库时间',
  sub: '提交',
  subDepartment: '子部门',
  subitemMaterialCode: '子项物料编码',
  Submit: '提交',
  subTime: '提交时间',
  successful: '成功',
  successfulImport: '成功导入：',
  successfullyDeleted: '删除成功',
  SuccessfulOperation: '操作成功！ 是否返回？',
  suoyou: '所有',
  supplier: '供应商',
  Supplier: '供应商',
  supplier_account: '账号',
  supplierAccessories: '供应商附件',
  supplierAdd: '添加',
  supplierList: '供应商列表',
  supplierRating: '供应商评级',
  SureToDeleteThisPaymentSlip: '确定删除这条付款单',
  SwiftCode: 'Swift Code',
  sysCategory: '功能分类',
  sysFuncs: '功能管理',
  sysFuncsEdit: '功能编辑',
  sysList: '功能列表',
  sysMgr: '系统管理',
  sysRoles: '角色管理',
  System5: '系统（包含软件硬件)',
  systemSoftware: '系统（包含软件硬件）',
  systemSync: '系统同步',
  tax: '税率',
  taxIncludedQuotation: '报价已含税',
  taxIncludedQuotations: '单价已含税',
  taxInfo: '税务相关',
  taxNo: '税号',
  taxRate: '税率',
  tel: '电话',
  templateClassification: '模板分类',
  templateCreateSuccess: '模板创建成功是否跳转到模板列表?',
  templateParameter: '模板参数',
  Territory: '境内',
  theExport: '出口外销',
  thickness: '厚度',
  ThisArrival: '本次到货',
  threeEncod: '商品编码',
  tianxieQtyReturn: '请填写少于已申请退货的商品数',
  time: '时间',
  timeBecomeReqular: '转正时间',
  timeBecomeRegular: '时间成为正式员工',
  tip1: '仅客户账号具有此权限',
  priceTip2: '设置此页面的各种价格设置，当订单的下单量比超过这些设置的时候，会触发审核，需要管理员审核该订单',
  title: '标题',
  to: '至',
  FrieghtView: '暂无设置模板',
  toBeAudited: '待审核',
  toBeComplete: '待完成',
  customerAccount: '用户账号',
  toBeConfirm: '待确认',
  Gathering: '搜集',
  toBeProduced: '待生产',
  tobeReceived: '待收货',
  toOrder: '待下单',
  total: '合计',
  TotalAmount: '总金额',
  totalAmountOrder: '订单总金额',
  totalAmout: '总额',
  TotalCost: '总价格',
  totalFreight: '总运费',
  totalMoney: '总金额',
  totalNumber: '总数量',
  totalOrderAmount: '订单总额',
  totalPrice1: '总价格',
  totalPrice2: '订单金额',
  toView: '查看',
  toWork: '班',
  toWork1: '上班',
  traceability: '溯源',
  TradeCondition: '贸易条款',
  transactionTerms: '交易条件',
  tripartiteCoding: '三方编码',
  TTLOrders: '订单数量',
  ttlPrice: '总价',
  twitter: '推特',
  type: '类型',
  Types: '类别',
  unfloatcargo: '已到货',
  uniqueCode: '唯一码',
  unit: '个',
  singleUnit: '单位',
  messureUnit: '计量单位',
  unitMeasurement: '计量单位',
  unitPrice: '单价',
  UnitPriceIncludesTax: '单价已含税',
  Unpaid: '待支付',
  Unpaid1: '未收款',
  unread: '未读',
  unreadMeasage: '未读消息',
  Unshipped: '未出货',
  untreated: '未处理',
  up1: '成功导入',
  upload: '上传uuid',
  uploader: '上传人',
  uploadFiles: '上传文件',
  uploadImgSize: '上传图片只能是 JPG、png 格式!上传图片大小不能超过500KB!',
  uploadLogoInfo: '上传图片只能是 JPG、png 格式!上传图片大小不能超过100KB!',
  uploadLogoSize: '上传的Logo大小不能超过100KB！',
  uploadPicturesErrInfo: '上传图片只能是 JPG、png 格式!',
  uploadPicturesSize: '上传图片大小不能超过',
  uploadSuccess: '上传成功',
  uploadUuidsErrInfo: '上传图片只能是 txt、xlsx 格式!',
  usd: '美金',
  user: '客服',
  userList: '人员信息',
  userName: '名字',
  usersEdit: '人员编辑',
  uuidsInfo: '以下存在重复，请确认',
  vat: 'Vat No.',
  view: '查看',
  waitForDelivery: '等待发货',
  waitingForCustomer: '待客户确认',
  warehouseContact: '仓库联系人',
  warehouseGoods: '仓库商品',
  warehouseId: '仓库编号',
  warehouseInfo: '仓库信息',
  warehouseList: '仓库列表',
  warehouseLog: '仓库出入日志',
  warehouseMgr: '仓库管理',
  warehouseName: '仓库名称',
  warehousePhone: '仓库电话',
  warehousing: '入库',
  WaybillNumber: '运单号',
  website: '网站',
  wechat: '微信',
  welcome: '欢迎',
  whetherCloseCase: '是否结案',
  whetherRestoreCustomer: '是否回复客户',
  willDeleteRecord: '此操作将删除该记录, 是否继续?',
  withdraw: '取款',
  withdrawal: '提现',
  woman: '女',
  wuliaohao: '物料号',
  xiangqing: '详情',
  xianjingshouzhi: '现金收支',
  internalCustomer: '内部客户',
  yes: '是',
  yesterday: '昨天',
  yieldOrderList: '待生产/采购',
  yinghangcunqu: '银行存取款',
  youfei: '邮费',
  youNotChooseMain: '您没有选择销售主体',
  youNotTemplate: '您没有上传模板',
  yuan: '元',
  yujjlxrgx: '与紧急联系人关系',
  zhifufangshi: '支付方式',
  zipCode: '邮 编',
  ziti: '自提',
  err404: "404",
  err404title: "噢哦！你迷路了。",
  err404msg: "你寻找的页面不存在。你来到这里的原因是个神秘。但你可以点击下面的按钮回到首页。",
  uploadImgOnly: "请只上传图片",
}
