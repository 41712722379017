import request from '@/utils/request'
export function login(username, password) {
  return request({
    url: '/admin/login',
    method: 'post',
    data: {
      mobile: username,
      pass: password
    }
  })
}

export function getInfo() {
  return request({
    url: '/admin/info',
    method: 'get'
  })
}

export function logout() {
  return request({
    url: '/admin/logout',
    method: 'get'
  })
}

export function sendCode(data) {
  return request({
    url: '/sms/code',
    method: 'post',
    data: data
  })
}

export function updata(data) {
  return request({
    url: '/password/set',
    method: 'post',
    data: data
  })
}

export function message(data) {
  return request({
    url: '/users/message',
    method: 'post',
    data: data
  })
}
export function modifyUser(data) {
  return request({
    url: '/users_own/modify',
    method: 'post',
    data: data
  })
}

export function readMessage(data) {
  return request({
    url: '/users/message/read/' + data,
    method: 'get'
  })
}
