<template>
  <v-card-title class="display-2 font-weight-bold">
    {{ title }}
  </v-card-title>
</template>

<script>
export default {
    name:"DialogTitle",
    props: { title: String }
};
</script>