import Vue from 'vue'
import Vuetify from 'vuetify'
import '@/sass/overrides.sass'
import store from "@/store";
import en from '@/lang/en.ts'
import kh from '@/lang/kh.ts'
import zhHans  from '@/lang/zh-cn.ts'

Vue.use(Vuetify)

const theme = {
  primary: localStorage.getItem('color'),
  secondary: '#d500f9',
  accent: '#f50057',
}

export default new Vuetify({
  lang: {
    locales: { en, zhHans, kh },
    current: localStorage.getItem('lang') || 'en',
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
})
