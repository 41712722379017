const company = 
{
    name: 'company',
    path: '/company',
    component: () => import('@/views/dashboard/Index'),
    meta: { title: 'company', icon: 'mdi-domain', funcs: ['user', 'department', 'attendance'] },
    children: [
        {
            name: 'users',
            path: 'users',
            component: () => import('@/views/dashboard/pages/company/user'),
            meta: { title: 'userList', icon: 'list', funcs: 'user' }
        },
        {
            name: 'departments',
            path: 'departments',
            component: () => import('@/views/dashboard/pages/company/department'),
            meta: { title: 'department', icon: 'list', funcs: 'department' }
        },
        {
            name: 'attendance',
            path: 'attendance',
            component: () => import('@/views/dashboard/pages/company/attendance'),
            meta: { title: 'attendanceRecord', icon: 'list', funcs: 'attendance' }
        },
    ]
}

export default company
