import Vue from 'vue'
import Vuex from 'vuex'
import { getSiteSetting } from '@/api/system'
import { login, getInfo } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { routeLists } from '@/router'

Vue.use(Vuex)

function hasPermission(plInfo, route) {
  if (plInfo.is_root || plInfo.is_admin) {
    return true
  }
  if (route.meta && route.meta.funcs) {
    if (Array.isArray(route.meta.funcs)) {
      return route.meta.funcs.some(func => plInfo.functions[func] !== undefined && plInfo.functions[func].list)
    } else {
      return plInfo.functions[route.meta.funcs] !== undefined && plInfo.functions[route.meta.funcs].list
    }
  } else {
    return true
  }
}

function filterAsyncRouter(asyncRouterMap, plInfo) {
  const accessedRouters = asyncRouterMap.filter(route => {
    if (hasPermission(plInfo, route)) {
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children, plInfo)
      }
      return true
    }
    return false
  })
  return accessedRouters
}

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    // barColor: 'rgba(255,255,255,1) 60%, rgba(164,231,192,1)',
    // barColor: 'rgba(255,255,255,1) 10%, rgba(153,202,251, 0.2)',
    // background-image: radial-gradient( circle 788px at 0.7% 3.4%,  rgba(164,231,192,1) 0%, rgba(255,255,255,1) 90% );
    barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',
    drawer: null,
    siteInfo: {},
    token: getToken(),
    userInfo: {},
    permissionInfo: [],
    routers: [],
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_SITEINFO(state, data) {
      state.siteInfo = data
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USERINFO: (state, info) => {
      state.userInfo = info
    },
    SET_PERMISSION: (state, pinfo) => {
      state.permissionInfo = pinfo
    },
    SET_ROUTERS: (state, routers) => {
      state.routers = routers
    },
  },
  actions: {
    SetInfo: ({ commit }) => {
      return getSiteSetting().then(res => {
        commit('SET_SITEINFO', res.item || {})
        return res.item || {}
      })
    },
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim()
      return new Promise((resolve, reject) => {
        return login(username, userInfo.password).then(response => {
          if (response.meta === 0) {
            setToken(response.token)
            commit('SET_TOKEN', response.token)
          }
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        commit('SET_TOKEN', '')
        commit('SET_USERINFO', {})
        commit('SET_PERMISSION', {})
        removeToken()
        resolve()
      })
    },
    GetUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo().then(response => {
          commit('SET_USERINFO', response.user_info)
          commit('SET_PERMISSION', response.permission_info)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    GenerateRoutes({ commit }, data) {
      return new Promise((resolve, reject) => {
        const pi  = data
        if(pi != undefined){
          let accessedRouters
          if (pi.is_root || pi.is_admin) {
            accessedRouters = routeLists
          } else {
            accessedRouters = filterAsyncRouter(routeLists, pi)
          }
          commit('SET_ROUTERS', accessedRouters)
          resolve(accessedRouters)
        }else{
          reject(pi)
        }
        
      })
    },
  },
  
  getters:{
    siteInfo: state => state.siteInfo,
    permissionInfo: state => state.permissionInfo,
    userInfo: state => state.userInfo,
    token: state => state.token,
    routers: state => state.routers,
  }
})
