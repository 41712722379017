const system = 
{
    name: 'System',
    path: '/sys',
    component: () => import('@/views/dashboard/Index'),
    meta: { title: 'sysMgr', icon: 'mdi-cog-outline', funcs: ['function', 'role', 'currency', 'site_setting', 'base_data'] },
    children: [
        {
            name: 'sysFuncs',
            path: 'funcs',
            component: () => import('@/views/dashboard/pages/system/func'),
            meta: { title: 'sysFuncs', icon: 'list', funcs: 'function' }
        },
        {
            name: 'sysRoles',
            path: 'roles',
            component: () => import('@/views/dashboard/pages/system/role'),
            meta: { title: 'sysRoles', icon: 'list', funcs: 'role' }
        },
        {
            name: 'sysRolesEdit',
            path: 'rolesEdit/:id',
            hidden: true,
            component: () => import('@/views/dashboard/pages/system/rolesEdit'),
            meta: { title: 'sysRoles', icon: 'user', funcs: 'role' }
        },
        {
            name: 'sysFuncsEdit',
            path: 'edit/:id',
            hidden: true,
            component: () => import('@/views/dashboard/pages/system/funcEdit'),
            meta: { title: 'sysFuncsEdit', icon: 'list', funcs: 'function' }
        },
        {
            name: 'currency',
            path: 'currency',
            component: () => import('@/views/dashboard/pages/system/currency'),
            meta: { title: 'currency', icon: 'list', funcs: 'currency' }
        },
        {
            name: 'priceControl',
            path: 'priceControl',
            component: () => import('@/views/dashboard/pages/system/priceControl'),
            meta: { title: 'priceControl', icon: 'list', funcs: 'function' }
        },
        {
            name: 'siteInfo',
            path: 'siteInfo',
            component: () => import('@/views/dashboard/pages/system/siteInfo'),
            meta: { title: 'siteInfo', icon: 'list', funcs: 'site_setting' }
        },
        {
            name: 'basicData',
            path: 'basicData',
            component: () => import('@/views/dashboard/pages/system/basicData'),
            meta: { title: 'basicData', icon: 'list', funcs: 'base_data' }
        }
    ]
}

export default system