const TokenKey = 'X-Access-Token'
const version = 'version'
const passCheck = 'passCheck'

export function getToken() {
  return localStorage.getItem(TokenKey)
}

export function setToken(token) {
  localStorage.setItem(TokenKey, token)
}

export function removeToken() {
  localStorage.removeItem(TokenKey)
}

export function getVersion() {
  return localStorage.getItem(version)
}

export function setVersion(data) {
  localStorage.setItem(version, data)
}

export function removeVersion() {
  localStorage.removeItem(version)
}

export function setUser(data) {
  // localStorage.setItem(data.username, data.password)
}

export function removeUser(data) {
  localStorage.removeItem(data.username)
}

export function getUser(data) {
  return localStorage.getItem(data.username)
}

export function setSaveCheck(data) {
  localStorage.setItem(passCheck, data)
}

export function getSavaCheck() {
  const check = localStorage.getItem(passCheck)
  if (check === 'true') {
    return true
  } else {
    return false
  }
}
